import React, { useState } from 'react';
import { IconButton, InputAdornment, Input } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ErrorMessage } from '../index';
import Theme from '../../../common/theme';

import './index.scss';

export default function TextFieldDX(props) {
	const [showPassword, setShowPassword] = useState(false);
	var className = 'input-text-box';

	if (props.buttontype == 'Dark') className = className + '-dark';

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const error = props.error ? (
		<ErrorMessage message={props.errorMessage} />
	) : (
		<ErrorMessage message={' '} />
	);
	const { errorMessage, ..._props } = props;
	return (
		<>
			<Input
				className={className}
				{..._props}
				type={props.password === 'true' ? (showPassword ? 'text' : 'password') : props.type}
				style={{
					width: '100%',
					...props.style,
					flexDirection: 'row',
					alignSelf: 'center',
				}}
				placeholderstyle={{ color: Theme.placeholerColor, fontSize: 15 }}
				endAdornment={
					props.endAdornment ? (
						props.endAdornment
					) : props.password ? (
						<InputAdornment
							position="end"
							style={{
								color: '#9c9c9c',
								position: 'relative',
								height: '100%',
								alignSelf: 'start',
							}}
						>
							<IconButton
								style={{
									color: '#9c9c9c',
									position: 'absolute',
									top: '-6px',
									right: '1px',
								}}
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					) : props.endlabel ? (
						<InputAdornment
							position="end"
							style={{
								position: 'relative',
								height: '100%',
								alignSelf: 'center',
								justifyContent: 'center',
							}}
						>
							<span style={{ color: '#232148' }}>{props.endlabel}</span>
						</InputAdornment>
					) : null
				}
			/>
			{error}
		</>
	);
}
