import { Avatar } from "@mui/material";
import Loader from "react-js-loader";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Theme from "../../../common/theme";
import { DeleteForever } from "@mui/icons-material";
import Api from "../../../common/api";
import AppContext from "../../../common/context";
import { CONFIG } from "../../../common/constants";
import { AddFriendConfirm } from "../modals/addFriendConfirm";
import { useNavigate } from "react-router-dom";

export default function NotificationItem(props) {
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  var notificationClassName =
    " notification-background-" + (props.index % 2 == 0 ? "even" : "odd");

  const GetNotification = ({ notificationId }) => {
    switch (notificationId) {
      case 1:
        return <FriendRequest props={props} />;
        break;
      case 2:
        return <FriendRequestAccepted props={props} />;
        break;
      case 3:
        return <FriendRequestRejected props={props} />;
        break;
      case 4:
        return <PointsWon props={props} />;
        break;
      case 5:
        return <PointsLost props={props} />;
        break;
      default:
        return <DefaultNotification props={props} />;
        break;
    }
  };

  const deleteNotification = async () => {
    try {
      setLoading(true);
      var resp = await Api({
        route: "usernotifications/" + props.userNotification.userNotificationId,
        method: "delete",
        accessToken: state.Auth.access_token,
      });
      setLoading(false);
    } catch (ex) {}
  };

  return (
    <div className={"notification" + notificationClassName}>
      <GetNotification notificationId={props.userNotification.notificationId} />
      <div
        style={{
          flex: 1,
          padding: "5px",
          alignItems: "center",
          margin: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {loading ? <></> : <DeleteForever onClick={deleteNotification} />}
      </div>
    </div>
  );
}

const getName = (user) => {
  var firstName = user.firstName;
  var lastName = user.lastName;
  var email = user.email;
  if (firstName) {
    return (
      firstName +
      (lastName !== null ? " " + lastName[0].toUpperCase() + "." : "")
    );
  } else {
    return email.split("@")[0];
  }
};

const FriendRequest = ({ props }) => {
  const [open, setOpen] = useState(false);
  const notification = props.userNotification.notification;
  const JSONData = JSON.parse(props.userNotification.dataJson);
  const sender = JSONData.sender;
  const friendId = JSONData.friendId;

  return (
    <div
      style={{ flexDirection: "row", flex: 9 }}
      onClick={() => {
        setOpen(true);
      }}
    >
      <AddFriendConfirm
        open={open}
        close={() => setOpen(false)}
        friendId={friendId}
        sender={sender}
        userNotificationId={props.userNotification.userNotificationId}
      />

      <div
        style={{
          flex: 1,
          padding: "5px",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <Avatar
          id={"Contained"}
          src={
            sender.profileImage
              ? CONFIG.API_URL + sender.profileImage
              : notification.icon
          }
          style={{ width: "65px", height: "65px" }}
        />
      </div>
      <div style={{ flex: 5, padding: "5px" }}>
        <span style={{ fontSize: 13, fontWeight: "bolder" }}>
          {notification.title + " " + getName(sender)}
        </span>
        {/* <br /> */}
        <span style={{ fontSize: 13, paddingTop: 4 }}>{notification.body}</span>
        {/* <br /> */}

        {/* <span
          style={{ fontSize: 9, color: Theme.placeholerColor, paddingTop: 6 }}
        >
          {moment(props.userNotification.createdAt)
            .utc()
            .format("DD MMM YY hh:mm a")}
        </span> */}
      </div>
    </div>
  );
};

const FriendRequestAccepted = ({ props }) => {
  const navigate = useNavigate();
  const check = () => {
    navigate("/menu/leaderboard");
  };
  const notification = props.userNotification.notification;
  const JSONData = JSON.parse(props.userNotification.dataJson);
  return (
    <div style={{ flexDirection: "row", flex: 9 }} onClick={check}>
      <div
        style={{
          flex: 1,
          padding: "5px",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <Avatar
          id={"Contained"}
          src={
            JSONData.profileImage
              ? CONFIG.API_URL + JSONData.profileImage
              : notification.icon
          }
          style={{ width: "65px", height: "65px" }}
        />
      </div>
      <div style={{ flex: 5, padding: "5px" }}>
        <span style={{ fontSize: 13, fontWeight: "bolder" }}>
          {notification.title + " " + getName(JSONData)}
        </span>
        <br />
        <span style={{ fontSize: 13, paddingTop: 4 }}>{notification.body}</span>
        <br />

        {/* <span
          style={{ fontSize: 9, color: Theme.placeholerColor, paddingTop: 6 }}
        >
          {moment(props.userNotification.createdAt)
            .utc()
            .format("DD MMM YY hh:mm a")}
        </span> */}
      </div>
    </div>
  );
};

const FriendRequestRejected = ({ props }) => {
  const check = () => {};
  const notification = props.userNotification.notification;
  const JSONData = JSON.parse(props.userNotification.dataJson);
  return (
    <div style={{ flexDirection: "row", flex: 9 }} onClick={check}>
      <div
        style={{
          flex: 1,
          padding: "5px",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <Avatar
          id={"Contained"}
          src={
            JSONData.profileImage
              ? CONFIG.API_URL + JSONData.profileImage
              : notification.icon
          }
          style={{ width: "65px", height: "65px" }}
        />
      </div>
      <div style={{ flex: 5, padding: "5px" }}>
        <span style={{ fontSize: 13, fontWeight: "bolder" }}>
          {notification.title + " " + getName(JSONData)}
        </span>
        <br />
        <span style={{ fontSize: 13, paddingTop: 4 }}>{notification.body}</span>
        <br />

        {/* <span
          style={{ fontSize: 9, color: Theme.placeholerColor, paddingTop: 6 }}
        >
          {moment(props.userNotification.createdAt)
            .utc()
            .format("DD MMM YY hh:mm a")}
        </span> */}
      </div>
    </div>
  );
};

const PointsWon = ({ props }) => {
  const [match, setMatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const check = () => {
    navigate("/menu/coupons");
  };
  const notification = props.userNotification.notification;
  const { state } = useContext(AppContext);

  const getMatch = async () => {
    setLoading(true);
    try {
      var match = JSON.parse(props.userNotification.dataJson);
      var resp = await Api({
        route: "matches/notification/" + match.matchId,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setMatch(resp);
      var winner =
        resp.winner == resp.team1Id
          ? resp.team1.name
          : resp.winner == resp.team2Id
          ? resp.team2.name
          : resp.winner == resp.player1Id
          ? resp.player1.name
          : resp.winner == resp.player2Id
          ? resp.player2.name
          : "Winning team";
      setTitle(
        props.userNotification.notification.title +
          " " +
          winner +
          " in " +
          resp.league.name
      );
      setLoading(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getMatch();
  }, []);

  return (
    <div style={{ flexDirection: "row", flex: 9 }} onClick={check}>
      <div
        style={{
          flex: 1,
          padding: "5px",
          alignItems: "center",
          margin: "auto",
        }}
      >
        {loading ? (
          ""
        ) : (
          <Avatar
            id={"Contained"}
            src={match.league.logo ? match.league.logo : notification.icon}
            style={{ width: "65px", height: "65px" }}
          />
        )}
      </div>
      <div style={{ flex: 5, padding: "5px" }}>
        {loading ? (
          <Loader
            type="bubble-top"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={props.loadersize ? props.loadersize : 35}
          />
        ) : (
          <>
            <span style={{ fontSize: 13, fontWeight: "bolder" }}>{title}</span>
            <br />
            <span style={{ fontSize: 13, paddingTop: 4 }}>
              {notification.body}
            </span>
            <br />

            {/* <span
              style={{
                fontSize: 9,
                color: Theme.placeholerColor,
                paddingTop: 6,
              }}
            >
              {moment(props.userNotification.createdAt)
                .utc()
                .format("DD MMM YY hh:mm a")}
            </span> */}
          </>
        )}
      </div>
    </div>
  );
};

const PointsLost = ({ props }) => {
  const [match, setMatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const check = () => {};
  const notification = props.userNotification.notification;
  const { state } = useContext(AppContext);

  const getMatch = async () => {
    setLoading(true);
    try {
      var match = JSON.parse(props.userNotification.dataJson);
      var resp = await Api({
        route: "matches/notification/" + match.matchId,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setMatch(resp);
      var winner =
        resp.winner == null
          ? ""
          : resp.winner == resp.team1Id
          ? resp.team1.name
          : resp.winner == resp.team2Id
          ? resp.team2.name
          : resp.winner == resp.player1Id
          ? resp.player1.name
          : resp.winner == resp.player2Id
          ? resp.player2.name
          : "Winning team";
      var customMessage = winner
        ? " won the match in "
        : " the match finished with no result in ";
      setTitle(
        props.userNotification.notification.title +
          ", " +
          winner +
          customMessage +
          resp.league.name
      );
      setLoading(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getMatch();
  }, []);

  return (
    <div style={{ flexDirection: "row", flex: 9 }} onClick={check}>
      <div
        style={{
          flex: 1,
          padding: "5px",
          alignItems: "center",
          margin: "auto",
        }}
      >
        {loading ? (
          ""
        ) : (
          <Avatar
            id={"Contained"}
            src={match.league.logo ? match.league.logo : notification.icon}
            style={{ width: "65px", height: "65px" }}
          />
        )}
      </div>
      <div style={{ flex: 5, padding: "5px" }}>
        {loading ? (
          <Loader
            type="bubble-top"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={props.loadersize ? props.loadersize : 35}
          />
        ) : (
          <>
            <span style={{ fontSize: 13, fontWeight: "bolder" }}>{title}</span>
            <br />
            <span style={{ fontSize: 13, paddingTop: 4 }}>
              {notification.body}
            </span>
            <br />

            {/* <span
              style={{
                fontSize: 9,
                color: Theme.placeholerColor,
                paddingTop: 6,
              }}
            >
              {moment(props.userNotification.createdAt)
                .utc()
                .format("DD MMM YY hh:mm a")}
            </span> */}
          </>
        )}
      </div>
    </div>
  );
};

const DefaultNotification = ({ props }) => {
  const check = () => {};
  const notification = props.userNotification.notification;

  return (
    <div style={{ flexDirection: "row", flex: 9 }} onClick={check}>
      <div
        style={{
          flex: 1,
          padding: "5px",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <Avatar
          id={"Contained"}
          src={notification.icon}
          style={{ width: "65px", height: "65px" }}
        />
      </div>
      <div style={{ flex: 5, padding: "5px" }}>
        <span style={{ fontSize: 13, fontWeight: "bolder" }}>
          {notification.title}
        </span>
        <br />
        <span style={{ fontSize: 13, paddingTop: 4 }}>{notification.body}</span>
        <br />

        {/* <span
          style={{ fontSize: 9, color: Theme.placeholerColor, paddingTop: 6 }}
        >
          {moment(props.userNotification.createdAt)
            .utc()
            .format("DD MMM YY hh:mm a")}
        </span> */}
      </div>
    </div>
  );
};
