const initialState = {
  userId: 0,
  access_token: null,
  emailConfirmed: false,
};

export default function AppReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        Auth: { ...action.payload },
      };
    case "LOGOUT":
      return {
        ...state,
        Auth: { ...initialState },
      };
    case "SET_RESETPASSWORD":
      return {
        ...state,
        Auth: {
          ...state.Auth,
          resetPassword: { ...action.payload },
        },
      };
    case "VERIFY_EMAIL":
      return {
        ...state,
        Auth: {
          ...state.Auth,
          emailConfirmed: action.payload,
        },
      };
    case "UPDATE_USER":
      return {
        ...state,
        Auth: {
          ...state.Auth,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          language: action.payload.language,
        },
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        Auth: {
          ...state.Auth,
          profileImage: action.payload.profileImage,
        },
      };
    case "UPDATE_JOINING_CODE":
      return {
        ...state,
        Auth: {
          ...state.Auth,
          joiningCode: action.payload.joiningCode,
        },
      };
    default:
      return state;
  }
}
