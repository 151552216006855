import React, { useEffect, useState, useContext } from "react";
import { google } from "../../../asset";
import { SocialButton } from ".";
import Api from "../../../common/api";
import AppContext from "../../../common/context";
import { LOGIN } from "../../../common/action";

export default function GoogleLoginButton(props) {
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AppContext);

  const GoogleSignUpReq = async (response) => {
    setLoading(true);
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "GetGoogleToken", data: "" })
    );
  };

  useEffect(() => {
    const handelGoogleToken = async (event) => {
      setLoading(true);

      var body = {
        accessToken: event.detail,
      };
      try {
        var resp = await Api({
          route: "googlelogin",
          body,
          method: "POST",
          accessToken: state.Auth.access_token,
        });
        dispatch(LOGIN(resp));
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "SetUpFireBase", data: "" })
        );
      } catch (error) {
        console.log("WebApp Error: " + JSON.stringify(error));
        props.onSocialError(
          error.message
            ? error.message
            : "Unable to Register, Please try later."
        );
      }
      setLoading(false);
    };
    document.addEventListener("GoogleToken", handelGoogleToken, false);

    return () => {
      document.removeEventListener("GoogleToken", handelGoogleToken, false);
    };
  }, []);

  return (
    <SocialButton
      loading={loading}
      disabled={loading ? true : false}
      onClick={GoogleSignUpReq}
      image={google}
      text={"Google"}
    />
  );
}
