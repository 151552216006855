import { useContext } from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OptionsList } from "../../";
import AppContext from "../../../../common/context";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import "./index.scss";
import Api from "../../../../common/api";
import { useEffect } from "react";
import { useState } from "react";

export default function Squad(props) {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const [team1Players, setTeam1Players] = useState([]);
  const [team2Players, setTeam2Players] = useState([]);
  var match = props.match;

  const getPlayers = async (matchid, teamid) => {
    try {
      var resp = await Api({
        route: "matchplayers/" + matchid + "/" + teamid,
        method: "Get",
        accessToken: state.Auth.access_token,
      });
      return resp;
    } catch (error) {}
  };

  useEffect(() => {
    const getData = async () => {
      setTeam1Players(await getPlayers(match.matchId, match.team1Id));
      setTeam2Players(await getPlayers(match.matchId, match.team2Id));
    };
    getData();
  }, []);

  const menuItems2 = [
    {
      icon: (
        <div style={{ justifyContent: "center" }}>
          <Avatar
            style={{ height: 30, width: 30 }}
            src={props.match.team1.logo}
            variant="circular"
          />
        </div>
      ),
      name: (
        <div style={{ justifyContent: "center", marginLeft: "5px" }}>
          {props.match.team1.name}
        </div>
      ),
      action: "expand",
      showData: () => showPlayer(team1Players),
    },
    {
      icon: (
        <Avatar
          style={{ height: 30, width: 30 }}
          src={props.match.team2.logo}
          variant="circular"
        />
      ),
      name: (
        <div style={{ justifyContent: "center", marginLeft: "5px" }}>
          {props.match.team2.name}
        </div>
      ),
      action: "expand",
      showData: () => showPlayer(team2Players),
    },
  ];

  const menuItems3 = [
    {
      name: (
        <div style={{ flexDirection: "row", margin: "auto" }}>
          <div
            style={{
              justifyContent: "center",
              marginLeft: "5px",
              flexDirection: "row",
              fontSize: "50px",
            }}
          >
            <Avatar
              style={{
                height: 30,
                width: 30,
                display: "inline-block",
                alignSelf: "center",
              }}
              src={props.match.team1.logo}
              variant="circular"
            />
            <div
              style={{
                fontSize: "50px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              {" - "}
            </div>
            <Avatar
              style={{
                height: 30,
                width: 30,
                display: "inline-block",
                alignSelf: "center",
              }}
              src={props.match.team2.logo}
              variant="circular"
            />
          </div>
        </div>
      ),
      rightIcon: (
        <div style={{ justifyContent: "end", flexDirection: "row", flex: "1" }}>
          <div style={{ justifyContent: "center" }}>{"Match Statistics"}</div>
          <div style={{ justifyContent: "center" }}>
            <KeyboardArrowDownOutlined style={{ fontSize: "16px" }} />
          </div>
        </div>
      ),
      action: () =>
        navigate("/matchDetails/" + match.matchId + "/" + match.team1Id, {
          replace: false,
          state: match,
        }),
    },
  ];

  return (
    <div
      style={{
        margin: "20px",
        overflowY: "scroll",
        color: "white",
        height: "300px",
      }}
    >
      {!((match.live || match.finished) && !match.futureMatch) ? (
        <OptionsList OptionListItem={menuItems2} />
      ) : (
        <OptionsList OptionListItem={menuItems3} />
      )}
    </div>
  );
}

const showPlayer = (players) => {
  const getPosition = (letter) => {
    switch (letter) {
      case "G":
        return "GoalKeeper";
      case "F":
        return "Forward";
      case "D":
        return "Defender";
      case "M":
        return "Midfielder";
      case null:
        return "Substitute";
      default:
        return letter;
    }
  };

  return (
    <>
      <div className="optionListItemHeading">
        <div style={{ flex: 7, fontSize: 15 }}>PLAYER</div>
        <div style={{ flex: 3, fontSize: 15 }}>ROLE</div>
      </div>
      {players.map((player, index) => {
        return (
          <div
            style={{ color: "#cacaca", fontSize: 12 }}
            className="optionListItem"
            key={index}
          >
            <div style={{ flex: 7 }}>{player.teamPlayer.player.name}</div>
            <div style={{ flex: 3 }}>{getPosition(player.role)}</div>
          </div>
        );
      })}
    </>
  );
};
