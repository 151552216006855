import React, { useContext, useEffect, useState } from "react";
import {
  MenuOutlined,
  SearchOutlined,
  NotificationsOutlined,
} from "@mui/icons-material";

import "react-tabs/style/react-tabs.css";
import "./tabsStyles.scss";
import Home from "./home";
import { Header } from "../compound";
import AppContext from "../../common/context";
import firebaseServices from "../../services/firebase.services";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "@mui/material";
import Api from "../../common/api";
import Theme from "../../common/theme";

export default function Dashboard(props) {
  const { state, _ } = useContext(AppContext);
  let { screen, date } = useParams();
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const [allowBack, setAllowBack] = useState(true);

  const saveFireBaseToken = async (event) => {
    console.log("This Event is called");
    firebaseServices.saveFireBaseToken(state.Auth.access_token, event.detail);
  };

  const getNotificationCount = async () => {
    try {
      var response = await Api({
        route: "usernotifications/unread",
        method: "get",
        accessToken: state.Auth.access_token,
      });
      setNotificationCount(response.notificationCount);
    } catch (ex) {
      console.log(ex);
    }
  };

  const goBack = () => {
    if (allowBack) navigate(-1);
    setAllowBack(false);
    setTimeout(() => setAllowBack(true), 1500);
  };

  useEffect(() => {
    if (state.Auth.userId === 0 || !state.Auth.emailConfirmed) {
      navigate("/");
    } else {
      console.log("Adding Listener");
      getNotificationCount();
      document.addEventListener("FirebaseToken", saveFireBaseToken);
      document.addEventListener("refreshNotification", getNotificationCount);
      document.addEventListener("GoBack", goBack, false);
      document.addEventListener("openNotification", () =>
        navigate("/menu/notifications")
      );
    }

    return () => {
      document.addEventListener("FirebaseToken", saveFireBaseToken);
      document.addEventListener("refreshNotification", getNotificationCount);
      document.addEventListener("openNotification", () =>
        navigate("/menu/notifications")
      );
      document.addEventListener("GoBack", goBack, false);
    };
  }, []);

  return (
    <div
      style={{
        color: "white",
        background: "#141233",
        overflow: "hidden",
        maxWidth: "800px",
        margin: "auto",
        width: "100%",
        height: "100%",
      }}
    >
      <Header
        left={
          <MenuOutlined
            className={"action_logo"}
            onClick={() => navigate("/menu")}
          />
        }
        center={
          <img
            style={{
              height: 35,
              paddingLeft: 15,
            }}
            src={require("../../asset/logo_Shighlight.png")}
            alt={"Welcome"}
          />
        }
        right={
          <div style={{ flexDirection: "row", marginLeft: "auto" }}>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: Theme.highlightRed,
                },
              }}
              badgeContent={notificationCount}
              overlap="circular"
              max={10}
            >
              <NotificationsOutlined
                className={"action_logo"}
                onClick={() => {
                  navigate("/menu/notifications");
                }}
              />
            </Badge>
            <SearchOutlined className={"action_logo"} />
          </div>
        }
      />
      <div
        style={{
          background: "#232148",
          overflow: "hidden",
          width: "100%",
          maxWidth: "800px",
          height: "92vh",
        }}
      >
        <Home screen={screen} date={date ? date : 4} />
      </div>
    </div>
  );
}
