import { Avatar } from '@mui/material';
import './index.scss';
import Badge from '@mui/material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function TeamAvatar(props) {
	// console.log('props TeamAvatar', props);
	return <>{props.selectedTeam ? <TSelectedTeam {...props} /> : <FSelectedTeam {...props} />}</>;
}

// const CricketTeamAva
export const TSelectedTeam = (props) => {
	return (
		<Badge
			color="secondary"
			style={{
				width: props.variant ? '90%' : '50%',
				textAlign: 'center',
				alignItems: 'center',
				padding: props.variant ? '20%' : '5%',
				border: '1px solid #009AFD',
				borderRadius: '11px',
				background: '#212A42',
				display: 'flex',
				flexDirection: 'column',
			}}
			badgeContent={
				<CheckCircleIcon
					style={{
						fontSize: '15px',
						color: '#009AFD',
						backgroundColor: '#ffff',
						borderRadius: '100%',
						borderWidth: '0px',
					}}
					overlap="circular"
				/>
			}
		>
			<Avatar
				style={{
					height: '40px',
					width: '40px',
					marginBottom: '5px',
					opacity: props.selectedTeam ? '0.4' : '1',
				}}
				src={props.teamLogo}
				variant={props.variant ? props.variant : 'circular'}
			/>
			{/* <span className={props.selectedTeam ? 'Selected__Team' : ''}>{props.team}</span> */}
			<span>{props.team}</span>
		</Badge>
	);
};

const FSelectedTeam = (props) => {
	return (
		<div
			style={{
				alignItems: 'center',
				textAlign: 'center',
				padding: '5%',
				// background: props.selectedTeam ? `rgb(33 31 66)` : '',
			}}
			// className={props.selectedTeam ? 'highlight' : ''}
		>
			<Avatar
				style={{
					height: '50px',
					width: '50px',
					marginBottom: '5px',
					// opacity: props.selectedTeam ? '0.4' : '1',
				}}
				src={props.teamLogo}
				variant={props.variant ? props.variant : 'circular'}
			/>
			{/* <span className={props.selectedTeam ? 'Selected__Team' : ''}>{props.team}</span> */}
			<span>{props.team}</span>
		</div>
	);
};
