const Theme = {
  backgroundColor: "#232148",
  backgroundColor1: "#090729",
  backgroundColor1: "#141233",
  highlightColor: "#4d5b95",
  highlightRed: "#ed1c26",
  red: "red",
  textColor: "white",
  subTextColor: "#807f8a",
  gray: "#807f8a",
  yello: "#ffc02a",
  placeholerColor: "#BDBDBD",
};

export default Theme;
