import Header from "../header";
import { ScoreCard } from "../matchUpdate/football";
import { useNavigate } from "react-router-dom";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./index.scss";
import { Info, Squad, Stats, Summary } from "../footballDetailsTabs";
import { useEffect, useState, useContext } from "react";
import AppContext from "../../../common/context";
import Api from "../../../common/api";
import Loader from "react-js-loader";
import FootballLeagueTable from "../footballDetailsTabs/leagueTable";
import { ProcessFootballMatch } from "../../../common/postProcessApi";

export default function Football(props) {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [venue, setVenue] = useState();
  const [official, setOfficial] = useState();
  const [match, setMatch] = useState(null);
  useEffect(() => {
    getMatch(props.matchId);
  }, []);
  const getMatch = async (matchid) => {
    try {
      var resp = await Api({
        route: "matches/" + matchid,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      const match = ProcessFootballMatch(resp);
      setMatch(match);
      setVenue(resp.venue.name);
      setOfficial(resp.matchOfficials[0].official.name);
    } catch (error) {}
  };
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "800px",
        height: "100vh",
        backgroundColor: "#02030a",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            className={"action_logo"}
            onClick={() => navigate(-1)}
          />
        }
        center={<div className="header_title">Match Detail</div>}
      />
      {match == null ? (
        <div>
          <Loader
            type="spinner-circle"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={70}
          />
        </div>
      ) : (
        <>
          <div className="match_details_league">
            {/* <Avatar
              style={{ height: 30, width: 30 }}
              src={match.league.logo}
              variant="circular"
            /> */}
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bolder",
                marginLeft: 10,
                justifyContent: "center",
              }}
            >
              {match.league.name}
            </div>
          </div>
          <ScoreCard nobg={true} match={match} />
          {match.futureMatch ? (
            <Info
              match={match}
              venue={venue}
              official={official}
              overflow={"none"}
            />
          ) : (
            ""
          )}
          <Tabs className={"match-details__react-tabs"}>
            <TabList className={"match-details__react-tabs__tab-list"}>
              {!match.futureMatch ? (
                <Tab className={"match-details__react-tabs__tab"}>
                  <div className="match-details__react-tabs__tab__text">
                    INFO
                  </div>
                </Tab>
              ) : (
                ""
              )}
              {match.futureMatch ? (
                <Tab className={"match-details__react-tabs__tab"}>
                  <div className="match-details__react-tabs__tab__text">
                    SQUAD
                  </div>
                </Tab>
              ) : (
                ""
              )}
              {!match.futureMatch ? (
                <Tab className={"match-details__react-tabs__tab"}>
                  <div className="match-details__react-tabs__tab__text">
                    SUMMARY
                  </div>
                </Tab>
              ) : (
                ""
              )}
              {!match.futureMatch ? (
                <Tab className={"match-details__react-tabs__tab"}>
                  <div className="match-details__react-tabs__tab__text">
                    STATS
                  </div>
                </Tab>
              ) : (
                ""
              )}
              <Tab className={"match-details__react-tabs__tab"}>
                <div className="match-details__react-tabs__tab__text">
                  TABLE
                </div>
              </Tab>
            </TabList>
            {!match.futureMatch ? (
              <TabPanel className={"match-details__react-tabs__tab-panel"}>
                <Info match={match} venue={venue} official={official} />
              </TabPanel>
            ) : (
              ""
            )}
            {match.futureMatch ? (
              <TabPanel className={"match-details__react-tabs__tab-panel"}>
                <Squad match={match} venue={venue} official={official} />
              </TabPanel>
            ) : (
              ""
            )}
            {!match.futureMatch ? (
              <TabPanel className={"match-details__react-tabs__tab-panel"}>
                <Summary match={match} venue={venue} official={official} />
              </TabPanel>
            ) : (
              ""
            )}
            {!match.futureMatch ? (
              <TabPanel className={"match-details__react-tabs__tab-panel"}>
                <Stats match={match} venue={venue} official={official} />
              </TabPanel>
            ) : (
              ""
            )}
            <TabPanel className={"match-details__react-tabs__tab-panel"}>
              <FootballLeagueTable
                match={match}
                venue={venue}
                official={official}
              />
            </TabPanel>
          </Tabs>
        </>
      )}
    </div>
  );
}
