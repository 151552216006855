import { useLocation, useNavigate } from "react-router-dom";
import { Sports } from "../../../common/enums";
import Football from "./football";
import Tennis from "./tennis";
import "./index.scss";

export default function MatchInningsDetails(props) {
  const { state } = useLocation();

  var match = state;
  switch (match.league.sportId) {
    case Sports.Football:
      return Football(match);
      break;
    case Sports.Tennis:
      return Tennis(match);
      break;
    default:
      return Football(props);
  }
}
