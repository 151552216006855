import { Pill } from "../../atoms";
import "./index.scss";

export default function Calender(props) {
  var dates = props.dates;
  return (
    <div style={{ width: "100%", marginTop: 15 }}>
      <div style={{ width: "80%" }}>
        {props.dates[props.activeIndex].date.format("MMMM, YYYY")}
      </div>
      <div className="date__row">
        {dates.map((date, index) => {
          return (
            <div
              className={
                index == props.activeIndex
                  ? "date__div date__active"
                  : "date__div"
              }
              onClick={() => {
                props.onClick(index);
              }}
              key={index}
            >
              {date.pill ? <Pill /> : ""}
              <span style={{ fontSize: 10, fontWeight: "lighter" }}>
                {date.date.format("ddd")}
              </span>
              <br />

              {date.date.format("D")}
            </div>
          );
        })}
      </div>
    </div>
  );
}
