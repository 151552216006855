import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Typography } from "@mui/material";
import Api from "../../../common/api";
import AppContext from "../../../common/context";
import { ButtonDX } from "../../atoms";
import "./index.scss";

const style = {
  position: "relative",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "#232148",
  boxShadow: 24,
  color: "white",
  // p: 4,
  padding: "15px",
  height: "400px",
};

export const ReedemModal1 = (props) => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const object = props.uniqueIdentifier;
  const ReedemptionCall = () => {
    // object.sort((a, b) => (a.status > b.status ? 1 : -1));
    // var body = {
    // 	uniqueIdentifier: object[0].uniqueIdentifier,
    // 	code: props.code,
    // };
    // try {
    // 	var resp = await Api({
    // 		route: 'Coupons/redeeme',
    // 		method: 'POST',
    // 		body,
    // 		accessToken: state.Auth.access_token,
    // 	});
    // 	props.handleClose();
    // } catch (error) {
    // 	// props.setOpenOTP(true);
    // }
    props.setOpenOTP(true);
    props.handleClose();
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // TransitionComponent={Transition}
      >
        <Box sx={style} style={{ height: "auto" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} justifyContent="end">
              <CloseIcon onClick={props.handleClose} />
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Avatar
                style={{ height: "100%", width: "50%" }}
                src="https://i.pinimg.com/564x/5f/f8/aa/5ff8aa286413e2b47204efc3dae0a8f3--fashion-logos-fashion-branding.jpg"
                variant="square"
              />
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "18px",
                  // marginTop: '10px',
                  // marginBottom: '10px',
                  // width: '90%',
                }}
              >
                {props.name}
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#9190A4",
                }}
              >
                Only {props.couponsRemaining} Coupons Left
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <div className="Modal__offer__text">{props.description}</div>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: "#9190A4",

                  marginTop: "-5px",
                }}
              >
                Only one coupon can be redeemed at a time per user.
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <ButtonDX
                style={{
                  fontSize: "14px",
                  height: "auto",
                  padding: "5px",
                  width: "217px",
                  borderRadius: "4px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  marginTop: "auto",

                  fontWeight: "500",
                  letterSpacing: "1.25px",
                }}
                type={"gradient"}
                onClick={ReedemptionCall}
                loadersize={15}
                loaderheight={15}
                text="REDEEM"
              ></ButtonDX>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <ButtonDX
                style={{
                  fontSize: "14px",
                  height: "auto",
                  padding: "5px",
                  width: "217px",
                  borderRadius: "4px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  marginTop: "auto",

                  fontWeight: "500",
                  letterSpacing: "1.25px",
                }}
                type={"dark"}
                onClick={props.handleClose}
                loadersize={15}
                loaderheight={15}
                text="Terms & conditions"
              ></ButtonDX>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
