export default function Badge(props) {
  var style = props.style
    ? props.style
    : {
        backgroundColor: props.color ? props.color : "red",
        borderRadius: "15px",
        width: props.width,
      };
  style = props.className ? {} : style;
  return (
    <span className={props.className} style={style}>
      {props.text}
    </span>
  );
}
