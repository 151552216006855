import "./index.css";

export default function promo(props) {
  return (
    <div
      className="promo"
      style={{
        borderRadius: 8,
        padding: 5,
      }}
    >
      <span
        style={{
          fontSize: 11,
          marginLeft: 2,
          transform: "rotate(-90deg)",
          zIndex: 10,
          color: "white",
          justifyContent: "center",
        }}
      >
        DISCOUNT
      </span>
      <div
        style={{
          background: "#009afd",
          position: "absolute",
          height: "100%",
          top: 0,
          left: 0,
          width: 20,
          zIndex: 0,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        }}
      ></div>

      <img
        style={{
          position: "absolute",
          marginLeft: 18,
          height: 50,
          width: 50,
          borderRadius: "100%",
          alignSelf: "center",
        }}
        src={require("../../../asset/" + props.logo)}
      />
      <div
        style={{
          marginLeft: 10,
          width: 100,
          padding: 10,
          justifyContent: "center",
          alignItems: "flex-start",
          paddingLeft: 5,
        }}
      >
        <span style={{ fontSize: 13, fontWeight: "bolder" }}>{props.name}</span>
        <span style={{ fontSize: 10, fontWeight: "lighter" }}>
          {props.subText}
        </span>
      </div>
      <span
        style={{
          color: "#009afd",
          fontSize: 15,
          fontWeight: "light",
          alignSelf: "center",
        }}
      >
        {props.offer}
      </span>
    </div>
  );
}
