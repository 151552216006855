import { useContext } from "react";
import { ArrowForwardIosOutlined } from "@mui/icons-material";

import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OptionsList } from "../../";
import AppContext from "../../../../common/context";
import "./index.scss";

export default function Info(props) {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  var match = props.match;

  const team1Id = match.team1Id ? match.team1Id : match.player1Id;
  const team2Id = match.team1Id ? match.team1Id : match.player1Id;

  const menuItems1 = [
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>Match</span>
          <span>{props.match.league.name}</span>
        </div>
      ),
    },
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>{"Date & Time"}</span>
          <span>
            {props.match.date}, {props.match.time}
          </span>
        </div>
      ),
    },
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>Venue</span>
          <span>{props.venue}</span>
        </div>
      ),
    },
  ];
  return (
    <div
      style={{
        margin: "20px",
        overflowY: "scroll",
        color: "white",
      }}
    >
      <OptionsList OptionListItem={menuItems1} />
    </div>
  );
}
