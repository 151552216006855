import { Header } from "../compound";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import FollowTeamsTabs from "../atoms/followTeamsTab";
import { TextFieldDX } from "../atoms";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Star } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";

import "./index.scss";
import { useState } from "react";
import { useEffect } from "react";

function FollowTeams() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "800px",
        height: "100vh",
        backgroundColor: "#02030a",
        color: "white",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            className="action_logo"
            onClick={() => navigate(-1)}
          />
        }
        center={<div className="header_title">Follow Teams</div>}
      />
      <div style={{ margin: "10px" }}>
        <h1>Follow Teams</h1>
        <span>
          Tap{" "}
          <img
            style={{
              height: 15,
            }}
            src={require("../../asset/starIcon.png")}
          />{" "}
          to follow one or more teams. These will appear in your feed
        </span>
      </div>
      <FollowTeamsTabs />
    </div>
  );
}

export default FollowTeams;

export const TeamCard = (props) => {
  const itemsPerPage = 10;
  const [searchString, setSearchString] = useState("");
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [propsTeam, setPropsTeam] = useState(props.teams);
  const [teams, setTeams] = useState([
    ...propsTeam.slice(
      0,
      propsTeam.length > itemsPerPage ? itemsPerPage : propsTeam.length - 1
    ),
  ]);
  const [filtered, setFiltered] = useState([
    ...propsTeam.slice(
      0,
      propsTeam.length > itemsPerPage ? itemsPerPage : propsTeam.length - 1
    ),
  ]);

  const fetchMoreData = () => {
    if (teams.length >= filtered.length) {
      setHasMore(false);
      return;
    }
    setTeams([
      ...filtered.slice(
        0,
        filtered.length > teams.length + itemsPerPage
          ? teams.length + itemsPerPage
          : filtered.length
      ),
    ]);
  };

  const rowRenderer = (team, key) => {
    return (
      <div
        key={key}
        style={{
          padding: "4%",
          background: "#141927",
          marginBottom: 5,
          borderRadius: 9,
          flexDirection: "row",
        }}
      >
        <div style={{ width: "10%", justifyContent: "center" }}>
          <img
            style={{
              height: 35,
            }}
            src={
              props.sportId == 2
                ? `https://www.cricbuzz.com/a/img/v1/24x18/i1/c${team.logo}/image.jpg`
                : team.logo
            }
          />
        </div>
        <div
          style={{ marginLeft: "5%", width: "70%", justifyContent: "center" }}
        >
          {team.name}
          <span style={{ fontSize: "13px" }}>{team.country}</span>
        </div>
        <div
          style={{
            justifyContent: "center",
            width: "20%",
            alignItems: "end",
          }}
        >
          {team.teamBookmarks.length > 0 ? (
            <Star
              onClick={async () => {
                var index = propsTeam.findIndex((t) => t.teamId == team.teamId);
                var tempTeams = propsTeam;
                var resp = await props.PostBookMark(team.teamId);
                if (resp) {
                  tempTeams[index] = {
                    ...tempTeams[index],
                    teamBookmarks: [],
                  };
                  setPropsTeam(tempTeams);
                  UpdateList();
                }
              }}
            />
          ) : (
            <StarBorderIcon
              onClick={async () => {
                var index = propsTeam.findIndex((t) => t.teamId == team.teamId);
                var tempTeams = propsTeam;
                var resp = await props.PostBookMark(team.teamId);
                if (resp) {
                  tempTeams[index] = {
                    ...tempTeams[index],
                    teamBookmarks: [resp],
                  };
                  setPropsTeam(tempTeams);
                  UpdateList();
                }
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const UpdateList = () => {
    var filteredTeams = propsTeam.filter((team) =>
      team.name.toLowerCase().includes(searchString.toLowerCase())
    );
    setFiltered(filteredTeams);
    setTeams([
      ...filteredTeams.slice(
        0,
        filteredTeams.length > teams.length + itemsPerPage
          ? itemsPerPage
          : filteredTeams.length
      ),
    ]);
    if (filteredTeams.length <= 0) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  useEffect(() => {
    UpdateList();
  }, [searchString]);

  const showItems = () => {
    var items = [];
    for (var i = 0; i < teams.length; i++) {
      items.push(rowRenderer(teams[i], teams[i].teamId));
    }
    return items;
  };

  const handleSearch = (prop) => (event) => {
    setSearchString(event.target.value);
  };

  return (
    <div style={{ width: "95%", margin: "auto" }}>
      {console.log(filtered.length)}
      <TextFieldDX
        placeholder="Search Teams"
        style={{ background: "white", marginTop: 5, color: "black" }}
        type={"text"}
        onChange={handleSearch()}
        value={searchString}
        error={error ? true : false}
        errorMessage={error}
      />
      <InfiniteScroll
        dataLength={teams.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div style={{ color: "#7F7F7F", fontSize: 11 }} key={Math.random()}>
            Loading...
          </div>
        }
        endMessage={
          <p style={{ color: "#7F7F7F", fontSize: 11, textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {showItems()}
      </InfiniteScroll>
    </div>
  );
};
