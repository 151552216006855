import { useEffect, useRef, useState } from "react";
import { Avatar, Modal } from "@mui/material";
import { ButtonDX } from "../../atoms";
import { toBase64 } from "../../../common/utils";
export default function UploadDoc(props) {
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const onChangeHandler = async (event) => {
    const image = await toBase64(event.target.files[0]);
    setImg(image);
  };

  const processImage = async () => {
    setLoading(true);
    await props.UploadFile(img);
    setLoading(false);
    props.setshow(!props.show);
    setImg(null);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const openDialog = (event) => {
    if (event.detail == "profile") {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    document.addEventListener("UploadAction", openDialog);
    return () => {
      document.addEventListener("UploadAction", openDialog);
    };
  });

  return (
    <Modal
      open={props.show}
      onClose={props.setshow}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          borderRadius: "15px",
          backgroundColor: "#141233",
          padding: "2%",
          outline: "none",
          boxShadow: 24,
          p: 4,
          color: "white",
          alignItems: "center",
        }}
      >
        <Avatar
          id={"Contained"}
          style={{
            height: "200px",
            width: "200px",
            display: "flex",
            margin: "5%",
            fontSize: "25px",
          }}
          src={
            img
              ? img
              : "https://www.maxpixel.net/static/photo/640/The-Facebook-Placeholder-Shape-Blue-Avatar-Icon-1577909.png"
          }
        />
        <input
          ref={async (el) => {
            inputRef.current = el;
          }}
          accept="image/*"
          type="file"
          name="file"
          style={{
            width: "80%",
            padding: "20px",
          }}
          onChange={onChangeHandler}
        />
        {img == null ? (
          <ButtonDX
            loading={loading}
            style={{
              width: "80%",
              marginTop: "0",
            }}
            type={"gradient"}
            text={"Upload"}
            disabled
          />
        ) : (
          <ButtonDX
            loading={loading}
            style={{
              width: "80%",
              marginTop: "0",
            }}
            onClick={processImage}
            type={"gradient"}
            text={"Upload"}
          />
        )}
      </div>
    </Modal>
  );
}
