import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Loader from "react-js-loader";
import Api from "../../../../common/api";
import AppContext from "../../../../common/context";
import { Calender, MatchUpdate, Promo } from "../../../compound";

import "./index.scss";
import { Sports } from "../../../../common/enums";
import { useNavigate } from "react-router-dom";

export default function Tennis(props) {
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState({
    error: false,
    default: null,
  });
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(props.date - 1);
  const dates = [
    { date: moment().utc().subtract(3, "days"), pill: false },
    { date: moment().utc().subtract(2, "days"), pill: false },
    { date: moment().utc().subtract(1, "days"), pill: false },
    { date: moment().utc(), pill: false },
    { date: moment().utc().add(1, "days"), pill: false },
    { date: moment().utc().add(2, "days"), pill: false },
    { date: moment().utc().add(3, "days"), pill: false },
  ];

  const [matches, setMatches] = useState([]);

  useEffect(() => {
    getMatches();
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(props.date - 1);
  });

  const changeDate = (index) => {
    navigate("/dashboard/tennis/" + (index + 1));
  };

  const getMatches = async () => {
    setLoading(true);
    try {
      var params = {
        sportId: Sports.Tennis,
        startDate: dates[activeIndex].date.format("DD-MM-YYYY"),
        timeZone: new Date().getTimezoneOffset(),
      };
      var resp = await Api({
        route: "matches/filter",
        method: "GET",
        params,
        accessToken: state.Auth.access_token,
      });
      resp = resp.sort((a, b) => {
        var adateTime = moment(a.startDate).utc();
        var bdateTime = moment(b.startDate).utc();
        var duration = moment.duration(bdateTime.diff(adateTime));
        if (parseInt(duration.asHours()) > 0) {
          return 1;
        }
        if (parseInt(duration.asHours()) < 0) {
          return -1;
        }
        return 0;
      });
      resp = resp.filter((match) => {
        var matchDate = moment(match.startDate).utc().startOf("day");
        var selectedDates = dates[activeIndex].date.utc().startOf("day");
        return matchDate.isSame(selectedDates);
      });
      var leagueMatches = {};
      for (var i = 0; i < resp.length; i++) {
        if (!leagueMatches.hasOwnProperty(resp[i].leagueId)) {
          leagueMatches[resp[i].leagueId] = [];
        }
        leagueMatches[resp[i].leagueId].push(resp[i]);
      }
      setMatches(Object.values(leagueMatches));
      setLoading(false);
    } catch (error) {
      setErrorMessage({
        ...errorMessage,
        default: error.message,
      });
      console.log(error);
    }
  };
  const showMatches = () => {
    return (
      <>
        {matches.map((league, index) => (
          <MatchUpdate
            key={index}
            match={league[0]}
            league={league}
            onClick={() => console.log(league[0].matchId)}
          />
        ))}
      </>
    );
  };

  return (
    <div
      style={{
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "15px",
        marginBottom: "5px",
      }}
    >
      <div
        style={{
          flexDirection: "row",
          overflowX: "scroll",
          width: "100%",
        }}
      >
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
      </div>

      <Calender dates={dates} onClick={changeDate} activeIndex={activeIndex} />
      {loading ? (
        <Loader
          type="bubble-top"
          bgColor={"#FFFFFF"}
          color={"#FFFFFF"}
          size={35}
        />
      ) : (
        showMatches()
      )}
    </div>
  );
}
