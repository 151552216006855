import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "@mui/material";
import Api from "../../../common/api";
import AppContext from "../../../common/context";
import { ButtonDX } from "../../atoms";
import { CONFIG } from "../../../common/constants";

import "./index.scss";
const style = {
  position: "relative",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  bgcolor: "#232148",
  boxShadow: 24,
  color: "white",
  padding: "15px",
};

export const AddFriendConfirm = (props) => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const deleteNotification = async () => {
    try {
      var resp = await Api({
        route: "usernotifications/" + props.userNotificationId,
        method: "delete",
        accessToken: state.Auth.access_token,
      });
    } catch (ex) {}
  };

  const AcceptRequest = async () => {
    setLoading(true);
    var body = {
      friendId: props.friendId,
      requestStatus: true,
    };
    try {
      var resp = await Api({
        route: "friends/response",
        method: "POST",
        body,
        accessToken: state.Auth.access_token,
      });
      await deleteNotification();
      onClose();
      navigate("/menu/leaderboard");
    } catch (error) {
      onClose();
    }
    setLoading(false);
  };

  const RejectRequest = async () => {
    setLoading(true);
    var body = {
      friendId: props.friendId,
      requestStatus: false,
    };
    try {
      var resp = await Api({
        route: "friends/response",
        method: "POST",
        body,
        accessToken: state.Auth.access_token,
      });
      await deleteNotification();
      onClose();
    } catch (error) {
      onClose();
    }
    setLoading(false);
  };

  const onClose = async () => {
    await delay(10);
    props.close();
  };

  const getName = (user) => {
    var firstName = user.firstName;
    var lastName = user.lastName;
    var email = user.email;
    if (firstName) {
      return (
        firstName +
        (lastName !== null ? " " + lastName[0].toUpperCase() + "." : "")
      );
    } else {
      return email.split("@")[0];
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12} justifyContent="end">
            <CloseIcon onClick={onClose} />
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <Avatar
              id={"Contained"}
              style={{ height: "130px", width: "130px" }}
              src={
                props.sender.profileImage
                  ? CONFIG.API_URL + props.sender.profileImage
                  : "NO Image"
              }
              variant="circular"
            />
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            className="Add__Friend__Name"
          >
            {getName(props.sender)}
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            textAlign="center"
            style={{
              fontFamily: "Ubuntu",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Accept the request and add your Friend on the Leaderboard
          </Grid>
          <Grid item xs={12} justifyContent="center" textAlign="center">
            <ButtonDX
              style={{
                fontSize: "13px",
                height: "auto",
                padding: "5px",
                width: "120px",
                borderRadius: "4px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                marginTop: "auto",

                fontWeight: "400",
                letterSpacing: "1.25px",
              }}
              type={"gradient"}
              onClick={AcceptRequest}
              loadersize={15}
              loaderheight={15}
              text="CONFIRM"
            />
            <ButtonDX
              style={{
                fontSize: "13px",
                height: "auto",
                padding: "5px",
                width: "120px",
                borderRadius: "4px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                marginTop: "auto",

                fontWeight: "400",
                letterSpacing: "1.25px",
              }}
              type={"gradient"}
              onClick={RejectRequest}
              loading={loading}
              loadersize={15}
              loaderheight={15}
              text="REJECT"
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
