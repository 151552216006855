import {
  ArrowBackIosOutlined,
  SearchOutlined,
  ShareOutlined,
  ManageAccountsOutlined,
  LocalActivityOutlined,
  StarsRounded,
  NotificationsOutlined,
  LeaderboardOutlined,
  TaskOutlined,
} from "@mui/icons-material";
import BookmarkBorderSharpIcon from "@mui/icons-material/BookmarkBorderSharp";
import { Modal } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../common/context";
import { Header, OptionsList } from "../compound";
import { AddFriendModal, AddFriendResp } from "../compound/modals";
import { useNavigate } from "react-router-dom";
import { ButtonDX } from "../atoms";
import { LOGOUT } from "../../common/action";

export default function Menu(props) {
  const { state, dispatch } = useContext(AppContext);
  const [addFriendResp, setAddFriendResp] = useState(null);
  const [addFriend, setAddFriend] = useState(false);
  const [modalStatus, setModalStatus] = useState(1);
  const navigate = useNavigate();
  const user = state.Auth;
  const name = user.firstName
    ? user.firstName
    : user.lastName
    ? user.lastName
    : user.email
    ? user.email.split("@")[0]
    : "";

  const logout = () => {
    dispatch(LOGOUT());
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (user.userId === 0 || !user.emailConfirmed) {
      navigate("/", { replace: true });
    }
  });
  const _setAddFriend = () => {
    setAddFriend(!addFriend);
    setAddFriendResp(null);
    setModalStatus(1);
  };

  const _shareWithFriends = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "Share",
        data: {
          message:
            "Hey! Checkout Sportl App and win free discount vouchers. \nhttps://sportlapp.compass-dx.com/\nOnce you download the app, join me by using code: " +
            user.joiningCode,
        },
      })
    );
  };

  const menuItems1 = [
    {
      name: "Profile",
      icon: <ManageAccountsOutlined style={{ paddingRight: "10px" }} />,
      action: () => {
        navigate("profile");
      },
    },
    {
      name: "My Points",
      icon: (
        <img
          style={{
            display: "flex",
            height: 25,
            paddingRight: "10px",
          }}
          src={require("../../asset/coin-icon.png")}
        />
      ),
      action: () => {
        navigate("points");
      },
    },
    {
      name: "My Coupons",
      icon: (
        <img
          style={{
            display: "flex",
            height: 25,
            paddingRight: "10px",
          }}
          src={require("../../asset/coupon-icon.png")}
        />
      ),
      action: () => {
        navigate("coupons");
      },
    },
    {
      name: "Leaderboard",
      icon: (
        <img
          style={{
            display: "flex",
            height: 25,
            paddingRight: "10px",
          }}
          src={require("../../asset/leaderboard-icon.png")}
        />
      ),
      action: () => {
        navigate("leaderboard");
      },
    },
    {
      name: "My Tasks",
      icon: <TaskOutlined style={{ paddingRight: "10px" }} />,
      action: () => {
        navigate("notifications");
      },
    },
    {
      name: "Add your friend",
      icon: (
        <img
          style={{
            paddingRight: "10px",
            height: "25px",
            width: "25px",
          }}
          src={require("../../asset/AddFriend-Icon.png")}
        />
      ),
      action: _setAddFriend,
    },
    {
      name: "Follow Teams",
      icon: <BookmarkBorderSharpIcon style={{ paddingRight: "10px" }} />,
      action: () => {
        navigate("followteams");
      },
    },
    {
      name: "Share with your friends",
      icon: <ShareOutlined style={{ paddingRight: "10px" }} />,
      action: _shareWithFriends,
    },
  ];

  return (
    <div
      id="AccountMenu"
      style={{
        width: "100%",
        maxWidth: "800px",
        height: "100vh",
        backgroundColor: "#141927",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            className={"action_logo"}
            onClick={() => navigate(-1)}
          />
        }
        center={<div className="header_title">My Account</div>}
        right={
          <SearchOutlined
            style={{ fontSize: "30px", marginLeft: "auto", padding: "10px" }}
          />
        }
      />
      <div
        style={{
          padding: "20px",
          overflowY: "scroll",
          color: "white",
          backgroundColor: "#02030a",
          flex: 1,
        }}
      >
        <img
          style={{
            height: 60,
            padding: "10px",
            display: "flex",
            alignSelf: "center",
            marginBottom: "3%",
          }}
          src={require("../../asset/logo_Shighlight.png")}
          alt={"Welcome"}
        />
        <OptionsList
          OptionListItem={menuItems1}
          style={{ backgroundColor: "#141927" }}
        />

        <div
          style={{
            justifyContent: "center",
            alignSelf: "center",
            width: "85%",
            paddingBottom: 25,
          }}
        >
          <ButtonDX
            type={"primary"}
            onClick={() => {
              logout();
            }}
            loading={false}
            text={"Sign Out"}
          />
        </div>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            color: "#7e7e7e",
          }}
        >
          V 1.0.5
        </div>
      </div>

      <Modal
        open={addFriend}
        onClose={() => setAddFriend(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          {modalStatus == 1 ? (
            <AddFriendModal
              setAddFriend={_setAddFriend}
              success={(resp) => {
                setAddFriendResp(resp);
                setModalStatus(2);
              }}
              failed={(resp) => {
                setAddFriendResp(resp);
                setModalStatus(2);
              }}
            />
          ) : (
            <AddFriendResp resp={addFriendResp} setAddFriend={_setAddFriend} />
          )}
        </div>
      </Modal>
    </div>
  );
}
