import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

import { ButtonDX } from "../../atoms";
import "./index.scss";

const style = {
  position: "relative",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "#232148",
  boxShadow: 24,
  color: "white",
  // p: 4,
  padding: "15px",
  height: "400px",
};

export const ReedemModal = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // TransitionComponent={Transition}
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12} justifyContent="end">
              <CloseIcon onClick={props.handleClose} />
            </Grid>
            <Grid item xs={12}>
              {""}
            </Grid>
            <Grid item xs={12}>
              {""}
            </Grid>
            <Grid item xs={12}>
              {""}
            </Grid>

            <Grid item xs={12} justifyContent="center">
              <div
                style={{
                  paddingTop: "7px",

                  background: "#090729",
                  borderRadius: "38px",
                  width: "250px",
                  textAlign: "center",
                }}
              >
                <Typography className="Reedem__Modal__title">
                  Balance Points
                </Typography>
                <Typography
                  className="Reedemption__title"
                  style={{ fontSize: "32px" }}
                >
                  {props.points}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Typography className="balancepoint__text">
                Please participate in more games to avail this coupon.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {""}
            </Grid>
            <Grid item xs={12}>
              {""}
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <ButtonDX
                style={{
                  fontSize: "14px",
                  height: "auto",
                  padding: "5px",
                  width: "217px",
                  borderRadius: "4px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  marginTop: "auto",

                  fontWeight: "500",
                  letterSpacing: "1.25px",
                }}
                type={"gradient"}
                onClick={() => navigate("/dashboard/myfeed")}
                loadersize={15}
                loaderheight={15}
                text="Play & Win"
              ></ButtonDX>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <ButtonDX
                style={{
                  fontSize: "14px",
                  height: "auto",
                  padding: "5px",
                  width: "217px",
                  borderRadius: "4px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  marginTop: "-10px",

                  fontWeight: "500",
                  letterSpacing: "1.25px",
                }}
                type={"dark"}
                onClick={props.handleClose}
                loadersize={15}
                loaderheight={15}
                text="OK"
              ></ButtonDX>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
