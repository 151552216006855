import TypographyDX from "../typography";

export default function ErrorMessage(props) {
  return (
    <TypographyDX
      style={{
        fontSize: 13,
        justifyContent: "start",
        diplay: "flex",
        padding: 5,
        paddingLeft: 0,
        marginBottom: "5px",
        height: "13px",
      }}
      color="red"
    >
      {props.message}
    </TypographyDX>
  );
}
