import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, Grid } from "@mui/material";
import TeamAvatar from "../teamAvatar";
import ButtonDX from "../button";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import Api from "../../../common/api";
import { useContext } from "react";
import AppContext from "../../../common/context";
import { useState } from "react";
import ErrorMessage from "../errorMessage";

export default function BasicModal(props) {
  const { state } = useContext(AppContext);
  const [btn1, setBtn1] = useState("dark");
  const [btn2, setBtn2] = useState("dark");
  const [answer, setAnswer] = useState(null);
  const [questionPerMatchId, setquestionPerMatchId] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    setquestionPerMatchId(props.question[0].matchQuestionId);
  }, []);

  const handleClose = async () => {
    setLoading(true);
    if (answer == null) {
      setError("Please chose a team.");
    } else {
      var body = {
        answer: answer,
        questionPerMatchId,
      };
      try {
        var resp = await Api({
          route: "fanresponses",
          body,
          method: "POST",
          accessToken: state.Auth.access_token,
        });
        navigate("/matchDetails/" + props.match.matchId, {
          replace: false,
          state: props.match,
        });
        props.setHide(true);
      } catch (error) {
        console.log("Error while answering play and win", error);
        setError("Error in selection please try again later");
      }
    }
    setLoading(false);
  };
  const team1btnClick = () => {
    setBtn1("selected");
    setBtn2("dark");
    setAnswer(props.match.team1 ? props.match.team1Id : props.match.player1Id);
  };
  const team2btnClick = () => {
    setBtn1("dark");
    setBtn2("selected");
    setAnswer(props.match.team2 ? props.match.team2Id : props.match.player2Id);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={"play_win_modal"}>
          <div style={{ flexDirection: "row-reverse" }}>
            <CloseIcon
              className={"action_logo"}
              style={{ padding: 5, margin: 5 }}
              onClick={() => props.setOpen(false)}
            />
          </div>
          <Typography id="modal-modal-title" className="main__title">
            {props.question[0].question.question}
          </Typography>
          <Typography className="description" id="modal-modal-description">
            You will win 100 points if your chosen team wins. Winning points can
            be used to redeem discount offers !
          </Typography>

          <Box sx={{ flexGrow: 1 }} style={{ marginTop: "15px" }}>
            <Grid container spacing={1}>
              <Grid item xs={6} justifyContent="center">
                <ButtonDX
                  style={{
                    height: "auto",
                    fontSize: "11px",
                    marginLeft: "3%",
                  }}
                  text={
                    <div className="options">
                      <Avatar
                        src={
                          props.cricketLogo1
                            ? props.cricketLogo1
                            : props.match.team1
                            ? props.match.team1.logo
                            : props.match.player1
                            ? props.match.player1.logo
                            : "https://img.freepik.com/premium-vector/tennis-logo-design-sports-logo_331749-147.jpg"
                        }
                      />
                      {props.match.team1
                        ? props.match.team1.name
                        : props.match.player1.name}
                    </div>
                  }
                  type={btn1}
                  onClick={team1btnClick}
                />
              </Grid>
              <Grid item xs={6} justifyContent="center">
                <ButtonDX
                  style={{
                    height: "auto",
                    fontSize: "11px",
                    marginRight: "3%",
                  }}
                  text={
                    <div className="options">
                      <Avatar
                        src={
                          props.cricketLogo2
                            ? props.cricketLogo2
                            : props.match.team2
                            ? props.match.team2.logo
                            : props.match.player2
                            ? props.match.player2.logo
                            : "https://img.freepik.com/premium-vector/tennis-logo-design-sports-logo_331749-147.jpg"
                        }
                      />
                      {props.match.team2
                        ? props.match.team2.name
                        : props.match.player2.name}
                    </div>
                  }
                  type={btn2}
                  onClick={team2btnClick}
                />
              </Grid>
            </Grid>
          </Box>
          <ErrorMessage message={error} />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} jusyifyContent="center">
                <ButtonDX
                  style={{
                    height: "auto",
                    fontSize: "14px",
                    width: "150px",
                  }}
                  loading={loading}
                  text={"CLOSE"}
                  type={"primary"}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
