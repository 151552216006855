export default function Header(props) {
	return (
		<div
			style={{
				width: '100%',
				background: '#141927',
				color: 'white',
				height: '8vh',
			}}
		>
			<div
				style={{
					height: '100%',
					flexDirection: 'row',
					alignItems: 'center',
					fontSize: '11px',
				}}
			>
				{props.left}
				{props.center}
				{props.right}
			</div>
		</div>
	);
}
