import { Checkbox } from "@mui/material";

export default function CheckboxDX(props) {
  return (
    <Checkbox
      sx={{
        color: props.error ? "red" : "white",
        "&.Mui-checked": {
          color: "white",
        },
      }}
      checked={props.checked}
      onClick={() => {
        props.setChecked();
      }}
    />
  );
}
