import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Loader from "react-js-loader";
import "./index.scss";

export const InningsCard = (props) => {
  const [team1PlayerScore, setteam1PlayerScore] = useState();
  const [team2PlayerScore, setteam2PlayerScore] = useState();
  useEffect(() => {
    try {
      setteam1PlayerScore(props.players1);
      setteam2PlayerScore(props.players2);
    } catch (e) {
      console.log("Player ScoreBoard", e);
    }
  }, []);

  const getInningsIndex = () => {
    var Team1 =
      JSON.parse(props.match.team1Score).inngs1.inningsId == 1 ? 0 : 1;
    var Team2 =
      JSON.parse(props.match.team2Score).inngs1.inningsId == 1 ? 0 : 1;
    return {
      team1Batting: Team1,
      team2Batting: Team2,
    };
  };

  return (
    <>
      {team1PlayerScore && team2PlayerScore ? (
        <div
          style={{
            marginTop: "10px",
            background: "#141927",
            borderRadius: "20px",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              padding: "15px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
              flex: "1 1 0",
              borderBottom: "1px solid black",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ color: "#FFFFFF80", fontSize: "24px" }}
              >
                {props.teamId == props.match.team1Id
                  ? props.match.team1.name
                  : props.match.team2.name}
              </Grid>
              <Grid item xs={12}>
                {(props.teamId == props.match.team1Id
                  ? getInningsIndex().team1Batting == 0
                    ? "1st"
                    : "2nd"
                  : getInningsIndex().team2Batting == 0
                  ? "1st"
                  : "2nd") + " Inning - Batting"}
              </Grid>
              <Grid item xs={12}>
                {""}
              </Grid>
              <Grid item xs={4}>
                {" "}
              </Grid>
              <Grid item xs={3}>
                {""}
              </Grid>
              <Grid item xs={1}>
                R
              </Grid>
              <Grid item xs={1}>
                B
              </Grid>
              <Grid item xs={1}>
                4s
              </Grid>
              <Grid item xs={1}>
                6s
              </Grid>
              <Grid item xs={1}>
                Sr
              </Grid>
            </Grid>
          </Box>

          {team1PlayerScore.map((n) => {
            var inningIndex =
              props.teamId == props.match.team1Id
                ? getInningsIndex().team1Batting
                : getInningsIndex().team2Batting;
            if (JSON.parse(n.scoreCard))
              return (
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    background: "#141927",
                    padding: "15px",
                    color: "white",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {n.teamPlayer.player.name}
                    </Grid>
                    <Grid item xs={3}>
                      {JSON.parse(n.scoreCard)[inningIndex].outDesc}
                    </Grid>
                    <Grid item xs={1}>
                      {JSON.parse(n.scoreCard)[inningIndex].runs}
                    </Grid>
                    <Grid item xs={1}>
                      {JSON.parse(n.scoreCard)[inningIndex].balls}
                    </Grid>
                    <Grid item xs={1}>
                      {JSON.parse(n.scoreCard)[inningIndex].fours}
                    </Grid>
                    <Grid item xs={1}>
                      {JSON.parse(n.scoreCard)[inningIndex].sixes}
                    </Grid>
                    <Grid item xs={1}>
                      {JSON.parse(n.scoreCard)[inningIndex].strikeRate}
                    </Grid>
                  </Grid>
                </Box>
              );
          })}
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              padding: "15px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
              flex: "1 1 0",
              borderBottom: "1px solid black",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ color: "#FFFFFF80", fontSize: "24px" }}
              >
                {props.teamId == props.match.team1Id
                  ? props.match.team2.name
                  : props.match.team1.name}
              </Grid>
              <Grid item xs={12}>
                {(props.teamId == props.match.team1Id
                  ? getInningsIndex().team1Batting == 0
                    ? "1st"
                    : "2nd"
                  : getInningsIndex().team2Batting == 0
                  ? "1st"
                  : "2nd") + " Inning - Bowling"}
              </Grid>
              <Grid item xs={6}>
                {" "}
              </Grid>
              <Grid item xs={1}>
                O
              </Grid>
              <Grid item xs={1}>
                M
              </Grid>
              <Grid item xs={1}>
                R
              </Grid>
              <Grid item xs={1}>
                W
              </Grid>
              <Grid item xs={1}>
                Econ
              </Grid>
            </Grid>
          </Box>
          {team2PlayerScore.map((n) => {
            var inningIndex =
              props.teamId == props.match.team1Id
                ? getInningsIndex().team1Batting
                : getInningsIndex().team2Batting;
            if (JSON.parse(n.scoreCard))
              if (JSON.parse(n.scoreCard)[inningIndex]) {
                return (
                  <Box
                    sx={{ flexGrow: 1 }}
                    style={{
                      background: "#141927",
                      padding: "15px",
                      color: "white",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {n.teamPlayer.player.name}
                      </Grid>
                      <Grid item xs={1}>
                        {JSON.parse(n.scoreCard)[inningIndex].overs}
                      </Grid>
                      <Grid item xs={1}>
                        {JSON.parse(n.scoreCard)[inningIndex].maidens}
                      </Grid>
                      <Grid item xs={1}>
                        {JSON.parse(n.scoreCard)[inningIndex].runs}
                      </Grid>
                      <Grid item xs={1}>
                        {JSON.parse(n.scoreCard)[inningIndex].wickets}
                      </Grid>
                      <Grid item xs={1}>
                        {JSON.parse(n.scoreCard)[inningIndex].economy}
                      </Grid>
                    </Grid>
                  </Box>
                );
              }
          })}
        </div>
      ) : (
        <Loader
          type="bubble-top"
          bgColor={"#FFFFFF"}
          color={"#FFFFFF"}
          size={35}
        />
      )}
    </>
  );
};
