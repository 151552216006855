import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import { Fragment, useEffect } from 'react';
import MatchDetails from './components/compound/matchDetails';
import MatchInningsDetails from './components/compound/matchInningsDetails';
import Notification from './components/compound/notification';
import Dashboard from './components/dashboard';
import MenuLayout from './components/menu';
import Menu from './components/menu/menu';
import Profile from './components/menu/profile';
import MyPoints from './components/menu/myPoints';
import MyCoupons from './components/menu/myCoupons';
import Leaderboard from './components/menu/leaderBoard';
import FollowTeams from './components/menu/followTeams';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function App() {
	localStorage.setItem('allowBack', JSON.stringify({ allowBack: true }));
	useEffect(() => {
		const messageFromApp = async (message) => {
			message = JSON.parse(message.data);
			switch (message.key) {
				case 'FacebookToken':
					document.dispatchEvent(new CustomEvent('FacebookToken', { detail: message.data }));
					break;
				case 'GoogleToken':
					document.dispatchEvent(new CustomEvent('GoogleToken', { detail: message.data }));
					break;
				case 'FirebaseToken':
					await delay(5000);
					document.dispatchEvent(new CustomEvent('FirebaseToken', { detail: message.data }));
					break;
				case 'GoBack':
					const allowBack = JSON.parse(localStorage.getItem('allowBack'));
					if (allowBack.allowBack) {
						document.dispatchEvent(new CustomEvent('GoBack', { detail: '' }));
					}
					break;
				case 'refreshNotification':
					document.dispatchEvent(new CustomEvent('refreshNotification', { detail: '' }));
					break;
				case 'openNotification':
					await delay(2000);
					document.dispatchEvent(new CustomEvent('openNotification', { detail: '' }));
					break;
				case 'UploadAction':
					document.dispatchEvent(new CustomEvent('UploadAction', { detail: message.data }));
					break;
				default:
					console.log(message.data);
			}
		};
		document.addEventListener('message', messageFromApp, false);

		return () => {
			document.removeEventListener('message', messageFromApp, false);
		};
	});
	return (
		<Router>
			<Fragment>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/menu" element={<MenuLayout />}>
						<Route index element={<Menu />} />
						<Route path="profile" element={<Profile />} />
						<Route path="points" element={<MyPoints />} />
						<Route path="coupons" element={<MyCoupons />} />
						<Route path="leaderboard" element={<Leaderboard />} />
						<Route path="notifications" element={<Notification />} />
						<Route path="followteams" element={<FollowTeams />} />
					</Route>
					<Route exact path="/dashboard" element={<Dashboard />} />
					<Route exact path="/dashboard/:screen" element={<Dashboard />} />
					<Route exact path="/dashboard/:screen/:date" element={<Dashboard />} />
					<Route exact path="/matchDetails/:matchId" element={<MatchDetails />} />
					<Route exact path="/matchDetails/:matchId/:teamId" element={<MatchInningsDetails />} />
				</Routes>
			</Fragment>
		</Router>
	);
}

export default App;
