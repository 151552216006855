import { CloseOutlined } from "@mui/icons-material";
import { useContext, useState } from "react";
import Api from "../../../common/api";
import AppContext from "../../../common/context";
import { ButtonDX, TextFieldDX } from "../../atoms";
import Gradient from "rgt";

export const AddFriendModal = (props) => {
  const [errorMessage, setErrorMessage] = useState({
    default: null,
  });
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(null);
  const [friendCode, setFriendCode] = useState("");
  const handleChange = (prop) => (event) => {
    setFriendCode(event.target.value);
  };

  const addFriend = async () => {
    setLoading(true);
    if (friendCode == null || friendCode == "") {
      setErrorMessage({ default: "Please Enter Friends Code." });
    } else {
      try {
        var resp = await Api({
          route: "friends/sendrequest/" + friendCode,
          method: "POST",
          accessToken: state.Auth.access_token,
        });
        props.success(resp);
      } catch (error) {
        props.failed({ error: error });
      }
    }
    setLoading(false);
  };

  return (
    <div className="modal-div">
      <CloseOutlined
        className={"action_logo"}
        style={{ alignSelf: "end" }}
        onClick={() => props.setAddFriend()}
      />
      <img
        style={{
          display: "flex",
          height: "200px",
        }}
        src={require("../../../asset/AddFriend.png")}
      />
      <span
        style={{
          fontSize: "25px",
          padding: "10px",
          textAlign: "center",
          fontWeight: "bolder",
        }}
      >
        <div>{"Add Your Friend"}</div>
      </span>
      <span style={{ fontSize: "20px", padding: "10px" }}>Win 200 Points</span>
      <TextFieldDX
        error={errorMessage.default ? true : false}
        errorMessage={errorMessage.default}
        placeholder="Enter Your Friend's Code Here"
        style={{
          height: "50px",
          alignSelf: "center",
          width: "90%",
          backgroundColor: "#02030a",
        }}
        type={"text"}
        onChange={handleChange()}
        value={friendCode}
      />

      <ButtonDX
        loading={loading}
        style={{
          width: "80%",
          marginTop: "0",
        }}
        onClick={addFriend}
        type={"primary"}
        text={"Proceed"}
      />
    </div>
  );
};
