import { ButtonDX, TypographyDX, ErrorMessage, TextFieldDX } from "../../atoms";
import { useState, useContext } from "react";
import Api from "../../../common/api";
import { email } from "../../../common/validate";
import AppContext from "../../../common/context";
import { SET_RESETPASSWORD } from "../../../common/action";
export default function ForgetPassword(props) {
  const { state, dispatch } = useContext(AppContext);
  const [forgetPasswordData, setForgetPasswordData] = useState({
    email: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    email: null,
    default: null,
  });

  const handleChange = (prop) => (event) => {
    setForgetPasswordData({
      ...forgetPasswordData,
      [prop]: event.target.value,
    });
  };

  const validate = async () => {
    var emailError = null;
    var overAllError = false;
    if (email(forgetPasswordData.email)) {
      emailError = null;
      overAllError = false;
    } else {
      emailError = "Invalid Email";
      overAllError = true;
    }
    setErrorMessage({
      email: emailError,
    });
    return overAllError;
  };

  const SendOTP = async () => {
    setLoading(true);
    var overAllError = await validate();
    if (!overAllError) {
      try {
        var params = {
          email: forgetPasswordData.email,
        };
        var resp = await Api({
          route: "resendemail",
          params,
          accessToken: state.Auth.access_token,
        });
        dispatch(
          SET_RESETPASSWORD({
            userId: resp.userId,
            email: forgetPasswordData.email,
          })
        );
        props.onClick("otp");
      } catch (error) {
        setErrorMessage({
          ...errorMessage,
          default: error.message
            ? error.message
            : "Unable to Send OTP, Please try later.",
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className="flexAuth">
      <div
        style={{
          width: "80%",
          marginBottom: 21,
        }}
      >
        <TypographyDX
          align="left"
          style={{
            width: "100%",
            fontWeight: "Bold",
            fontSize: 30,
          }}
        >
          Forget Password?
        </TypographyDX>
        <TypographyDX
          align="left"
          style={{
            width: "100%",
            fontSize: 14,
            marginBottom: 16,
          }}
        >
          Recover Your Account with Email. We will send an Email with OTP to
          recover your account
        </TypographyDX>
        <TextFieldDX
          error={errorMessage.email ? true : false}
          errorMessage={errorMessage.email}
          placeholder="Email"
          type={"text"}
          onChange={handleChange("email")}
          value={forgetPasswordData.email}
        />
        <ErrorMessage message={errorMessage.default} />
        <ButtonDX
          type={"primary"}
          onClick={() => SendOTP()}
          loading={loading}
          disabled={loading ? true : false}
          text={"Send Email"}
        />
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "auto",
          paddingBottom: 15,
          flexDirection: "column-reverse",
          widht: "100%",
        }}
      >
        <TypographyDX
          align="right"
          className={"fontStyle"}
          style={{
            fontSize: 15,
            textAlign: "center",
          }}
          onClick={() => props.onClick("login")}
        >
          Already have an account?{" "}
          <span style={{ color: "#009afd", fontWeight: "bold" }}>Sign In</span>
        </TypographyDX>
      </div>
    </div>
  );
}
