import Loader from "react-js-loader";
import { LoadingButton } from "@mui/lab";
import { SocialButton } from "./socialButton";
import FacebookLoginButton from "./facebookLogin";
import GoogleLoginButton from "./googleLogin";
import "./index.scss";

export default function ButtonDX(props) {
  var classNames = "button ";
  if (props.className) {
    classNames += " " + props.className;
  }
  if (props.type) {
    classNames += " " + props.type;
  }

  return (
    <LoadingButton
      className={classNames}
      loadingIndicator=""
      style={{
        ...props.style,
      }}
      onClick={props.loading ? null : props.onClick}
      {...props}
    >
      {!props.loading ? (
        props.text
      ) : (
        <div
          style={{
            alignContent: "center",
            justifyContent: "center",
            height: props.loaderheight,
          }}
        >
          <Loader
            type="bubble-top"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={props.loadersize ? props.loadersize : 35}
          />
        </div>
      )}
    </LoadingButton>
  );
}

export { SocialButton, FacebookLoginButton, GoogleLoginButton };
