import Header from '../header';
import { ScoreCard2 } from '../matchUpdate/tennis2';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIosOutlined, SearchOutlined } from '@mui/icons-material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './index.scss';
import moment from 'moment';
import { Info, Stats, Summary } from '../tennisDetailsTabs';
import { useEffect, useState, useContext } from 'react';
import AppContext from '../../../common/context';
import Api from '../../../common/api';
import Loader from 'react-js-loader';

export default function Tennis(props) {
	const navigate = useNavigate();
	const { state } = useContext(AppContext);
	const [venue, setVenue] = useState();
	const [official, setOfficial] = useState();
	const [match, setMatch] = useState(null);
	useEffect(() => {
		getMatch(props.matchId);
	}, []);
	const getMatch = async (matchid) => {
		try {
			var resp = await Api({
				route: 'matches/' + matchid,
				method: 'GET',
				accessToken: state.Auth.access_token,
			});
			const match = resp;
			var live = [7, 4, 6, 7, 5, 3, 11];
			var finished = [8, 15, 14, 18, 10, 9];
			var dateTime = moment(match.startDate).utc();
			match.finished = finished.includes(match.statusCode);
			match.live = live.includes(match.statusCode);
			match.futureMatch = moment().utc() < dateTime;
			match.loading = false;
			match.date = dateTime.format('MMM DD YYYY');
			match.time = moment(match.startDate).utc().format('hh:mm a');
			setMatch(match);
			setVenue(resp.venue.name);
			setOfficial(resp.matchOfficials[0].official.name);
		} catch (error) {}
	};
	return (
		<div
			style={{
				width: '100%',
				maxWidth: '800px',
				height: '100vh',
				backgroundColor: '#02030a',
			}}
		>
			<Header
				left={<ArrowBackIosOutlined className={'action_logo'} onClick={() => navigate(-1)} />}
				center={<div className="header_title">Match Detail</div>}
			/>
			{match == null ? (
				<div>
					<Loader type="spinner-circle" bgColor={'#FFFFFF'} color={'#FFFFFF'} size={70} />
				</div>
			) : (
				<>
					<div className="match_details_league">
						<div
							style={{
								justifyContent: 'center',
								fontSize: '14px',
								fontWeight: 'bolder',
								marginLeft: 10,
							}}
						>
							{match.league.name}
						</div>
					</div>
					<ScoreCard2 match={match} nobg={true} />
					<Tabs className={'match-details__react-tabs'}>
						{!match.futureMatch ? (
							<TabList className={'match-details__react-tabs__tab-list'}>
								<Tab className={'match-details__react-tabs__tab'}>
									<div className="match-details__react-tabs__tab__text">INFO</div>
								</Tab>
								{/* <Tab className={"match-details__react-tabs__tab"}>
                  <div className="match-details__react-tabs__tab__text">
                    SUMMARY
                  </div>
                </Tab> */}
								<Tab className={'match-details__react-tabs__tab'}>
									<div className="match-details__react-tabs__tab__text">STATS</div>
								</Tab>
							</TabList>
						) : (
							''
						)}

						<TabPanel>
							<Info match={match} venue={venue} official={official} />
						</TabPanel>
						{/* <TabPanel>
              <Summary match={match} venue={venue} official={official} />
            </TabPanel> */}
						<TabPanel>
							<Stats match={match} venue={venue} official={official} />
						</TabPanel>
					</Tabs>
				</>
			)}
		</div>
	);
}
