import { Avatar } from "@mui/material";
import ButtonDX from ".";

export function SocialButton(props) {
  return (
    <ButtonDX
      style={{
        width: "48%",
        height: 40,
        backgroundColor: "#141927",
        margin: 0,
        display: "flex",
      }}
      loading={props.loading}
      onClick={props.onClick}
      text={
        <div
          style={{
            width: "100%",
            flexDirection: "row",
            fontSize: 11,
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Avatar
            style={{
              width: 30,
            }}
            src={props.image}
            variant="square"
          />
          {props.text}
        </div>
      }
    />
  );
}
