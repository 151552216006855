import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import {
  FavoriteBorderOutlined,
  ArrowForwardIosOutlined,
  Circle,
} from "@mui/icons-material";
import "./index.scss";
import AppContext from "../../../common/context";
import { OptionsList } from "..";
import { useContext, useEffect, useState } from "react";
import Api from "../../../common/api";
import moment, { duration } from "moment";
import { Badge, BasicModal, ButtonDX, TeamAvatar } from "../../atoms";

export default function Tennis(props) {
  var league = props.league;

  const menuItems = [
    {
      name: (
        <div style={{ flexDirection: "row", width: "85%", margin: "auto" }}>
          <div style={{ justifyContent: "center" }}>
            <Avatar
              style={{ height: 20, width: 20 }}
              src={league[0].league.logo}
              variant="circular"
            />
          </div>
          <div
            style={{
              justifyContent: "center",
              fontSize: "12px",
              fontWeight: "bolder",
              marginLeft: 10,
            }}
          >
            {league[0].league.name}
            <br />
            <span
              style={{
                fontSize: "10px",
                fontWeight: "lighter",
                color: "#807f8a",
              }}
            ></span>
          </div>
        </div>
      ),
      action: "expand",
      showData: () => showMatches(league),
    },
  ];

  return (
    <>
      <OptionsList OptionListItem={menuItems} />
    </>
  );
}

const showMatches = (matches) => {
  return (
    <>
      {matches.map((match, index) => {
        var live = [7, 4, 6, 7, 5, 3, 11];
        var finished = [8, 15, 14, 18, 10];
        var dateTime = moment(match.startDate).utc();
        match.finished = finished.includes(match.statusCode);
        match.live = live.includes(match.statusCode);
        match.futureMatch = moment().utc() < dateTime;
        match.loading = false;
        match.date = dateTime.format("MMM DD YYYY");
        match.time = moment(match.startDate).utc().format("hh:mm a");

        return <ScoreCard match={match} key={index} />;
      })}
    </>
  );
};

export const ScoreCard = (props) => {
  var match = props.match ? props.match : props;
  const [timeRemaining, setTimeRemaining] = useState("");
  const [selectedTeam1, setselectedTeam1] = useState(false);
  const [selectedTeam2, setselectedTeam2] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      match.questions.length != 0 &&
      match.questions[0]?.fanResponses.length != 0 &&
      match.questions[0]?.fanResponses != undefined &&
      match.questions[0]?.fanResponses[0] &&
      match.questions[0]?.fanResponses[0].answer == match.team1Id
    ) {
      if (
        (match.questions.length == 0 ||
          match.questions[0]?.fanResponses.length == 0 ||
          match.questions[0]?.fanResponses == undefined) &&
        match.questions[0]?.fanResponses[0].answer == match.team1Id
      ) {
        setselectedTeam1(true);
      } else {
        setselectedTeam2(true);
      }
    } else {
    }
  }, []);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      var now = moment().utc();
      var duration = moment.duration(moment(match.startDate).local().diff(now));
      var hh = parseInt(duration.asHours());
      var mm = parseInt(duration.asMinutes()) % 60;
      var ss = parseInt(duration.asSeconds()) % 60;
      setTimeRemaining(hh + ":" + mm + ":" + ss);
      if (hh < 0) {
        match.finished = true;
      }
    }, 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [timeRemaining]);

  return (
    <div
      className="tennis_match_container"
      onClick={() => {
        navigate("/matchDetails/" + match.matchId, {
          replace: false,
          state: match,
        });
      }}
    >
      <div className="tennis_matchupdate_Time">
        <div>{new moment(match.startDate).format("h:mm a")}</div>
        <div>
          {match.live ? (
            <div className="live">
              <Badge
                className={"live"}
                text={
                  <>
                    Live <Circle style={{ fontSize: 8 }} />
                  </>
                }
                width={"50%"}
              />
            </div>
          ) : (
            ""
          )}
          {match.status.includes("finished") ? "Finished" : ""}
        </div>
      </div>
      <div
        style={{
          flexDirection: "row",
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingLeft: "3%",
          paddingRight: "3%",
        }}
      >
        <div className="team_players_name">
          <div className="tennis_matchteam_name">
            {match.player1Id
              ? match.player1.name
              : match.team1
              ? match.team1.name
              : "-"}
          </div>
          <div className="tennis_matchteam_name">
            {match.player2Id
              ? match.player2.name
              : match.team2
              ? match.team2.name
              : "-"}
          </div>
        </div>
        <div className="team_players_score">
          <div className={"match_score" + (props.nobg ? " nobg" : "")}>
            {(match.live || match.finished) && !match.futureMatch ? (
              <Score match={match} />
            ) : (
              <Remaining match={match} timeRemaining={timeRemaining} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Score = (props) => {
  const [score1, setScore1] = useState();
  const [score2, setScore2] = useState();

  var match = props.match;
  useEffect(() => {
    try {
      setScore1(JSON.parse(match.team1Score));
      setScore2(JSON.parse(match.team2Score));
    } catch (e) {
      console.log("error", e);
    }
  }, [match]);

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {match && score1 ? (
        <div style={{ flexDirection: "column", fontSize: "20px" }}>
          <div style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
            {score1.current != null ? <div>{score1.current}</div> : ""}
            {score1.period_1 != null ? <div>{score1.period_1}</div> : ""}
            {score1.period_2 != null ? <div>{score1.period_2}</div> : ""}
            {score1.period_3 != null ? <div>{score1.period_3}</div> : ""}
            {score1.period_1_tie_break != null ? (
              <div>{score1.period_1_tie_break}</div>
            ) : (
              ""
            )}
            {score1.period_2_tie_break != null ? (
              <div>{score1.period_2_tie_break}</div>
            ) : (
              ""
            )}
          </div>
          <div style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
            {score2.current != null ? <div>{score2.current}</div> : ""}
            {score2.period_1 != null ? <div>{score2.period_1}</div> : ""}
            {score2.period_2 != null ? <div>{score2.period_2}</div> : ""}
            {score2.period_3 != null ? <div>{score2.period_3}</div> : ""}
            {score2.period_1_tie_break != null ? (
              <div>{score2.period_1_tie_break}</div>
            ) : (
              ""
            )}
            {score2.period_2_tie_break != null ? (
              <div>{score2.period_2_tie_break}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            flex: 3,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          To Be Updated
        </div>
      )}
    </div>
  );
};

export const Remaining = (props) => {
  var match = props.match;
  const { state } = useContext(AppContext);
  const [question, setQuestion] = useState();
  const [fanResponse, setFanResponse] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    getquestion(match.matchId);
  }, []);

  const getquestion = async (matchid) => {
    try {
      var resp = await Api({
        route: "matches/" + matchid,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setQuestion(resp.questions);
      setFanResponse(resp.questions[0].fanResponses);
    } catch (error) {
      //alert
    }
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {open && question ? (
        <BasicModal
          open={open}
          setOpen={setOpen}
          match={match}
          question={question}
        />
      ) : (
        ""
      )}
      <div className="time_remaining-text">Time Remaining</div>
      <div className="time_remaining">{props.timeRemaining}</div>
      {question &&
      (match.questions.length == 0 ||
        match.questions[0]?.fanResponses.length == 0 ||
        match.questions[0]?.fanResponses == undefined) ? (
        <ButtonDX
          className={"play_win_btn"}
          type={"gradient"}
          onClick={handleOpen}
          loading={match.loading}
          loadersize={15}
          loaderheight={15}
          disabled={match.loading ? true : false}
          text={"PLAY TO WIN"}
        ></ButtonDX>
      ) : (
        ""
      )}
    </div>
  );
};
