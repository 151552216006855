import React, { useEffect, useContext, useState } from "react";
import Header from "../header";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIosOutlined, SearchOutlined } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AppContext from "../../../common/context";
import Api from "../../../common/api";
import "./index.scss";
import { ButtonDX } from "../../atoms";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Loader from "react-js-loader";
import moment from "moment";
export default function Football(props) {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const match = props;
  var finished = [8, 15, 14, 18, 10, 9];
  var live = [7, 4, 6, 7, 5, 3, 11];
  var dateTime = moment(match.startDate).utc();
  match.finished = finished.includes(match.statusCode);
  match.live = live.includes(match.statusCode);
  match.futureMatch = moment().utc() < dateTime;
  match.loading = false;
  match.date = dateTime.format("MMM DD YYYY");
  match.time = moment(match.startDate).utc().format("hh:mm a");

  const [players, setPlayers] = useState();
  const [players1, setPlayers1] = useState();
  const [players2, setPlayers2] = useState();
  const [teamid, setTeamid] = useState();
  const team2Id = match.team2Id
    ? params.teamId == match.team2Id
    : match.team1Id;
  const [timeRemaining, setTimeRemaining] = useState();

  useEffect(() => {
    let timer1 = setTimeout(() => {
      var now = moment().utc();
      var duration = moment.duration(moment(match.startDate).local().diff(now));
      var hh = parseInt(duration.asHours());
      var mm = parseInt(duration.asMinutes()) % 60;
      var ss = parseInt(duration.asSeconds()) % 60;
      setTimeRemaining(hh + ":" + mm + ":" + ss);
    }, 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [timeRemaining]);
  useEffect(() => {
    getPlayers(params.matchId, params.teamId);
    getPlayers1(params.matchId, params.teamId);
    {
      team2Id
        ? getPlayers2(params.matchId, match.team1Id)
        : getPlayers2(params.matchId, match.team2Id);
    }
  }, []);
  const getPlayers = async (matchid, teamid) => {
    try {
      var resp = await Api({
        route: "matchplayers/" + matchid + "/" + teamid,
        method: "Get",
        accessToken: state.Auth.access_token,
      });
      setPlayers(resp);
    } catch (error) {}
  };
  const getPlayers1 = async (matchid, teamid) => {
    try {
      var resp = await Api({
        route: "matchplayers/" + matchid + "/" + teamid,
        method: "Get",
        accessToken: state.Auth.access_token,
      });

      setPlayers1(resp);
    } catch (error) {}
  };
  const getPlayers2 = async (matchid, teamid) => {
    try {
      var resp = await Api({
        route: "matchplayers/" + matchid + "/" + teamid,
        method: "Get",
        accessToken: state.Auth.access_token,
      });
      setPlayers2(resp);
    } catch (error) {}
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#02030a",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            style={{ fontSize: "30px", padding: "10px" }}
            onClick={() => navigate(-1)}
          />
        }
        center={
          <div style={{ fontSize: "20px" }}>
            {!match.futureMatch ? "Match Statistics" : "Squad Detail"}
          </div>
        }
        right={
          <div
            style={{ justifyContent: "end", flexDirection: "row", flex: "1" }}
          >
            <NotificationsIcon style={{ fontSize: "30px", padding: "10px" }} />
            <ShareIcon style={{ fontSize: "30px", padding: "10px" }} />
            <SearchOutlined style={{ fontSize: "30px", padding: "10px" }} />
          </div>
        }
      />
      {(match.live || match.finished) && !match.futureMatch ? (
        <ScoreBoard match={match} params={params} />
      ) : match.futureMatch && players ? (
        <SquadDetails match={match} players={players} getPlayers={getPlayers} />
      ) : (
        <Loader
          type="bubble-top"
          bgColor={"#FFFFFF"}
          color={"#FFFFFF"}
          size={35}
        />
      )}
    </div>
  );
}

export const ScoreBoard = (props) => {
  const [matchDetails, setMatchDetails] = useState({});
  const match = props.match;

  useEffect(() => {
    setMatchDetails(JSON.parse(match.matchDetails));
  }, []);
  const keys = matchDetails ? Object.keys(matchDetails) : "";

  const runCallback = (cb) => {
    return cb();
  };

  return (
    <>
      {matchDetails ? (
        <div
          style={{
            marginTop: "10px",
            background: "#090729",
            borderRadius: "24px",
            marginLeft: "7px",
            marginRight: "7px",
            overflow: "scroll",
            color: "white",
            padding: "6px",
          }}
        >
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              padding: "15px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
              flex: "1 1 0",
              // borderBottom: '1px solid black',
              marginBottom: "5px",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={2}>
                {match.team1.name}
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  fontWeight: "bolder",
                  justifyContent: "flex-end",
                  fontSize: "30px",
                }}
              >
                {match.team1Score}
              </Grid>

              <Grid item xs={4} style={{ justifyContent: "center" }}>
                STATISTICS
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  fontWeight: "bolder",
                  justifyContent: "flex-start",
                  fontSize: "30px",
                }}
              >
                {match.team2Score}
              </Grid>
              <Grid item xs={2}>
                {match.team2.name}
              </Grid>
            </Grid>
          </Box>

          {runCallback(() => {
            const row = [];
            for (
              var i = 0;
              i < Object.values(matchDetails[keys[0]] || {}).length;
              i++
            ) {
              row.push(
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    padding: "15px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "white",
                    flex: "1 1 0",
                    // borderBottom: '1px solid black',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {matchDetails[keys[0]][i].value == null
                        ? "-"
                        : matchDetails[keys[0]][i].value}
                    </Grid>
                    <Grid item xs={8} style={{ justifyContent: "center" }}>
                      {matchDetails[keys[0]][i].type}
                    </Grid>
                    <Grid item xs={2}>
                      {matchDetails[keys[1]][i].value == null
                        ? "-"
                        : matchDetails[keys[1]][i].value}
                    </Grid>
                  </Grid>
                </Box>
              );
            }
            return row;
          })}
        </div>
      ) : (
        <Loader
          type="bubble-top"
          bgColor={"#FFFFFF"}
          color={"#FFFFFF"}
          size={35}
        />
      )}
    </>
  );
};

export const SquadDetails = (props) => {
  const match = props;

  const [bt1, setBt1] = useState("gradient");
  const [bt2, setBt2] = useState("dark");
  const bt1Clicked = () => {
    props.getPlayers(props.match.matchId, props.match.team1Id);
    setBt1("gradient");
    setBt2("dark");
  };
  const bt2Clicked = () => {
    props.getPlayers(props.match.matchId, props.match.team2Id);
    setBt2("gradient");
    setBt1("dark");
  };

  return (
    <>
      {props.players ? (
        <div
          style={{
            margin: "20px",
            overflowY: "scroll",
            color: "white",
          }}
        >
          <div style={{ width: "100%", marginBottom: "3%" }}>
            <div
              style={{
                backgroundColor: "#141233",
                marginBottom: "1px",
                padding: "15px",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                color: "white",
                flex: "1",
                borderTopLeftRadius: "25px",
                borderTopRightRadius: "25px",
              }}
            >
              {props.match ? (
                <>
                  <ButtonDX
                    style={{
                      fontSize: "10px",
                      height: "auto",
                      padding: "5px",
                      width: "40%",
                    }}
                    type={bt1}
                    onClick={bt1Clicked}
                    loading={match.loading}
                    loadersize={15}
                    loaderheight={15}
                    disabled={match.loading ? true : false}
                    text={props.match.team1.name}
                  ></ButtonDX>
                  <ButtonDX
                    style={{
                      fontSize: "10px",
                      height: "auto",
                      padding: "5px",
                      width: "40%",
                    }}
                    type={bt2}
                    onClick={bt2Clicked}
                    loading={match.loading}
                    loadersize={15}
                    loaderheight={15}
                    disabled={match.loading ? true : false}
                    text={props.match.team2.name}
                  ></ButtonDX>
                </>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                backgroundColor: "#141233",
                marginBottom: "1px",
                padding: "15px",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                color: "white",
                // flex: '1 1 0',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    Player
                  </Grid>
                  <Grid item xs={6}>
                    Role
                  </Grid>
                </Grid>
              </Box>
            </div>
            {props.players.map((n) => {
              return (
                <>
                  <div
                    style={{
                      backgroundColor: "#141233",
                      marginBottom: "1px",
                      padding: "15px",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flex: "1 1 0",
                      color: "white",
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {n.teamPlayer.player.name}
                        </Grid>
                        <Grid item xs={6}>
                          {n.teamPlayer.player.playerRole}
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <Loader
          type="bubble-top"
          bgColor={"#FFFFFF"}
          color={"#FFFFFF"}
          size={35}
        />
      )}
    </>
  );
};
