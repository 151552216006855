import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import AppContext from "../../../../common/context";
import Api from "../../../../common/api";
import "./index.scss";
import { Avatar, FormControl, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";

export default function FootBallLeagueTable({ match, venue, official }) {
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leagueTable, setLeagueTable] = useState([]);
  const [group, setGroup] = useState(null);
  const [groups, setGroups] = useState([]);
  const [noData, setNoData] = useState(false);

  const getLeagueTable = async (matchid, teamid) => {
    setLoading(true);
    try {
      var resp = await Api({
        route: "standing/" + match.seasonId,
        method: "Get",
        accessToken: state.Auth.access_token,
      });
      if (resp == null) {
        setNoData(true);
        return;
      }
      setLeagueTable(resp);
      var list = JSON.parse(resp.standing);
      if (list.length <= 0) {
        setNoData(true);
        return;
      }
      var listOfGroups = [];
      for (var i = 0; i < list.length; i++) {
        listOfGroups.push(list[i][0].description + ": " + list[0][0].group);
      }
      setGroups(listOfGroups);
      setGroup(0);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getLeagueTable();
  }, []);

  const handleChange = (event) => {
    setGroup(event.target.value);
  };

  const getTable = (data) => {
    var ranks = JSON.parse(data.standing);
    return ranks[group].map((x, index) => {
      return (
        <div key={index} className="league-table-row">
          <div className="league-table-col-1">{index + 1}</div>
          <div className="league-table-col-2">
            <div style={{ flex: "4", alignItems: "center" }}>
              <Avatar
                style={{ height: 30, width: 30 }}
                src={x.team.logo}
                variant="circular"
              />
            </div>
            <div style={{ flex: "9", textAlign: "center" }}>{x.team.name}</div>
          </div>
          <div className="league-table-col-3">{x.all.played}</div>
          <div className="league-table-col-3">{x.all.win}</div>
          <div className="league-table-col-3">{x.points}</div>
        </div>
      );
    });
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#02030a",
      }}
    >
      {loading || error ? (
        !noData ? (
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              paddingTop: "5%",
              paddingBottom: "5%",
            }}
          >
            <Loader
              type="spinner-circle"
              bgColor={"#FFFFFF"}
              color={"#FFFFFF"}
              size={90}
            />
          </div>
        ) : (
          ""
        )
      ) : (
        <>
          <div
            style={{
              width: "100%",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <FormControl
              id={"select-form"}
              variant="standard"
              style={{
                margin: "auto",
                marginBottom: 10,
                width: "90%",
                display: "flex",
              }}
            >
              <Select
                className="leagueTableDropdown"
                id="groups"
                value={group}
                label="Group"
                onChange={handleChange}
              >
                {groups.map((x, i) => {
                  return (
                    <MenuItem key={i} id={"menuItem"} value={i}>
                      {x}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div
              style={{
                width: "95%",
                alignSelf: "center",
              }}
            >
              <div className="league-table-title">
                <div className="league-table-col-1">#</div>
                <div className="league-table-col-2">TEAM</div>
                <div className="league-table-col-3">P</div>
                <div className="league-table-col-3">W</div>
                <div className="league-table-col-3">PTS</div>
              </div>
            </div>
            <div
              style={{
                width: "95%",
                alignSelf: "center",
                overflowY: "scroll",
              }}
            >
              {getTable(leagueTable)}
            </div>
          </div>
        </>
      )}

      {noData ? (
        <div
          style={{
            color: "#706e6ebf",
            width: "100%",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          {" "}
          League Standing Table is not available
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
