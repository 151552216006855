import { CONFIG } from "../constants";
import errorHandler from "../errorHandler";

const Api = async (req) => {
  var url = CONFIG.API_URL + req.route;
  if (req.params) {
    url += "?" + new URLSearchParams(req.params);
  }

  if (req.body != null && !req.formData) {
    req.body = JSON.stringify(req.body);
  }

  var config = {
    method: req.method ? req.method : "GET",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + req.accessToken,
    },
  };

  if (req.formData) {
    delete config.headers["Content-Type"];
  }

  if (req.body !== null) {
    config.body = req.body;
  }
  var Data = await fetch(url, config)
    .then(async (response) => {
      if (response.ok)
        return Promise.resolve(response.json()).then((responseInJson) => {
          if (responseInJson.code === 200) {
            return Promise.resolve(responseInJson.message);
          } else return Promise.reject(responseInJson.message);
        });

      if (!response.ok) {
        console.log(response);
      }

      return Promise.resolve(response.json()).then((responseInJson) => {
        return Promise.reject(responseInJson);
      });
    })
    .then(function (result) {
      return result;
    })
    .catch(function (error) {
      console.log("Error: " + JSON.stringify(error));
      errorHandler(error);
      return;
    });

  return Data;
};

export default Api;
