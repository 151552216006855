import { useState } from "react";
import "./index.scss";
import {
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import AppContext from "../../../common/context";
import { useContext } from "react";
export default function optionsList(props) {
  return (
    <div className="optionList">
      {props.OptionListItem.map((listitem, index) => {
        return (
          <ListItem
            updateScreen={props.updateScreen}
            listitem={listitem}
            key={index}
          />
        );
      })}
    </div>
  );
}

const ListItem = (props) => {
  const { state, dispatch } = useContext(AppContext);
  const [show, setShow] = useState(false);

  const clickAction = () => {
    if (props.listitem.action == null) {
      props.updateScreen(
        props.listitem.name?.replaceAll(" ", "").toLowerCase()
      );
    } else if (props.listitem.action == "expand") {
      setShow(!show);
    } else {
      props.listitem.action();
    }
  };
  return (
    <>
      <div
        style={props.listitem.style ? props.listitem.style : {}}
        className="optionListItem"
        key={props.index}
        onClick={clickAction}
      >
        {props.listitem.icon}
        <span>{props.listitem.name}</span>
        {props.listitem.action == "expand" ? (
          <div
            style={{ justifyContent: "end", flexDirection: "row", flex: "1" }}
          >
            <div style={{ justifyContent: "center" }}>
              {!show ? (
                <KeyboardArrowDownOutlined className={"action_logo"} />
              ) : (
                <KeyboardArrowUpOutlined className={"action_logo"} />
              )}
            </div>
          </div>
        ) : (
          props.listitem.rightIcon
        )}
      </div>
      {show && props.listitem.showData ? props.listitem.showData() : ""}
    </>
  );
};
