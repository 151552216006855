export const Sports = {
  Football: 1,
  Circket: 2,
  Tennis: 3,
};

export const CouponStatus = {
  1: "Valid",
  2: "Expired",
  3: "Redeemed",
};
