import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Typography } from '@mui/material';
import Api from '../../../common/api';
import AppContext from '../../../common/context';
import { ButtonDX } from '../../atoms';
import OtpInput from 'react-otp-input';
import './index.scss';
const style = {
	position: 'relative',
	top: '40%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '300px',
	bgcolor: '#232148',
	boxShadow: 24,
	color: 'white',
	// p: 4,
	padding: '15px',
	// height: '400px',
};

export const OTPReedemModal = (props) => {
	const { state } = useContext(AppContext);
	const [otp, setOtp] = useState('');
	const [error, setError] = useState('');

	const OTP = (e) => {
		setOtp(e);
		console.log(e);
	};
	const object = props.uniqueIdentifier;
	const handleRedeemption = async () => {
		object.sort((a, b) => (a.status > b.status ? 1 : -1));
		var body = {
			uniqueIdentifier: object[0].uniqueIdentifier,
			code: otp,
		};
		try {
			var resp = await Api({
				route: 'Coupons/redeeme',
				method: 'POST',
				body,
				accessToken: state.Auth.access_token,
			});

			props.setOpenCong(true);
			props.setOpenOTP(false);
		} catch (error) {
			console.log('Reedempiton Error', error);
			setError(error);
		}
	};
	return (
		<Modal
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Grid container spacing={1}>
					<Grid item xs={12} justifyContent="end">
						<CloseIcon onClick={() => props.setOpenOTP(false)} />
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<Avatar
							style={{ height: '100%', width: '50%' }}
							src="https://i.pinimg.com/564x/5f/f8/aa/5ff8aa286413e2b47204efc3dae0a8f3--fashion-logos-fashion-branding.jpg"
							variant="square"
						/>
					</Grid>
					<Grid
						item
						xs={12}
						justifyContent="center"
						// style={{
						// 	marginTop: '12px',
						// }}
					>
						<Typography
							style={{
								fontWeight: '700',
								fontSize: '24px',
								lineHeight: '24px',
								textAlign: 'center',
								alignItems: 'center',
								letterSpacing: '0.18px',
								// marginBottom: '10px',
								// width: '90%',
							}}
						>
							Reedem Coupon
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						justifyContent="center"
						// style={{
						// 	marginTop: '12px',
						// }}
					>
						<Typography
							style={{
								fontWeight: '300',
								fontSize: '16px',
								lineHeight: '24px',
								textAlign: 'center',
								alignItems: 'center',
								letterSpacing: '0.15px',
								// marginBottom: '10px',
								// width: '90%',
							}}
						>
							Please ask staff to enter merchant code to redeem this coupon.
						</Typography>
					</Grid>
					{error != null ? (
						<Grid item xs={12} justifyContent="center">
							<Typography
								style={{
									fontWeight: '300',
									fontSize: '16px',
									lineHeight: '24px',
									textAlign: 'center',
									alignItems: 'center',
									letterSpacing: '0.15px',
									// marginBottom: '10px',
									// width: '90%',
								}}
							>
								{error}
							</Typography>
						</Grid>
					) : (
						''
					)}
					<Grid
						item
						xs={12}
						justifyContent="center"
						style={{
							marginBottom: '12px',
						}}
						// flexDirection="row"
					>
						<OtpInput
							value={otp}
							onChange={OTP}
							// style={{ flexDirection: 'row' }}
							containerStyle={{ flexDirection: 'row' }}
							inputStyle={{
								opacity: '0.5',
								padding: '10px',
								border: 'none',
								boxShadow: ` 0px 4px 4px rgba(0, 0, 0, 0.25)`,
								marginRight: '10px',
								marginLeft: '10px',
							}}
							numInputs={4}
							// style={{ marginLeft: '10px' }}
							// separator={<span>-</span>}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						justifyContent="center"
						// style={{ marginTop: '10px' }}
					>
						<ButtonDX
							style={{
								fontSize: '14px',
								height: 'auto',
								padding: '5px',
								width: '217px',
								borderRadius: '4px',
								boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
								marginTop: 'auto',

								fontWeight: '500',
								letterSpacing: '1.25px',
							}}
							type={'gradient'}
							onClick={handleRedeemption}
							loadersize={15}
							loaderheight={15}
							text="REDEEM"
						></ButtonDX>
					</Grid>
					<Grid
						item
						xs={12}
						justifyContent="center"
						// style={{ marginTop: '10px' }}
					>
						<ButtonDX
							style={{
								fontSize: '14px',
								height: 'auto',
								padding: '5px',
								width: '217px',
								borderRadius: '4px',
								boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
								marginTop: 'auto',

								fontWeight: '500',
								letterSpacing: '1.25px',
							}}
							type={'dark'}
							onClick={props.handleClose}
							loadersize={15}
							loaderheight={15}
							text="Terms & conditions"
						></ButtonDX>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};
