export default function pill(props) {
  return (
    <div
      style={{
        position: "absolute",
        fontSize: "7px",
        backgroundColor: "#ffffff",
        color: "#4d5b95",
        borderRadius: "25px",
        height: "5px",
        width: "5px",
        right: "10px",
      }}
    ></div>
  );
}
