import { ArrowBackIosOutlined, AddCircleOutlined } from '@mui/icons-material';
import { Avatar, Badge, Button, InputAdornment, Modal } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UPDATE_USER, UPDATE_PROFILE } from '../../common/action';
import Api from '../../common/api';
import AppContext from '../../common/context';
import { TextFieldDX, Alert } from '../atoms';
import { Header, OptionsList, UploadDoc } from '../compound';
import { CONFIG } from '../../common/constants';
import { dataURLtoBlob, getGreetingMessage } from '../../common/utils';
import { LOGOUT } from '../../common/action';
import { ButtonDX } from '../atoms';
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom';

export default function Profile(props) {
	const { state, dispatch } = useContext(AppContext);
	const [user, setUser] = useState(state.Auth);
	const [profileImage, setProfileImage] = useState(state.Auth.profileImage);
	const navigate = useNavigate();
	if (user.joiningCode == null) {
		setUser({ ...user, joiningCode: '' });
	}
	const [loading, setLoading] = useState(false);
	const [generateCodeLoading, setGenerateCodeLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState({
		error: false,
		default: null,
	});
	const [uploadModal, setUploadModal] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	user.language = 'English - US';

	const auth = state.Auth;

	useEffect(() => {
		if (auth.userId === 0 || !auth.emailConfirmed) {
			navigate('/', { replace: true });
		}
	});

	const logout = () => {
		dispatch(LOGOUT());
		navigate('/', { replace: true });
	};

	const UpdateUser = async () => {
		setLoading(true);
		var overAllError = false;

		if (!overAllError) {
			var body = {
				firstName: user.firstName,
				lastName: user.lastName,
				language: user.language,
			};
			try {
				var resp = await Api({
					route: 'users/' + user.userId,
					body,
					method: 'Post',
					accessToken: user.access_token,
				});
				dispatch(
					UPDATE_USER({
						firstName: user.firstName,
						lastName: user.lastName,
						language: user.language,
					})
				);
			} catch (error) {
				setErrorMessage({
					...errorMessage,
					default: error.message,
				});
			}
		}
		setLoading(false);
	};

	const UpdateProfile = async (capturedImage) => {
		setLoading(true);
		localStorage.setItem('allowBack', JSON.stringify({ allowBack: false }));
		var imageFile = dataURLtoBlob(capturedImage);
		let formData = new FormData();
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};
		if (imageFile != null) {
			try {
				const compressedFile = await imageCompression(imageFile, options);
				formData.append('file', compressedFile, 'filename.png');
			} catch (error) {
				console.log(error);
			}

			try {
				var resp = await Api({
					route: 'users/uploadprofile',
					body: formData,
					method: 'Post',
					accessToken: user.access_token,
					formData: true,
				});
				dispatch(
					UPDATE_PROFILE({
						profileImage: resp.profileImage,
					})
				);
				setProfileImage(resp.profileImage);
			} catch (error) {
				setErrorMessage({
					...errorMessage,
					default: error.message,
				});
			}
			setLoading(false);
			localStorage.setItem('allowBack', JSON.stringify({ allowBack: true }));
		} else {
			setLoading(false);
			localStorage.setItem('allowBack', JSON.stringify({ allowBack: true }));
		}
	};

	const handleChange = (prop) => (event) => {
		setUser({ ...user, [prop]: event.target.value });
	};

	const hideAlert = () => {
		setShowAlert(false);
		setAlertMessage('');
	};

	const ShareCode = async () => {
		setGenerateCodeLoading(true);
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				type: 'Share',
				data: {
					message:
						'Hello, This is my Sportl Code: ' +
						user.joiningCode +
						' \n' +
						// user.firstName + " " + user.lastName +
						'\nPlease add me to your leaderboard using the above code.',
				},
			})
		);
		setGenerateCodeLoading(false);
	};

	const askPermission = async () => {
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ type: 'AskPermission', data: 'profile' })
		);
	};

	const askPermissionAndUpload = async () => {
		setUploadModal(!uploadModal);
		askPermission();
	};

	return (
		<div
			id="AccountMenu"
			style={{
				width: '100%',
				maxWidth: '800px',
				height: '100vh',
				backgroundColor: '#02030A',
			}}
		>
			<Header
				left={
					<ArrowBackIosOutlined
						style={{ fontSize: '22px', padding: '25px' }}
						onClick={() => {
							navigate(-1);
						}}
					/>
				}
				center={
					<div
						style={{
							margin: '5px',
							/* font-family: 'Rubik', */
							fontStyle: 'normal',
							fontWeight: '600',
							fontSize: '20px',
							lineHeight: '26px',
						}}
					>
						My Profile
					</div>
				}
			/>
			<div
				style={{
					margin: '20px',
					overflowY: 'scroll',
					alignItems: 'center',
					marginBottom: '8px',
				}}
			>
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					onClick={askPermissionAndUpload}
					badgeContent={
						<AddCircleOutlined
							style={{
								fontSize: '45px',
								color: '#009AFD',
								backgroundColor: '#ffff',
								borderRadius: '100%',
								borderWidth: '0px',
							}}
						/>
					}
					overlap="circular"
				>
					<Avatar
						id={'Contained'}
						style={{
							height: '200px',
							width: '200px',
							display: 'flex',
							margin: '5%',
							fontSize: '25px',
						}}
						src={
							user.profileImage == null
								? 'https://www.maxpixel.net/static/photo/640/The-Facebook-Placeholder-Shape-Blue-Avatar-Icon-1577909.png'
								: CONFIG.API_URL + profileImage
						}
					/>
				</Badge>

				<div
					style={{
						color: '#ffff',
						marginBottom: '5%',
						// fontFamily: 'Rubik',
						fontStyle: 'normal',
						fontWeight: '600',
						fontSize: '22px',
						lineHeight: '26px',
					}}
				>
					{/* {getGreetingMessage()} */}
					{'John Doe'}
				</div>
				<TextFieldDX
					// buttontype="Dark"
					type={'text'}
					value={user.firstName ? user.firstName : ''}
					style={{ width: '100%' }}
					endlabel={'First Name'}
					onChange={handleChange('firstName')}
					onBlur={UpdateUser}
					error={errorMessage.email ? true : false}
					errorMessage={errorMessage.email}
				/>
				<TextFieldDX
					// buttontype="Dark"
					type={'text'}
					value={user.lastName ? user.lastName : ''}
					style={{ width: '100%' }}
					endlabel={'Last Name'}
					onChange={handleChange('lastName')}
					onBlur={UpdateUser}
					error={errorMessage.email ? true : false}
					errorMessage={errorMessage.email}
				/>
				<TextFieldDX
					// buttontype="Dark"
					type={'text'}
					value={user.email ? user.email : ''}
					style={{ width: '100%' }}
					endlabel={'Email'}
					disabled={true}
				/>
				<TextFieldDX
					// buttontype="Dark"
					type={'text'}
					value={user.language}
					style={{ width: '100%' }}
					endlabel={'Language'}
					onBlur={UpdateUser}
					onChange={handleChange('languange')}
					error={errorMessage.email ? true : false}
					errorMessage={errorMessage.email}
				/>
				<TextFieldDX
					disabled
					// buttontype="Dark"
					type={'text'}
					value={'Code'}
					style={{ width: '100%' }}
					endAdornment={
						<InputAdornment
							position="end"
							style={{
								position: 'relative',
								height: '80%',
								alignSelf: 'center',
								justifyContent: 'center',
								flexDirection: 'row',
							}}
						>
							<ButtonDX
								style={{
									fontSize: '13px',
									padding: '6px',
									color: '#fefefe',
									height: '35px',
									borderRadius: '8px',
								}}
								loading={generateCodeLoading}
								type={'primary'}
								onClick={() => {
									ShareCode();
								}}
								text={user.joiningCode}
							/>
						</InputAdornment>
					}
				/>
			</div>
			<div
				style={{
					justifyContent: 'center',
					alignSelf: 'center',
					width: '90%',
					paddingBottom: 25,
				}}
			>
				{/* <ButtonDX
					// type={'gradient'}
					onClick={() => {
						logout();
					}}
					loading={false}
					text={'Log Out'}
				/> */}
				<Button
					variant="contained"
					style={{ marginBottom: '10px', padding: '13px', borderRadius: '13px' }}
				>
					Save Changes
				</Button>
			</div>

			{uploadModal ? (
				<UploadDoc
					show={uploadModal}
					setshow={() => setUploadModal(!uploadModal)}
					UploadFile={UpdateProfile}
				/>
			) : (
				''
			)}
			<Alert
				title="Document Size"
				open={showAlert}
				alert={alertMessage}
				closeLabel="Retry"
				handleClose={() => hideAlert()}
				handlePopupClose={() => setShowAlert(false)}
			/>
		</div>
	);
}
