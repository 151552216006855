import { Circle } from "@mui/icons-material";
import "./index.scss";
import AppContext from "../../../common/context";
import { useContext, useEffect, useState } from "react";
import Api from "../../../common/api";
import moment from "moment";
import { Badge, BasicModal, ButtonDX, TeamAvatar } from "../../atoms";

export const ScoreCard2 = (props) => {
  var match = props.match ? props.match : props;
  const [timeRemaining, setTimeRemaining] = useState("");
  const [selectedTeam1, setselectedTeam1] = useState(false);
  const [selectedTeam2, setselectedTeam2] = useState(false);
  useEffect(() => {
    if (
      match.questions.length != 0 &&
      match.questions[0]?.fanResponses.length != 0 &&
      match.questions[0]?.fanResponses != undefined &&
      match.questions[0]?.fanResponses[0] &&
      match.questions[0]?.fanResponses[0].answer == match.team1Id
    ) {
      if (
        (match.questions.length == 0 ||
          match.questions[0]?.fanResponses.length == 0 ||
          match.questions[0]?.fanResponses == undefined) &&
        match.questions[0]?.fanResponses[0].answer == match.team1Id
      ) {
        setselectedTeam1(true);
      } else {
        setselectedTeam2(true);
      }
    } else {
    }
  }, []);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      var now = moment().utc();
      var duration = moment.duration(moment(match.startDate).local().diff(now));
      var hh = parseInt(duration.asHours());
      var mm = parseInt(duration.asMinutes()) % 60;
      var ss = parseInt(duration.asSeconds()) % 60;
      setTimeRemaining(hh + ":" + mm + ":" + ss);
      if (hh < 0) {
        match.finished = true;
      }
    }, 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [timeRemaining]);
  return (
    <div className={"match_score" + (props.nobg ? " nobg" : "")}>
      <div className="team__avatar__container">
        <TeamAvatar
          teamLogo={
            "https://img.freepik.com/premium-vector/tennis-logo-design-sports-logo_331749-147.jpg"
          }
          team={
            match.player1Id
              ? match.player1.name
              : match.team1
              ? match.team1.name
              : "-"
          }
          selectedTeam={selectedTeam1}
        />
        <span>
          {match.player1Id
            ? match.player1.name
            : match.team1
            ? match.team1.name
            : "-"}
        </span>
      </div>

      {(match.live || match.finished) && !match.futureMatch ? (
        <Score match={match} />
      ) : (
        <Remaining match={match} timeRemaining={timeRemaining} />
      )}
      <div className="team__avatar__container">
        <TeamAvatar
          teamLogo={
            "https://img.freepik.com/premium-vector/tennis-logo-design-sports-logo_331749-150.jpg?w=2000"
          }
          selectedTeam={selectedTeam2}
          team={
            match.player2Id
              ? match.player2.name
              : match.team2
              ? match.team2.name
              : "-"
          }
        />
        <span>
          {match.player2Id
            ? match.player2.name
            : match.team2
            ? match.team2.name
            : "-"}
        </span>
      </div>
    </div>
  );
};

export const Score = (props) => {
  const [score1, setScore1] = useState();
  const [score2, setScore2] = useState();

  var match = props.match;
  useEffect(() => {
    try {
      setScore1(JSON.parse(match.team1Score));
      setScore2(JSON.parse(match.team2Score));
    } catch (e) {
      console.log("error", e);
    }
  }, [match]);

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {match && score1 ? (
        <div className="team__score" style={{ fontSize: "20px" }}>
          <div>
            <span>{score1.current}</span>
          </div>
          <div>:</div>
          <div>
            <span>{score2.current}</span>
          </div>
        </div>
      ) : (
        <div
          style={{
            flex: 3,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          To Be Updated
        </div>
      )}
      {match.live ? (
        <div className="live">
          <Badge
            className={"live"}
            text={
              <>
                Live <Circle style={{ fontSize: 8 }} />
              </>
            }
            width={"50%"}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const Remaining = (props) => {
  var match = props.match;
  const { state } = useContext(AppContext);
  const [question, setQuestion] = useState();
  const [fanResponse, setFanResponse] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    getquestion(match.matchId);
  }, []);
  const getquestion = async (matchid) => {
    try {
      var resp = await Api({
        route: "matches/" + matchid,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setQuestion(resp.questions);
      setFanResponse(resp.questions[0].fanResponses);
    } catch (error) {
      //alert
    }
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {open && question ? (
        <BasicModal
          open={open}
          setOpen={setOpen}
          match={match}
          question={question}
        />
      ) : (
        ""
      )}
      <div className="time_remaining-text">Time Remaining</div>
      <div className="time_remaining">{props.timeRemaining}</div>
      {question &&
      (match.questions.length == 0 ||
        match.questions[0]?.fanResponses.length == 0 ||
        match.questions[0]?.fanResponses == undefined) ? (
        <ButtonDX
          className={"play_win_btn"}
          type={"gradient"}
          onClick={handleOpen}
          loading={match.loading}
          loadersize={15}
          loaderheight={15}
          disabled={match.loading ? true : false}
          text={"PLAY TO WIN"}
        ></ButtonDX>
      ) : (
        ""
      )}
    </div>
  );
};
