import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TeamCard } from "../../menu/followTeams";
import "./index.scss";
import Api from "../../../common/api";
import AppContext from "../../../common/context";
import Loader from "react-js-loader";

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const [teams, setTeams] = useState(null);
  const { state } = useContext(AppContext);
  const auth = state.Auth;

  useEffect(() => {
    getTeams();
  }, [value]);

  const getTeams = async () => {
    setTeams(null);
    try {
      var resp = await Api({
        route: "teams/bysports/" + (value + 1),
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      resp = resp.sort((a, b) => (a.name > b.name ? 1 : -1));
      setTeams(resp);
    } catch (error) {}
  };

  const PostBookMark = async (id) => {
    try {
      var resp = await Api({
        route: "teambookmarks/toggle",
        method: "POST",
        body: { teamId: id },
        accessToken: state.Auth.access_token,
      });
      return resp;
    } catch (error) {
      return null;
    }
  };

  return (
    <Tabs
      selectedIndex={value}
      onSelect={(index) => setValue(index)}
      className={"match-details__react-tabs"}
    >
      <TabList className={"match-details__react-tabs__tab-list"}>
        <Tab className={"match-details__react-tabs__tab"}>
          <div className="match-details__react-tabs__tab__text">Football</div>
        </Tab>
        <Tab className={"match-details__react-tabs__tab"}>
          <div className="match-details__react-tabs__tab__text">Circket</div>
        </Tab>
        <Tab className={"match-details__react-tabs__tab"}>
          <div className="match-details__react-tabs__tab__text">Tennis</div>
        </Tab>
      </TabList>
      <TabPanel className={"match-details__react-tabs__tab-panel"}>
        <>
          {teams != null && teams.length > 0 ? (
            <TeamCard
              teams={teams}
              PostBookMark={PostBookMark}
              sportId={value + 1}
            />
          ) : (
            <Loader
              type="bubble-top"
              bgColor={"#FFFFFF"}
              color={"#FFFFFF"}
              size={35}
              style={{ marginTop: "15px" }}
            />
          )}
        </>
      </TabPanel>
      <TabPanel className={"match-details__react-tabs__tab-panel"}>
        <>
          {teams != null && teams.length > 0 ? (
            <TeamCard
              teams={teams}
              PostBookMark={PostBookMark}
              sportId={value + 1}
            />
          ) : (
            <Loader
              type="bubble-top"
              bgColor={"#FFFFFF"}
              color={"#FFFFFF"}
              size={35}
              style={{ marginTop: "15px" }}
            />
          )}
        </>
      </TabPanel>
      <TabPanel className={"match-details__react-tabs__tab-panel"}>
        <>
          {teams != null && teams.length > 0 ? (
            <TeamCard
              teams={teams}
              PostBookMark={PostBookMark}
              sportId={value + 1}
            />
          ) : (
            <Loader
              type="bubble-top"
              bgColor={"#FFFFFF"}
              color={"#FFFFFF"}
              size={35}
              style={{ marginTop: "15px" }}
            />
          )}
        </>
      </TabPanel>
    </Tabs>
  );
}
