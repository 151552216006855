import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../common/context";
import Header from "../header";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import "./index.scss";
import Api from "../../../common/api";
import Theme from "../../../common/theme";
import NotificationItem from "./notificationItem";
import * as moment from "moment";

export default function Notification(props) {
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const getNotification = async () => {
    try {
      var resp = await Api({
        route: "usernotifications",
        method: "get",
        accessToken: state.Auth.access_token,
      });
      resp = resp.sort((a, b) => {
        return moment(b.createdAt) - moment(a.createdAt);
      });
      setNotifications(resp);
      setLoading(false);
    } catch (ex) {}
  };
  const markAsSeen = async () => {
    try {
      var resp = await Api({
        route: "usernotifications/seen",
        method: "get",
        accessToken: state.Auth.access_token,
      });
    } catch (ex) {}
  };

  const auth = state.Auth;
  useEffect(() => {
    if (auth.userId === 0 || !auth.emailConfirmed) {
      navigate("/", { replace: true });
    }
    getNotification();
    markAsSeen();

    document.addEventListener("refreshNotification", getNotification);

    return () => {
      document.addEventListener("refreshNotification", getNotification);
    };
  }, []);
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        maxWidth: "800px",
        width: "100%",
        height: "100vh",
        margin: "auto",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            className={"action_logo"}
            onClick={() => {
              navigate(-1);
            }}
          />
        }
        center={<div className="header_title">My Tasks</div>}
      />
      <div style={{ flex: 1, alignContent: "center", overflowY: "scroll" }}>
        {loading ? (
          <Loader
            type="spinner-circle"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={70}
          />
        ) : notifications.length > 0 ? (
          notifications.map((notification, index) => {
            return (
              <NotificationItem
                userNotification={notification}
                key={index}
                index={index}
              />
            );
          })
        ) : (
          <div
            style={{
              width: "100%",
              color: Theme.gray,
              textAlign: "center",
              flex: 1,
              justifyContent: "center",
            }}
          >
            No Notification
          </div>
        )}
      </div>
    </div>
  );
}
