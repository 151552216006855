import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../common/context";

import {
  LibraryBooksOutlined,
  SportsSoccerOutlined,
  SportsTennisOutlined,
  SportsCricketOutlined,
  SportsMotorsportsOutlined,
} from "@mui/icons-material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./tabsStyles.scss";
import MyFeed from "./myFeed";
import Football from "./football";
import Circket from "./circket";
import Tennis from "./tennis";
import { useNavigate } from "react-router-dom";

const getIndex = (screen) => {
  switch (screen) {
    case "myfeed":
      return 0;
    case "football":
      return 1;
    case "tennis":
      return 2;
    case "circket":
      return 3;
    case "formula1":
      return 4;
    default:
      return 0;
  }
};

export default function Home(props) {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(getIndex(props.screen));

  useEffect(() => {
    setTabIndex(getIndex(props.screen));
  });
  return (
    <div
      style={{
        background: "#02030a",
        width: "100%",
        height: "100%",
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Tabs
        className={"home__react-tabs"}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className={"home__react-tabs__tab-list"}>
          <Tab
            className={"home__react-tabs__tab"}
            onClick={() => navigate("/dashboard/myfeed")}
          >
            <div className="home__icon__border">
              <LibraryBooksOutlined className="home__icon" />
            </div>
            <div className="home__react-tabs__tab__text">MyFeed</div>
          </Tab>
          <Tab
            className={"home__react-tabs__tab"}
            onClick={() => navigate("/dashboard/football")}
          >
            <div className="home__icon__border">
              <SportsSoccerOutlined className="home__icon" />
            </div>
            <div className="home__react-tabs__tab__text">Football</div>
          </Tab>
          <Tab
            className={"home__react-tabs__tab"}
            onClick={() => navigate("/dashboard/tennis")}
          >
            <div className="home__icon__border">
              <SportsTennisOutlined className="home__icon" />
            </div>
            <div className="home__react-tabs__tab__text">Tennis</div>
          </Tab>
          <Tab
            className={"home__react-tabs__tab"}
            onClick={() => navigate("/dashboard/circket")}
          >
            <div className="home__icon__border">
              <SportsCricketOutlined className="home__icon" />
            </div>
            <div className="home__react-tabs__tab__text">Cricket</div>
          </Tab>
          <Tab
            className={"home__react-tabs__tab"}
            onClick={() => navigate("/dashboard/formula1")}
          >
            <div className="home__icon__border">
              <SportsMotorsportsOutlined className="home__icon" />
            </div>
            <div className="home__react-tabs__tab__text">Formula 1</div>
          </Tab>
        </TabList>

        <TabPanel className={"react-tabs__tab-panel"}>
          <MyFeed date={props.date} />
        </TabPanel>
        <TabPanel>
          <Football date={props.date} />
        </TabPanel>
        <TabPanel>
          <Tennis date={props.date} />
        </TabPanel>
        <TabPanel>
          <Circket date={props.date} />
        </TabPanel>
        <TabPanel>
          <h2 style={{ textAlign: "center" }}>Coming Soon</h2>
        </TabPanel>
      </Tabs>
    </div>
  );
}
