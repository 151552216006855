import React, { useEffect, useState, useContext } from "react";
import { facebook } from "../../../asset";
import { SocialButton } from ".";
import Api from "../../../common/api";
import AppContext from "../../../common/context";
import { LOGIN } from "../../../common/action";

export default function FacebookLoginButton(props) {
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const FacebookSignUpReq = async (response) => {
    setLoading(true);
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "GetFacebookToken", data: "" })
    );
  };

  useEffect(() => {
    const handelFacebookToken = async (event) => {
      setLoading(true);
      var body = {
        accessToken: event.detail,
      };
      try {
        var resp = await Api({
          route: "facebooklogin",
          body,
          method: "POST",
          accessToken: state.Auth.access_token,
        });
        dispatch(LOGIN(resp));

        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "SetUpFireBase", data: "" })
        );
      } catch (error) {
        props.onSocialError(
          error.message
            ? error.message
            : "Unable to Register, Please try later."
        );
      }
      setLoading(false);
    };
    document.addEventListener("FacebookToken", handelFacebookToken, false);

    return () => {
      document.removeEventListener("FacebookToken", handelFacebookToken, false);
    };
  }, []);

  return (
    <SocialButton
      loading={loading}
      disabled={loading ? true : false}
      onClick={FacebookSignUpReq}
      image={facebook}
      text={"Facebook"}
    />
  );
}
