import { ButtonDX, TypographyDX, CheckboxDX, ErrorMessage } from "../../atoms";
import TextFieldDX from "../../atoms/textField";
import { useState, useContext } from "react";
import Api from "../../../common/api";
import { email, password } from "../../../common/validate";
import AppContext from "../../../common/context";
import { LOGIN } from "../../../common/action";
import { FacebookLoginButton, GoogleLoginButton } from "../../atoms/button";

export default function SignUp(props) {
  const { state, dispatch } = useContext(AppContext);
  const [signUpData, setSignUpData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    error: false,
    email: null,
    password: null,
    confirmPassword: null,
    default: null,
    checkBox: false,
  });
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (prop) => (event) => {
    setSignUpData({ ...signUpData, [prop]: event.target.value });
  };

  const validate = async () => {
    var emailError = null,
      confirmPasswordError = null,
      passwordError = null,
      checkBoxError = false;
    var overAllError = false;
    if (email(signUpData.email)) {
      emailError = null;
      overAllError = false;
      if (password(signUpData.password)) {
        passwordError = null;
        overAllError = false;
        if (signUpData.confirmPassword === signUpData.password) {
          confirmPasswordError = null;
          overAllError = false;
          if (checked) {
            checkBoxError = false;
            overAllError = false;
          } else {
            checkBoxError = true;
            overAllError = true;
          }
        } else {
          confirmPasswordError = "Confirm Password doesn't Match";
          overAllError = true;
        }
      } else {
        passwordError = "Password must be Atleast 8 character long";
        overAllError = true;
      }
    } else {
      emailError = "Invalid Email";
      overAllError = true;
    }
    setErrorMessage({
      email: emailError,
      confirmPassword: confirmPasswordError,
      password: passwordError,
      checkBox: checkBoxError,
    });
    return overAllError;
  };

  const SignUpCall = async () => {
    setLoading(true);
    var overAllError = await validate();
    if (!overAllError) {
      var body = {
        email: signUpData.email,
        password: signUpData.password,
        confirmPassword: signUpData.confirmPassword,
      };
      try {
        var resp = await Api({
          route: "register",
          body,
          method: "POST",
          accessToken: state.Auth.access_token,
        });

        dispatch(LOGIN(resp));

        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "SetUpFireBase", data: "" })
        );
      } catch (error) {
        console.log(error);
        setErrorMessage({
          ...errorMessage,
          default: error.message
            ? error.message
            : "Unable to Register, Please try later.",
        });
      }
    }
    setLoading(false);
  };

  const onSocialError = (error) => {
    setErrorMessage({
      ...errorMessage,
      default: error,
    });
  };

  return (
    <div className="flexAuth" style={{ justifyContent: "flex-start" }}>
      <div
        style={{
          width: "80%",
        }}
      >
        <TypographyDX
          align="center"
          style={{
            fontSize: 20,
            fontWeight: "Bold",
            marginBottom: 10,
            textAlign: "left",
          }}
        >
          Sign Up
        </TypographyDX>

        <TextFieldDX
          error={errorMessage.email ? true : false}
          errorMessage={errorMessage.email}
          placeholder="Email"
          type={"text"}
          onChange={handleChange("email")}
          value={signUpData.email}
        />
        <TextFieldDX
          error={errorMessage.password ? true : false}
          errorMessage={errorMessage.password}
          placeholder="Password"
          type={"text"}
          onChange={handleChange("password")}
          value={signUpData.password}
          password={"true"}
        />
        <TextFieldDX
          error={errorMessage.confirmPassword ? true : false}
          errorMessage={errorMessage.confirmPassword}
          placeholder="Password"
          type={"text"}
          onChange={handleChange("confirmPassword")}
          value={signUpData.confirmPassword}
          password={"true"}
        />
        <TypographyDX
          align="left"
          style={{
            fontSize: 12,
            margin: "auto",
          }}
          onClick={() => console.log("open T&C")}
        >
          <CheckboxDX
            error={errorMessage.checkBox}
            checked={checked}
            setChecked={() => {
              setChecked(!checked);
            }}
          />
          I agree with the{" "}
          <span style={{ textDecoration: "underline" }}>
            {"Terms & Conditions"}
          </span>
        </TypographyDX>
        <ErrorMessage message={errorMessage.default} />
      </div>
      <ButtonDX
        style={{ width: "80%" }}
        type={"primary"}
        onClick={SignUpCall}
        loading={loading}
        disabled={loading ? true : false}
        text={"SignUp"}
      />
      <div style={{ width: "80%", paddingTop: 10, paddingBottom: 10 }}>
        <TypographyDX
          align="right"
          className={"fontStyle"}
          style={{
            fontSize: 15,
            textAlign: "center",
          }}
        >
          <span style={{ fontFamily: "Arial", letterSpacing: "-2px" }}>
            &#x2015;&#x2015;&#x2015;
          </span>
          <span>&nbsp;&nbsp;Or Sign Up with&nbsp;&nbsp;</span>
          <span style={{ fontFamily: "Arial", letterSpacing: "-2px" }}>
            &#x2015;&#x2015;&#x2015;
          </span>
        </TypographyDX>
      </div>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <GoogleLoginButton onSocialError={onSocialError} />
        <FacebookLoginButton onSocialError={onSocialError} />
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "auto",
          paddingBottom: 15,
          flexDirection: "column-reverse",
          widht: "100%",
        }}
      >
        <TypographyDX
          align="right"
          className={"fontStyle"}
          style={{
            fontSize: 15,
            textAlign: "center",
          }}
          onClick={() => props.onClick("login")}
        >
          Already have an account?{" "}
          <span style={{ color: "#009afd", fontWeight: "bold" }}>Sign In</span>
        </TypographyDX>
      </div>
    </div>
  );
}
