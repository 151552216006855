import React from "react";
import { OptionsList } from "../../";
import { Avatar } from "@mui/material";
import { InningsCard } from "../../matchInningsDetails/circket";

export default function ScoreBoard(props) {
  const menuItems1 = [
    {
      icon: (
        <div style={{ justifyContent: "center" }}>
          <Avatar
            style={{ height: 30, width: 30 }}
            src={`https://www.cricbuzz.com/a/img/v1/24x18/i1/c${props.match.team1.logo}/image.jpg`}
            variant="circular"
          />
        </div>
      ),
      name: (
        <div style={{ justifyContent: "center", marginLeft: "5px" }}>
          {props.match.team1.name}
        </div>
      ),
      action: "expand",
      showData: () => (
        <InningsCard
          match={props.match}
          players1={props.players1}
          players2={props.players2}
          teamId={props.match.team1Id}
        />
      ),
    },
  ];
  const menuItems2 = [
    {
      icon: (
        <Avatar
          style={{ height: 30, width: 30 }}
          src={`https://www.cricbuzz.com/a/img/v1/24x18/i1/c${props.match.team2.logo}/image.jpg`}
          variant="circular"
        />
      ),
      name: (
        <div style={{ justifyContent: "center", marginLeft: "5px" }}>
          {props.match.team2.name}
        </div>
      ),
      action: "expand",
      showData: () => (
        <InningsCard
          match={props.match}
          players1={props.players2}
          players2={props.players1}
          teamId={props.match.team2Id}
        />
      ),
    },
  ];

  return (
    <div
      style={{
        margin: "20px",
        overflowY: "scroll",
        color: "white",
      }}
    >
      <OptionsList OptionListItem={menuItems1} />
      <OptionsList OptionListItem={menuItems2} />
    </div>
  );
}
