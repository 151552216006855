import { CloseOutlined, QuestionMarkOutlined } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { ButtonDX } from "../../atoms";
import Gradient from "rgt";
import "./index.scss";
import { CONFIG } from "../../../common/constants";

export const AddFriendResp = (props) => {
  if (!props.resp.error) {
    var user = props.resp.user2;
    return (
      <div className="modal-div">
        <CloseOutlined
          style={{ alignSelf: "end" }}
          onClick={() => props.setAddFriend(false)}
        />
        <Avatar
          id={"Contained"}
          style={{
            height: "100px",
            width: "100px",
            backgroundColor: "#46464c",
          }}
          src={CONFIG.API_URL + user.profileImage}
          variant="circular"
        />
        <span
          style={{
            fontSize: "25px",
            padding: "10px",
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          <Gradient dir="left-to-right" from="#E4035D" to="#38ADFD">
            {user.firstName + " "}
            {user.lastName !== null ? user.lastName[0].toUpperCase() + "." : ""}
          </Gradient>
        </span>
        <span
          style={{ fontSize: "20px", padding: "10px", textAlign: "center" }}
        >
          {
            "Your friend is now notified & will show in your leaderboard once they approve your friend request"
          }
        </span>

        <ButtonDX
          style={{
            width: "80%",
            marginTop: "0",
          }}
          onClick={props.setAddFriend}
          type={"gradient"}
          text={"OK"}
        />
      </div>
    );
  } else {
    return (
      <div className="modal-div">
        <CloseOutlined
          style={{ alignSelf: "end" }}
          onClick={() => props.setAddFriend()}
        />

        <QuestionMarkOutlined
          style={{
            height: "100px",
            width: "100px",
          }}
        />
        <span
          style={{
            fontSize: "25px",
            padding: "10px",
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          <Gradient dir="left-to-right" from="#E4035D" to="#38ADFD">
            {"Cannot Send Friend Request"}
          </Gradient>
        </span>
        <span
          style={{ fontSize: "20px", padding: "10px", textAlign: "center" }}
        >
          {props.resp.error == "REQUEST_ALREADY_EXIST"
            ? "Friend Request is pending."
            : props.resp.error == "CANNOT_ADD_SELF_AS_FRIEND"
            ? "You added your own Code."
            : props.resp.error == "ALREADY_FRIEND"
            ? "You are already Friend"
            : "We are unable to find your friend. Please recheck Code."}
        </span>

        <ButtonDX
          style={{
            width: "80%",
            marginTop: "0",
          }}
          onClick={props.setAddFriend}
          type={"gradient"}
          text={"OK"}
        />
      </div>
    );
  }
};
