import {
  AddCircleOutlined,
  ArrowBackIosOutlined,
  ConnectingAirportsOutlined,
} from "@mui/icons-material";
import Gradient from "rgt";
import { Avatar, Badge, Typography } from "@mui/material";
import { Header } from "../compound";
import { CONFIG } from "../../common/constants";
import { useContext, useEffect, useState } from "react";
import Loader from "react-js-loader";
import Api from "../../common/api";
import AppContext from "../../common/context";

import "./index.scss";
import { useNavigate } from "react-router-dom";

export default function Leaderboard(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [leaderboard, setLeaderboard] = useState(null);
  const [first, setFirst] = useState(null);
  const [second, setSecond] = useState(null);
  const [third, setThird] = useState(null);
  const [firstP, setFirstP] = useState(null);
  const [secondP, setSecondP] = useState(null);
  const [thirdP, setThirdP] = useState(null);
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  var questionMark =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQyBfgIJrGciqhdV5BgGs-ocISXWetT1301_YXdJai_kzPKDf9eKGXrUOTsl1qL2Xu8Vg&usqp=CAU";
  const auth = state.Auth;

  useEffect(() => {
    if (auth.userId === 0 || !auth.emailConfirmed) {
      navigate("/", { replace: true });
    }
    getLeaderboard();
  }, []);

  const getLeaderboard = async () => {
    setLoading(true);
    try {
      var resp = await Api({
        route: "fans/leaderboard",
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setLeaderboard(resp);
      try {
        setFirst(resp[0]);
        setFirstP(CONFIG.API_URL + resp[0].profileImage);
        setSecond(resp[1]);
        setSecondP(CONFIG.API_URL + resp[1].profileImage);
        setThird(resp[2]);
        setThirdP(CONFIG.API_URL + resp[2].profileImage);
      } catch (ex) {
        console.log(ex);
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const getList = () => {
    var array = [];
    var color = ["leaderboard_rank1", "leaderboard_rank2", "leaderboard_rank3"];
    for (var i = 0; i < leaderboard.length; i++) {
      array.push(
        <div className={"leaderboard-row " + (i < 3 ? color[i] : "")} key={i}>
          <div className="leaderboard-col-1" style={{ paddingLeft: "5px" }}>
            {i + 1}
            {i == 0 ? (
              <img
                style={{
                  display: "flex",
                  height: 12,
                  width: 17,
                  paddingRight: "10px",
                  alignSelf: "center",
                }}
                src={require("../../asset/crown.png")}
              />
            ) : (
              ""
            )}
          </div>
          <div className="leaderboard-col-2">
            <Avatar
              id="Contained"
              style={{
                height: "65px",
                width: "65px",
                display: "flex",
                marginRight: "10px",
              }}
              src={
                leaderboard[i].profileImage
                  ? CONFIG.API_URL + leaderboard[i].profileImage
                  : "https://www.maxpixel.net/static/photo/640/The-Facebook-Placeholder-Shape-Blue-Avatar-Icon-1577909.png"
              }
            />
            <div
              style={{
                fontSize: "15px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                wordWrap: "anywhere",
              }}
            >
              {getName(leaderboard[i].firstName, leaderboard[i].lastName)}
            </div>
          </div>
          <div className="leaderboard-col-3">
            <div
              style={{
                fontSize: "15px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                wordWrap: "anywhere",
              }}
            >
              {leaderboard[i].wins + " wins"}
            </div>
            <div
              style={{
                fontSize: "15px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                wordWrap: "anywhere",
              }}
            >
              {leaderboard[i].totalPointsWon + " Points"}
            </div>
          </div>
        </div>
      );
    }
    return array;
  };

  const getName = (firstName, lastName) => {
    return (
      firstName +
      (lastName !== null ? " " + lastName[0].toUpperCase() + "." : "")
    );
  };

  const RankedPlayer = (props) => {
    var player = props.player;
    var rank = props.rank;
    var profile = rank == "1st" ? firstP : rank == "2nd" ? secondP : thirdP;
    var i = rank == "1st" ? 0 : rank == "2nd" ? 1 : 2;
    var color = ["009afd", "6f00fd", "e1811f"];
    return (
      <div
        style={{
          paddingTop: 82,
          zIndex: 2,
          flex: rank == "1st" ? 7 : 3,
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: rank == "1st" ? "flex-start" : "center",
            height: 210,
          }}
        >
          <Badge
            id={rank == "1st" ? "leaderboard-badge-first" : "leaderboard-badge"}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            badgeContent={
              <div
                style={{
                  backgroundColor: "#" + color[i],
                  color: "#ffff",
                  padding: 4,
                  height: 10,
                  width: 10,
                  borderRadius: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                {i + 1}
              </div>
            }
            overlap="circular"
          >
            <Avatar
              id={"Contained"}
              style={{
                height: rank == "1st" ? 80 : 50,
                width: rank == "1st" ? 80 : 50,
                display: "flex",
                margin: "5px 0px",
                border: "3px solid #" + color[i],
              }}
              imgProps={{
                onError: () => {
                  rank == "1st"
                    ? setFirstP(questionMark)
                    : rank == "2nd"
                    ? setSecondP(questionMark)
                    : setThirdP(questionMark);
                },
              }}
              src={profile ? profile : questionMark}
            />
          </Badge>
          <Typography
            style={{
              color: "white",
              padding: "0",
              fontSize: 12,
              fontWeight: "bolder",
              wordWrap: "anywhere",
            }}
          >
            {player ? getName(player.firstName, player.lastName) : "-"}
          </Typography>
          <Typography style={{ color: "#808080", padding: "0", fontSize: 11 }}>
            {player ? player.wins + " Wins" : "-"}
          </Typography>
          <div
            style={{
              fontSize: 10,
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              wordWrap: "anywhere",
              color: "#ffffff",
            }}
          >
            {player ? player.totalPointsWon + " Points" : "-"}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      id="AccountMenu"
      style={{
        width: "100%",
        maxWidth: "800px",
        height: "100vh",
        backgroundColor: "#02030a",
        alignSelf: "center",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            className={"action_logo"}
            onClick={() => {
              navigate(-1);
            }}
          />
        }
        center={<div className="header_title">Leaderboard</div>}
      />
      {loading || error || leaderboard == null ? (
        <div style={{ flex: 1, justifyContent: "center" }}>
          <Loader
            type="spinner-circle"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={150}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              margin: "20px",
              alignItems: "center",
              justifyContent: "space-around",
              flexDirection: "row",
              alignSelf: "center",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "250px",
                paddingRight: "10px",
                alignSelf: "center",
                position: "absolute",
                maxWidth: "375px",
                zIndex: 1,
              }}
              src={require("../../asset/Leaderboard_bg.png")}
            />
            <RankedPlayer player={second} rank={"2nd"} />
            <RankedPlayer player={first} rank={"1st"} />
            <RankedPlayer player={third} rank={"3rd"} />
          </div>
          <div
            style={{
              width: "95%",
              alignSelf: "center",
            }}
          >
            <div className="leaderboard-title">
              <div className="leaderboard-col-1">Rank</div>
              <div className="leaderboard-col-2">
                <div style={{ margin: "auto" }}>Player</div>
              </div>
              <div className="leaderboard-col-3">Score</div>
            </div>
          </div>
          <div
            style={{
              width: "95%",
              alignSelf: "center",
              overflowY: "scroll",
            }}
          >
            {getList()}
          </div>
        </>
      )}
    </div>
  );
}
