import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import {
  FavoriteBorderOutlined,
  ArrowForwardIosOutlined,
  Favorite,
  Circle,
} from "@mui/icons-material";
import moment from "moment";
import "./index.scss";
import { Badge, ButtonDX, TeamAvatar, Alert, BasicModal } from "../../atoms";
import AppContext from "../../../common/context";
import Api from "../../../common/api";
import { ProcessFootballMatch } from "../../../common/postProcessApi";

export default function Football(props) {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  var match = ProcessFootballMatch(props.match);

  const [showAlert, setShowAlert] = useState(false);
  const hideAlert = () => {
    setShowAlert(false);
  };

  const [fav, setFav] = useState(match.matchBookmarks.length);

  const bookmark = async (id) => {
    try {
      var body = {
        matchId: match.matchId,
        userId: state.Auth.userId,
      };
      var resp = await Api({
        route: "matchbookmarks/toggle/" + id,
        method: "POST",
        body,
        accessToken: state.Auth.access_token,
      });
      setFav(!fav);
    } catch (error) {}
  };

  return (
    <div
      className="match__container"
      onClick={() =>
        navigate("/matchDetails/" + match.matchId, {
          replace: false,
          state: match,
        })
      }
    >
      <div style={{ borderBottom: "1px solid #212a42" }}>
        <div
          style={{
            flexDirection: "row",
            width: "85%",
            margin: "auto",
          }}
        >
          <div style={{ justifyContent: "center" }}>
            <Avatar
              style={{ height: 30, width: 30 }}
              src={match.league.logo}
              variant="circular"
            />
          </div>
          <div
            style={{
              justifyContent: "center",
              fontSize: "14px",
              fontWeight: "bolder",
              marginLeft: 10,
            }}
          >
            {match.league.name}
            <br />
            {/* <span
            style={{
              fontSize: "10px",
              fontWeight: "lighter",
              color: "#807f8a",
            }}
          >
            {match.date} | {match.time}
          </span> */}
          </div>
          {/* <div className="match__action">
          <div style={{ justifyContent: "center", padding: "10px" }}>
            {fav ? (
              <Favorite
                style={{
                  fontSize: "16px",
                  color: "red",
                }}
                onClick={() => bookmark(match.matchId)}
              />
            ) : (
              <FavoriteBorderOutlined
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
                onClick={() => bookmark(match.matchId)}
              />
            )}
          </div>
          <div style={{ justifyContent: "center" }}>
            <ArrowForwardIosOutlined
              style={{ fontSize: "16px" }}
              onClick={() =>
                navigate("/matchDetails/" + match.matchId, {
                  replace: false,
                  state: match,
                })
              }
            />
          </div>
        </div> */}
        </div>
      </div>
      <ScoreCard match={match} />
      <Alert
        title="Document Size"
        open={showAlert}
        closeLabel="Retry"
        handleClose={() => hideAlert()}
        handlePopupClose={() => setShowAlert(false)}
      />
    </div>
  );
}

export const ScoreCard = (props) => {
  var match = ProcessFootballMatch(props.match ? props.match : props);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [selectedTeam1, setselectedTeam1] = useState(false);
  const [selectedTeam2, setselectedTeam2] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      var now = moment().utc();
      var duration = moment.duration(moment(match.startDate).local().diff(now));
      var hh = parseInt(duration.asHours());
      var mm = parseInt(duration.asMinutes()) % 60;
      var ss = parseInt(duration.asSeconds()) % 60;
      setTimeRemaining(hh + ":" + mm + ":" + ss);
      if (hh < 0) {
        match.finished = true;
        match.futureMatch = false;
      }
    }, 1000);
    if (match.questions[0]?.fanResponses[0]) {
      if (match.questions[0]?.fanResponses[0].answer == match.team1Id) {
        setselectedTeam1(true);
        setselectedTeam2(false);
      } else {
        setselectedTeam2(true);
        setselectedTeam1(false);
      }
    } else {
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [timeRemaining]);

  return (
    <div className={"match_score" + (props.nobg ? " nobg" : "")}>
      <div className="team__avatar__container">
        <TeamAvatar
          selectedTeam={selectedTeam1}
          teamLogo={match.team1.logo}
          team={match.team1.name}
        />
      </div>

      {match.live || match.finished || !match.futureMatch ? (
        <Score match={match} matchFinished={match.finished} />
      ) : (
        <Remaining match={match} timeRemaining={timeRemaining} />
      )}
      <div className="team__avatar__container">
        <TeamAvatar
          selectedTeam={selectedTeam2}
          teamLogo={match.team2.logo}
          team={match.team2.name}
        />
      </div>
    </div>
  );
};

const Score = (props) => {
  var match = props.match;
  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {match && match.team1Score ? (
        <div className="team__score">
          <div>{match.team1Score}</div>
          <div>:</div>
          <div>{match.team2Score}</div>
        </div>
      ) : (
        <div
          style={{
            flex: 3,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          To Be Updated
        </div>
      )}

      {props.matchFinished ? (
        <div style={{ flex: 3, textAlign: "center", justifyContent: "end" }}>
          {matchStatus[match.statusCode]}
        </div>
      ) : (
        ""
      )}
      {match.live ? (
        <div className="live">
          <Badge
            className={"live"}
            text={
              <>
                Live <Circle style={{ fontSize: 8 }} />
              </>
            }
            width={"50%"}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const Remaining = (props) => {
  var match = props.match;
  const { state } = useContext(AppContext);
  const [question, setQuestion] = useState();
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    getquestion(match.matchId);
  }, [match]);
  const getquestion = async (matchid) => {
    try {
      var resp = await Api({
        route: "matches/" + matchid,
        method: "GET",
        accessToken: state.Auth.access_token,
      });

      setQuestion(resp.questions);
    } catch (error) {}
  };
  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {open && question ? (
        <BasicModal
          open={open}
          setOpen={setOpen}
          match={match}
          question={question}
          setHide={setHide}
        />
      ) : (
        ""
      )}
      {question &&
      (match?.questions?.length == 0 ||
        match?.questions[0]?.fanResponses?.length == 0 ||
        match?.questions[0]?.fanResponses == undefined) &&
      !hide ? (
        <>
          <div className="time_remaining-text">Time Remaining</div>
          <div className="time_remaining">{props.timeRemaining}</div>
          <ButtonDX
            className={"play_win_btn"}
            type={"gradient"}
            onClick={handleOpen}
            loading={match.loading}
            loadersize={15}
            loaderheight={15}
            disabled={match.loading ? true : false}
            text={"PLAY TO WIN"}
          ></ButtonDX>
        </>
      ) : (
        <div style={{ justifyContent: "center", height: "90%" }}>
          <div className="time_remaining-text">Time Remaining</div>
          <div className="time_remaining">{props.timeRemaining}</div>
        </div>
      )}
    </div>
  );
};

const matchStatus = {
  1: "Time To Be Defined",
  2: "Not Started",
  3: "First Half, Kick Off",
  4: "Halftime",
  5: "Second Half, 2nd Half Started",
  6: "Extra Time",
  7: "Penalty In Progress",
  8: "Match Finished",
  9: "Match Finished After Extra Time",
  10: "Match Finished After Penalty",
  11: "Break Time (in Extra Time)",
  12: "Match Suspended",
  13: "Match Interrupted",
  14: "Match Postponed",
  15: "Match Cancelled",
  16: "Match Abandoned",
  17: "Technical Loss",
  18: "WalkOver",
};
