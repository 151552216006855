import { ArrowBackIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { ButtonDX, TypographyDX } from "../atoms";
import { ForgetPassword, Login, OTP, SignUp } from "../auth";
import ResetPassword from "../auth/resetPassword";
import AppContext from "../../common/context";
import "./home.scss";
import { useNavigate } from "react-router-dom";

const Main = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        width: "100%",
        flex: 1,
      }}
    >
      <div
        style={{
          width: "80%",
          marginBottom: 23.15,
        }}
      >
        <ButtonDX
          type={"primary"}
          onClick={() => props.onClick("login")}
          text={"Login"}
        />
        <ButtonDX
          type={"dark"}
          onClick={() => props.onClick("signUp")}
          text={"Sign Up"}
        />
      </div>
    </div>
  );
};

export default function Home() {
  const navigate = useNavigate();
  const [screen, setScreen] = useState("home");
  const [component, setComponent] = useState(<Main onClick={updateScreen} />);
  const [screens, setScreens] = useState([]);

  const { state } = useContext(AppContext);
  const auth = state.Auth;

  var logoSetting = {
    home: {
      icon: "logo_light.png",
      height: 65,
      paddingTop: "40vh",
      marginLeft: "auto",
      marginRight: "auto",
    },
  };

  const popScreen = () => {
    var list = [...screens];
    if (list.length > 0) {
      var newScreen = list.pop();
      setScreens(list);
      setScreen(newScreen);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", popScreen);
    document.addEventListener("GoBack", popScreen, false);

    return () => {
      window.removeEventListener("popstate", popScreen);
      document.removeEventListener("GoBack", popScreen, false);
    };
  });

  useEffect(() => {
    switch (screen) {
      case "home":
        setComponent(<Main onClick={updateScreen} />);
        break;
      case "login":
        setComponent(<Login onClick={updateScreen} />);
        break;
      case "signUp":
        setComponent(<SignUp onClick={updateScreen} />);
        break;
      case "forgetPassword":
        setComponent(<ForgetPassword onClick={updateScreen} />);
        break;
      case "otp":
        setComponent(<OTP onClick={updateScreen} />);
        break;
      case "resetPassword":
        setComponent(<ResetPassword onClick={updateScreen} />);
        break;
      default:
        setComponent(<Main onClick={updateScreen} />);
        break;
    }
  }, [screen]);

  useEffect(() => {
    if (auth.userId !== 0 && !auth.emailConfirmed) updateScreen("otp");
    if (!(auth.userId === 0 || !auth.emailConfirmed)) {
      navigate("/dashboard/feed/3", { replace: true });
    }
  }, [auth]);

  function updateScreen(newScreen) {
    var _screens = [...screens, screen];
    setScreens(_screens);
    setScreen(newScreen);
  }
  const bg = screen == "home" ? `url(/bg.png)` : "#00080e";

  if (auth.userId === 0 || !auth.emailConfirmed) {
    return (
      <div
        className="home-main"
        style={{
          background: bg,
        }}
      >
        <div style={{ width: "100%" }}>
          {screen === "home" || screen === null ? null : (
            <IconButton
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                width: 50,
                height: 50,
                color: "white",
                background: "#141927",
              }}
              color="primary"
              component="span"
              onClick={popScreen}
            >
              <ArrowBackIos
                color="white"
                style={{
                  marginLeft: 5,
                  fontSize: 20,
                }}
              />
            </IconButton>
          )}
          {logoSetting[screen] ? (
            <img
              style={{
                display: "block",
                margin: "auto",
                height: logoSetting[screen].height,
                aspectRatio: logoSetting[screen].ratio,
                paddingTop: logoSetting[screen].paddingTop,
                marginLeft: logoSetting[screen].marginLeft,
                marginRight: logoSetting[screen].marginRight,
              }}
              src={require("../../asset/" + logoSetting[screen].icon)}
              alt={"Welcome"}
            />
          ) : null}
        </div>
        <div style={{ width: "100%", flex: 1, marginTop: 80 }}>{component}</div>
      </div>
    );
  }
}
