import { Typography } from "@mui/material";

export default function TypographyDX(props) {
  const { style, ...allprops } = props;
  return (
    <Typography
      className={"fontStyle"}
      style={{
        ...style,
        color: props.color ? props.color : "white",
      }}
      {...allprops}
    />
  );
}
