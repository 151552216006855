export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getGreetingMessage() {
  var myDate = new Date();
  var currentHour = myDate.getHours();
  var msg;
  if (currentHour < 12) msg = "Good Morning";
  else if (currentHour >= 12 && currentHour <= 17) msg = "Good Afternoon";
  else if (currentHour >= 17 && currentHour <= 24) msg = "Good Evening";
  return msg;
}
