import { Sports } from "../../../common/enums";
import Circket from "./circket";
import Football from "./football";
import Tennis from "./tennis";
import "./index.scss";

export default function MatchUpdate(props) {
  var match = props.match;
  const object = {};
  switch (match.league.sport.sportId) {
    case Sports.Football:
      return Football(props);
      break;
    case Sports.Circket:
      return Circket(props);
      break;
    case Sports.Tennis:
      return Tennis(props);
      break;
    default:
      return Football(props);
  }
}
