import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OptionsList } from "../../";
import "./index.scss";

export default function Info(props) {
  const navigate = useNavigate();
  var match = props.match;
  console.log(match);

  const menuItems1 = [
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>Match</span>
          <span>{match.league.name}</span>
        </div>
      ),
    },
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>{"Date & Time"}</span>
          <span>
            {match.date}, {match.time}
          </span>
        </div>
      ),
    },
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>Venue</span>
          <span>{match.venue.name}</span>
        </div>
      ),
    },
    ,
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>Umpires</span>
          <span>
            {match.matchOfficials[0]
              ? match.matchOfficials[0].official.name
              : "-"}
          </span>
        </div>
      ),
    },
    ,
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>Reviews</span>
          <span>Pak 2, Eng 0</span>
        </div>
      ),
    },
  ];
  const menuItems2 = [
    {
      icon: (
        <div style={{ justifyContent: "center" }}>
          <Avatar
            style={{ height: 30, width: 30 }}
            src={`https://www.cricbuzz.com/a/img/v1/24x18/i1/c${match.team1.logo}/image.jpg`}
            variant="circular"
          />
        </div>
      ),
      name: (
        <div style={{ flexDirection: "row", width: "85%", margin: "auto" }}>
          <div style={{ justifyContent: "center", marginLeft: "5px" }}>
            {match.team1.name}
          </div>
        </div>
      ),
      rightIcon: (
        <div style={{ justifyContent: "end", flexDirection: "row", flex: "1" }}>
          <div style={{ justifyContent: "center" }}>
            {(match.live || match.finished) && !match.futureMatch
              ? "Innings"
              : "Squad"}
          </div>
          <div style={{ justifyContent: "center" }}>
            <ArrowForwardIosOutlined
              style={{ fontSize: "16px" }}
              onClick={() =>
                navigate(
                  "/matchDetails/" + match.matchId + "/" + match.team1Id,
                  {
                    replace: false,
                    state: match,
                  }
                )
              }
            />
          </div>
        </div>
      ),
    },
    {
      icon: (
        <div style={{ justifyContent: "center" }}>
          <Avatar
            style={{ height: 30, width: 30 }}
            src={`https://www.cricbuzz.com/a/img/v1/24x18/i1/c${match.team2.logo}/image.jpg`}
            variant="circular"
          />
        </div>
      ),
      name: (
        <div style={{ flexDirection: "row", width: "85%", margin: "auto" }}>
          {/* leagues info and fav / detials Action */}

          <div style={{ justifyContent: "center", marginLeft: "5px" }}>
            {match.team2.name}
          </div>
        </div>
      ),
      rightIcon: (
        <div style={{ justifyContent: "end", flexDirection: "row", flex: "1" }}>
          <div style={{ justifyContent: "center" }}>
            {" "}
            {(match.live || match.finished) && !match.futureMatch
              ? "Innings"
              : "Squad"}
          </div>
          <div style={{ justifyContent: "center" }}>
            <ArrowForwardIosOutlined
              style={{ fontSize: "16px" }}
              onClick={() =>
                navigate(
                  "/matchDetails/" + match.matchId + "/" + match.team2Id,
                  {
                    replace: false,
                    state: match,
                  }
                )
              }
            />
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div
        style={{
          margin: "20px",
          overflowY: "scroll",
          color: "white",
        }}
      >
        <OptionsList OptionListItem={menuItems1} />
        {/* <OptionsList OptionListItem={menuItems2} /> */}
      </div>
    </>
  );
}
