import { ButtonDX, TypographyDX } from "../../atoms";
import TextFieldDX from "../../atoms/textField";
import { useState, useEffect, useContext } from "react";
import Api from "../../../common/api";
import { VERIFYEMAIL } from "../../../common/action";
import AppContext from "../../../common/context";

export default function OTP(props) {
  const { state, dispatch } = useContext(AppContext);
  const resetPassword = state.Auth.resetPassword ? true : false;
  const userId = resetPassword
    ? state.Auth.resetPassword.userId
    : state.Auth.userId;
  const email = resetPassword
    ? state.Auth.resetPassword.email
    : state.Auth.email;

  const [OTP, setOTP] = useState("");
  const [resendCounter, setResendCounter] = useState(60);
  const [errorMessage, setErrorMessage] = useState({
    default: null,
    otp: null,
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (prop) => (event) => {
    setOTP(event.target.value);
  };

  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (resendCounter > 0) setResendCounter(resendCounter - 1);
    }, 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [resendCounter]);

  const validate = async () => {
    if (OTP.length !== 4) {
      setErrorMessage({
        ...errorMessage,
        otp: "Invalid OTP entered",
      });
      return true;
    } else {
      return false;
    }
  };
  const verifyOtpCall = async () => {
    setLoading(true);
    var overAllError = await validate();
    if (!overAllError) {
      var params = {
        userId: userId,
        otp: OTP,
      };
      try {
        await Api({
          route: "verifyemail",
          params,
          accessToken: state.Auth.access_token,
        });
        dispatch(VERIFYEMAIL(true));
        if (!resetPassword) {
          props.onClick("home");
        } else {
          props.onClick("resetPassword");
        }
      } catch (error) {
        setErrorMessage({
          ...errorMessage,
          otp: error.message,
        });
      }
    }
    setLoading(false);
  };

  const SendOTP = async () => {
    try {
      var params = {
        email: email,
      };
      await Api({
        route: "resendemail",
        params,
        accessToken: state.Auth.access_token,
      });
      setResendCounter(60);
    } catch (error) {
      setErrorMessage({
        ...errorMessage,
        default: error.message ? error.message : "Unable to Verify OTP.",
      });
    }
  };

  return (
    <div className="flexAuth">
      <div
        style={{
          width: "80%",
        }}
      >
        <TypographyDX
          align="left"
          style={{
            width: "100%",
            fontWeight: "bold",
            fontSize: 27,
          }}
        >
          OTP Verification
        </TypographyDX>
        <TypographyDX
          align="left"
          style={{
            width: "100%",
            fontWeight: "normal",
            fontSize: 14,
            marginBottom: 20,
          }}
        >
          You will get an OTP via Email
        </TypographyDX>
        <TextFieldDX
          error={errorMessage.otp ? true : false}
          errorMessage={errorMessage.otp}
          placeholder="Enter OTP from Email"
          type={"number"}
          onChange={handleChange("OTP")}
          value={OTP}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "end",
            paddingBottom: 25,
          }}
        >
          <ButtonDX
            type={"dark"}
            onClick={SendOTP}
            style={{
              margin: 0,
              width: "auto",
            }}
            disabled={resendCounter > 0 ? true : false}
            text={
              <TypographyDX
                align="center"
                style={{
                  fontSize: 14,
                  width: "100%",
                  margin: "auto",
                }}
              >
                {resendCounter > 0 ? resendCounter + " " : ""} Resend OTP
              </TypographyDX>
            }
          />
        </div>
        <ButtonDX
          type={"primary"}
          onClick={() => {
            verifyOtpCall();
          }}
          loading={loading}
          disabled={loading ? true : false}
          text={"VERIFY"}
        ></ButtonDX>
      </div>
    </div>
  );
}
