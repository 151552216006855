import moment from "moment";
const ProcessFootballMatch = (match) => {
  var finished = ["8", "15", "14", "18", "10", " 9"];
  var live = ["7", "4", "6", "7", " 5", " 3", "11"];
  var dateTime = moment(match.startDate).utc();
  match.finished = finished.includes(match.statusCode);
  match.live = live.includes(match.statusCode);
  match.futureMatch = moment().utc() < dateTime;
  match.loading = false;
  match.date = dateTime.local().format("MMM DD YYYY");
  match.time = moment(match.startDate).local().format("hh:mm a");
  return match;
};

export default ProcessFootballMatch;
