export const LOGIN = (authData) => {
  return {
    type: "LOGIN",
    payload: authData,
  };
};
export const VERIFYEMAIL = (payload) => {
  return {
    type: "VERIFY_EMAIL",
    payload: payload,
  };
};
export const REGISTER = (authData) => {
  return {
    type: "REGISTER",
    payload: authData,
  };
};

export const LOGOUT = () => {
  return {
    type: "LOGOUT",
  };
};

export const SET_RESETPASSWORD = (id) => {
  return {
    type: "SET_RESETPASSWORD",
    payload: id,
  };
};

export const UPDATE_USER = (user) => {
  return {
    type: "UPDATE_USER",
    payload: user,
  };
};

export const UPDATE_PROFILE = (user) => {
  return {
    type: "UPDATE_PROFILE",
    payload: user,
  };
};

export const UPDATE_JOINING_CODE = (user) => {
  return {
    type: "UPDATE_JOINING_CODE",
    payload: user,
  };
};
