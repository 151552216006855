import Api from "../common/api";

const saveFireBaseToken = async (authToken, firebaseToken) => {
  var body = {
    deviceToken: firebaseToken,
  };
  try {
    var resp = await Api({
      route: "userdevicetoken",
      body,
      method: "POST",
      accessToken: authToken,
    });
  } catch (ex) {
    console.log(ex);
  }
};

export default {
  saveFireBaseToken,
};
