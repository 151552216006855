import moment from "moment";
const ProcessCircketMatch = (match) => {
  var live = [7];
  var finished = [9, 8];
  var dateTime = moment(match.startDate).utc();
  match.finished = finished.includes(match.statusCode);
  match.live = live.includes(match.statusCode);
  match.futureMatch = moment().utc() < dateTime;
  match.loading = false;
  match.date = dateTime.local().format("MMM DD YYYY");
  match.time = moment(match.startDate).local().format("hh:mm a");
  return match;
};
export default ProcessCircketMatch;
