const prod = {
  API_URL: "https://api.sportl.app/",
  FB_APP_ID: "386219573423507",
  GOOGLE_ID:
    "14650686350-neic1cejph5psi38udcds4ukpv74ibdv.apps.googleusercontent.com",
  DOC_SIZE: 1,
  IMAGE_SIZE: 5,
};

const dev = {
  API_URL: "https://sportlapi.compass-dx.com/", //"http://localhost:3001/", "https://sportlapi.compass-dx.com/"
  FB_APP_ID: "386219573423507",
  GOOGLE_ID:
    "14650686350-neic1cejph5psi38udcds4ukpv74ibdv.apps.googleusercontent.com",
  DOC_SIZE: 1,
  IMAGE_SIZE: 5,
  NOTIFICATION_CODE: {
    FRIEND_REQUEST: 1,
    FRIEND_REQUEST_ACCEPTED: 2,
    FRIEND_REQUEST_REJECTED: 3,
    WON_GAME: 4,
    LOST_GAME: 5,
  },
};
export const CONFIG = process.env.NODE_ENV === "development" ? dev : prod;
