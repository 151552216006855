import { ButtonDX, TypographyDX, ErrorMessage } from "../../atoms";
import TextFieldDX from "../../atoms/textField";
import { useState, useContext } from "react";
import Api from "../../../common/api";
import { password } from "../../../common/validate";
import AppContext from "../../../common/context";
import { LOGIN } from "../../../common/action";

export default function ResetPassword(props) {
  const { state, dispatch } = useContext(AppContext);
  const [resetData, setResetpData] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    password: null,
    confirmPassword: null,
    default: null,
  });
  const [loading, setLoading] = useState(false);
  const auth = state.Auth.resetPassword;

  const handleChange = (prop) => (event) => {
    setResetpData({ ...resetData, [prop]: event.target.value });
  };

  const validate = async () => {
    var confirmPasswordError = null,
      passwordError = null;

    var overAllError = false;
    if (password(resetData.password)) {
      passwordError = null;
      overAllError = false;
      if (resetData.confirmPassword === resetData.password) {
        confirmPasswordError = null;
        overAllError = false;
      } else {
        confirmPasswordError = "Confirm Password doesn't Match";
        overAllError = true;
      }
    } else {
      passwordError = "Password must be Atleast 8 character long";
      overAllError = true;
    }
    setErrorMessage({
      confirmPassword: confirmPasswordError,
      password: passwordError,
    });
    return overAllError;
  };
  const ResetPasswordCall = async () => {
    setLoading(true);
    var overAllError = await validate();
    if (!overAllError) {
      var body = {
        userId: auth.userId,
        password: resetData.password,
        confirmPassword: resetData.confirmPassword,
      };
      try {
        var resp = await Api({
          route: "resetpassword",
          body,
          method: "POST",
          accessToken: state.Auth.access_token,
        });
        dispatch(LOGIN(resp));

        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "SetUpFireBase", data: "" })
        );
        props.onClick("home");
      } catch (error) {
        setErrorMessage({
          ...errorMessage,
          default: error.message
            ? error.message
            : "Unable reset password. Please Try Again.",
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className="flexAuth">
      <div
        style={{
          width: "80%",
        }}
      >
        <TypographyDX
          align="left"
          style={{
            width: "100%",
            fontWeight: "Bold",
            fontSize: 27,
          }}
        >
          Secure your Account
        </TypographyDX>
        <TypographyDX
          align="left"
          style={{
            width: "100%",
            fontWeight: "normal",
            fontSize: 14,
            marginBottom: 15,
          }}
        >
          Secure your Account by updating your password.
        </TypographyDX>
        <TextFieldDX
          error={errorMessage.password ? true : false}
          errorMessage={errorMessage.password}
          placeholder="Password"
          type={"text"}
          onChange={handleChange("password")}
          value={resetData.password}
          password={"true"}
        />
        <TextFieldDX
          error={errorMessage.confirmPassword ? true : false}
          errorMessage={errorMessage.confirmPassword}
          placeholder="Password"
          type={"text"}
          onChange={handleChange("confirmPassword")}
          value={resetData.confirmPassword}
          password={"true"}
        />
        <ErrorMessage message={errorMessage.default} />
        <ButtonDX
          type={"primary"}
          onClick={ResetPasswordCall}
          loading={loading}
          disabled={loading ? true : false}
          text={"Update Password"}
        />
      </div>
    </div>
  );
}
