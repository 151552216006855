import React, { useState, useEffect, useContext } from "react";
import { Header, Promo } from "../compound";
import { ArrowBackIosOutlined, SearchOutlined } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Avatar, Divider, Typography } from "@mui/material";
import { ButtonDX } from "../atoms";
import Api from "../../common/api";
import AppContext from "../../common/context";
import Loader from "react-js-loader";
import { ReedemModal1 } from "../compound/modals/ReedemModal1";
import { ReedemModal } from "../compound/modals/ReedemModal";
import { OTPReedemModal } from "../compound/modals/OTPReedemModal";
import { CongReedemModal } from "../compound/modals/CongReedemModal";
import { CONFIG } from "../../common/constants";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const style = {
  position: "relative",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "#232148",
  boxShadow: 24,
  color: "white",
  // p: 4,
  padding: "15px",
  height: "400px",
};

export default function MyCoupons(props) {
  const { state } = useContext(AppContext);
  const [merchant, setMerchant] = useState();
  const [availablepoints, setAvailablepoints] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const navigate = useNavigate();
  const user = state.Auth;
  useEffect(() => {
    if (user.userId === 0 || !user.emailConfirmed) {
      navigate("/", { replace: true });
    }
    getMerchant();
    getPoints(state.Auth.userId);
  }, []);
  const getMerchant = async () => {
    try {
      var resp = await Api({
        route: "merchants",
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setMerchant(resp);
    } catch (error) {
      //alert
    }
  };
  const getPoints = async (userId) => {
    try {
      var resp = await Api({
        route: "fans/" + userId,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setAvailablepoints(resp.totalPointsRemaining);
      setTotalPoints(resp.totalPointsWon);
    } catch (error) {
      //alert
    }
  };
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "800px",
        height: "100vh",
        backgroundColor: "#02030a",
        color: "white",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            className="action_logo"
            onClick={() => navigate(-1)}
          />
        }
        center={<div className="header_title">Coupons</div>}
        right={
          <div
            style={{
              marginLeft: "auto",
              padding: "10px",
              flexDirection: "row",
            }}
          >
            <SearchOutlined className="action_logo" />
          </div>
        }
      />
      <div
        className="points__container"
        style={{ border: "1px solid #009afd", borderRadius: "15px" }}
      >
        <div className="points__div" style={{ border: "0px" }}>
          <div>
            Available{" "}
            <span style={{ fontSize: "12px", color: "#525167" }}>Points</span>
          </div>
          <div>
            <span className="points__count">
              <img
                style={{
                  display: "flex",
                  height: 25,
                }}
                src={require("../../asset/coin.png")}
              />
              {availablepoints ? availablepoints : 0}
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          flexDirection: "row",
          overflowX: "scroll",
          width: "90%",
          marginTop: 15,
          alignSelf: "center",
        }}
      >
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
        <Promo
          logo={"promoLogo.png"}
          name={"TEA TIME"}
          offer={"20% OFF"}
          subText={"Burgers, Sandwiches, Tea"}
        />
      </div>

      {merchant ? (
        merchant.map((m) => {
          return (
            <Merchant
              img={m.logo}
              name={m.name}
              text={m.website}
              sponsor={m.website}
              offer={m.merchantCoupons}
              availablepoints={availablepoints}
              code={m.code}
            />
          );
        })
      ) : (
        <Loader
          type="bubble-top"
          bgColor={"#FFFFFF"}
          color={"#FFFFFF"}
          size={35}
          style={{ marginTop: "15px" }}
        />
      )}
    </div>
  );
}

export const Merchant = (props) => {
  const [open, setOpen] = useState(false);
  const [iconChanged, setIconChanged] = useState(false);
  const [border, setBorder] = useState("");
  const openDiv = () => {
    setIconChanged(!iconChanged);
    setOpen(!open);
    setBorder(!open ? "merchant__select__border" : "");
  };

  return (
    <>
      {props.offer.length != 0 ? (
        <>
          <div className={"merchant__div " + border}>
            <div className="merchant__icon">
              <Avatar
                style={{ height: "100%", width: "100%" }}
                variant="square"
                src={
                  props.img == null
                    ? "https://www.maxpixel.net/static/photo/640/The-Facebook-Placeholder-Shape-Blue-Avatar-Icon-1577909.png"
                    : CONFIG.API_URL + props.img
                }
              />
            </div>
            <div style={{ width: "70%", paddingLeft: "3%" }}>
              <div
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                  overflow: "hidden",
                  wordWrap: "normal",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "18px",
                    width: "90%",
                  }}
                >
                  {props.name}
                </Typography>
                {!iconChanged ? (
                  <KeyboardArrowDownIcon
                    className={"coupon_action_logo"}
                    onClick={openDiv}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    className={"coupon_action_logo"}
                    onClick={openDiv}
                  />
                )}
              </div>

              <Divider
                style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}
              />
              <div
                style={{
                  marginTop: "2px",
                  marginBottom: "5px",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "11px",
                }}
              >
                {props.text}
              </div>
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "15px",
                  fontSize: "12px",
                  color: "#BFBFC8",
                  fontWeight: "300",
                  lineHeight: "11px",
                }}
              >
                {props.sponsor}
              </div>
            </div>
          </div>
          <Reedemption
            open={open}
            offer={props.offer}
            availablepoints={props.availablepoints}
            name={props.name}
            code={props.code}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export const Reedemption = (props) => {
  const [openModal, setOpenModal] = useState();
  const [description, setDescription] = useState();
  const [couponsRemaining, setcouponsRemaining] = useState();
  const [uniqueIdentifier, setuniqueIdentifier] = useState();
  const [requiredPoints, setRequiredPoints] = useState();

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const handleClose = () => {
    setOpenModal(!openModal);
  };
  const handleRedeem = (
    description,
    couponsRemaining,
    uniqueIdentifier,
    points
  ) => {
    handleClose();
    setDescription(description);
    setcouponsRemaining(couponsRemaining);
    setuniqueIdentifier(uniqueIdentifier);
    setRequiredPoints(points);
  };

  const [openOTP, setOpenOTP] = useState(false);
  const [OpenCong, setOpenCong] = useState(false);
  return (
    <>
      {props.availablepoints < requiredPoints ? (
        <ReedemModal
          open={openModal}
          handleClose={handleClose}
          points={props.availablepoints}
        />
      ) : (
        <ReedemModal1
          open={openModal}
          handleClose={handleClose}
          points={props.availablepoints}
          couponsRemaining={couponsRemaining}
          description={description}
          code={props.code}
          name={props.name}
          uniqueIdentifier={uniqueIdentifier}
          setOpenOTP={setOpenOTP}
        />
      )}
      <OTPReedemModal
        open={openOTP}
        setOpenOTP={setOpenOTP}
        setOpenCong={setOpenCong}
        uniqueIdentifier={uniqueIdentifier}
        code={props.code}
      />
      <CongReedemModal open={OpenCong} setOpenCong={setOpenCong} />
      {props.open &&
        props.offer.map((p, index) => {
          return (
            <>
              <div
                className={
                  "Reedemption__main " +
                  (index == props.offer.length - 1 ? "Reedemption__last" : "")
                }
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div style={{ width: "60%", justifyContent: "center" }}>
                  <span className="Reedemption__title">{p.benefitValue}</span>
                  <span className="Reedemption__text">{p.description}</span>
                </div>
                <div style={{ width: "40%" }}>
                  <span
                    className="Reedemption__text"
                    style={{ color: "#9190A4", textAlign: "center" }}
                  >
                    {"Valid Until"} <br />
                    {months[new Date(p.createdAt).getMonth()]}
                    {new Date(p.createdAt).getDate()},
                    {new Date(p.createdAt).getFullYear()}
                  </span>
                  <ButtonDX
                    style={{
                      height: "auto",
                      fontSize: "10px",
                      padding: "5px",
                      width: "95%",
                      borderRadius: "10px",
                    }}
                    type={"primary"}
                    onClick={() =>
                      handleRedeem(
                        p.description,
                        p.couponsRemaining,
                        p.coupons,
                        p.points
                      )
                    }
                    loadersize={15}
                    loaderheight={15}
                    text="Reedem"
                  />
                </div>
              </div>
              {index !== props.offer.length - 1 ? (
                <div
                  style={{
                    width: "85%",
                    padding: "5px",
                    height: "3px",
                    alignSelf: "center",
                    backgroundColor: "#141927",
                  }}
                ></div>
              ) : (
                ""
              )}
            </>
          );
        })}
    </>
  );
};
