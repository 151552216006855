import React, { useEffect, useContext, useState } from "react";
import Header from "../header";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIosOutlined, SearchOutlined } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Loader from "react-js-loader";
import { Avatar } from "@mui/material";

export default function Tennis(props) {
  const [points1, setPoints1] = useState();
  const [points2, setPoints2] = useState({});
  const match = props;

  const navigate = useNavigate();

  useEffect(() => {
    try {
      setPoints1(JSON.parse(match.matchDetails));
      setPoints2(JSON.parse(match.team2Score));
      console.log(props);
    } catch (e) {}
  }, [props]);
  return (
    <div
      style={{
        width: "100%",
        // maxWidth: '800px',
        height: "100vh",
        backgroundColor: "#02030a",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            style={{ fontSize: "30px", padding: "10px" }}
            onClick={() => navigate(-1)}
          />
        }
        center={
          <div style={{ fontSize: "20px" }}>
            {!match.futureMatch ? "Score Card" : "Squad Detail"}
          </div>
        }
        right={
          <div
            style={{ justifyContent: "end", flexDirection: "row", flex: "1" }}
          >
            <NotificationsIcon style={{ fontSize: "30px", padding: "10px" }} />
            <ShareIcon style={{ fontSize: "30px", padding: "10px" }} />
            <SearchOutlined style={{ fontSize: "30px", padding: "10px" }} />
          </div>
        }
      />

      {match ? (
        <ScoreBoard match={match} points1={points1} points2={points2} />
      ) : (
        <Loader
          type="bubble-top"
          bgColor={"#FFFFFF"}
          color={"#FFFFFF"}
          size={35}
        />
      )}
    </div>
  );
}

export const ScoreBoard = (props) => {
  const match = props.match;
  const points1 = props.points1;

  console.log("props", props);

  return (
    <>
      {points1 ? (
        <div
          style={{
            marginTop: "2%",
            background: "#090729",
            borderRadius: "24px",
            marginLeft: "7px",
            marginRight: "7px",
            overflow: "scroll",
            color: "white",
            padding: "6px",
          }}
        >
          <Player1Score points={points1} match={match} />

          {/* {points2 ? <Player2Score points={points2} match={match} /> : ''} */}
        </div>
      ) : (
        <div
          style={{
            background: "#090729",
            // borderRadius: '24px',
            // marginLeft: '7px',
            // marginRight: '7px',
            // overflow: 'scroll',
            color: "white",
            // padding: '6px',
            // justifyContent: 'center',
            // textAlign: 'center',

            margin: "auto",
          }}
        >
          <span>TO BE UPDATED</span>
        </div>
      )}
    </>
  );
};
export const Player1Score = (props) => {
  const match = props.match;
  const points = props.points;
  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          padding: "15px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          flex: "1 1 0",
          // borderBottom: '1px solid black',
          marginBottom: "5px",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={6} style={{ alignItems: "center" }}>
            {match.league.name}
          </Grid>
          <Grid
            item
            xs={3}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Avatar
              style={{ height: 70, width: 70 }}
              src={
                match.player1Id
                  ? match.player1.logo
                  : match.team1Id
                  ? match.team1.logo
                  : null
              }
              variant="circular"
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Avatar
              style={{ height: 70, width: 70 }}
              src={
                match.player2Id
                  ? match.player2.logo
                  : match.team2Id
                  ? match.team2.logo
                  : null
              }
              // src={'https://images.news18.com/ibnlive/uploads/2022/06/roger-federer-1.jpg'}
              variant="circular"
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          padding: "15px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          flex: "1 1 0",
          // borderBottom: '1px solid black',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            style={{
              alignItems: "center",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Match Statistics
          </Grid>
          <Grid
            item
            xs={3}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {match.player1Id
              ? match.player1.name
              : match.team1.name
              ? match.team1.name
              : "Roger"}
          </Grid>
          <Grid
            item
            xs={3}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {match.player2Id
              ? match.player2.name
              : match.team2.name
              ? match.team2.name
              : "Roger"}
          </Grid>
        </Grid>
      </Box>
      {points &&
        points
          .filter(
            (sc) =>
              sc.period == "all" &&
              (sc.name === "games_won" ||
                sc.name === "first_serve" ||
                sc.name === "second_serve" ||
                sc.name === "max_points_in_a_row" ||
                sc.name === "total_points_won" ||
                sc.name === "max_games_in_a_row") &&
              sc.away !== "0"
          )
          .map((scr) => {
            return (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  padding: "15px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "white",
                  flex: "1 1 0",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    {scr.name.toUpperCase().replaceAll("_", " ")}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {scr.home}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {scr.away}
                  </Grid>
                </Grid>
              </Box>
            );
          })}

      <Box
        sx={{ flexGrow: 1 }}
        style={{
          padding: "15px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          flex: "1 1 0",
          // borderBottom: '1px solid black',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ justifyContent: "center", fontWeight: "700" }}
          >
            Match Winner
          </Grid>
          <Grid item xs={12}>
            {""}
          </Grid>
          <Grid item xs={12} style={{ justifyContent: "center" }}>
            <Avatar
              style={{ height: 80, width: 80 }}
              // src={match.player2Id ? match.player2.logo : match.team2Id ? match.team2.logo : null}
              src={
                "https://images.news18.com/ibnlive/uploads/2022/06/roger-federer-1.jpg"
              }
              variant="circular"
            />
          </Grid>
          <Grid item xs={12} style={{ justifyContent: "center" }}>
            {match.winner}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
