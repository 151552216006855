import React, { useContext, useEffect, useState } from "react";
import { Header } from "../compound";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "./index.scss";
import AppContext from "../../common/context";
import Api from "../../common/api";
import { useNavigate } from "react-router-dom";

var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function MyPoints(props) {
  const { state } = useContext(AppContext);
  const [pointsHistory, setPointsHistory] = useState();
  const [availablepoints, setAvailablepoints] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const navigate = useNavigate();

  const auth = state.Auth;

  useEffect(() => {
    if (auth.userId === 0 || !auth.emailConfirmed) {
      navigate("/", { replace: true });
    }
    getPointsHistory();
    getPoints(state.Auth.userId);
  }, []);
  const getPointsHistory = async () => {
    try {
      var resp = await Api({
        route: "pointsledger",
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setPointsHistory(resp);
    } catch (error) {}
  };
  const getPoints = async (userId) => {
    try {
      var resp = await Api({
        route: "fans/" + userId,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setAvailablepoints(resp.totalPointsRemaining);
      setTotalPoints(resp.totalPointsWon);
      console.log(resp);
    } catch (error) {}
  };
  return (
    // ******************main container******************
    <div
      style={{
        width: "100%",
        maxWidth: "800px",
        height: "100vh",
        backgroundColor: "#02030a",
        color: "white",
      }}
    >
      {/* ******************Header****************** */}
      <Header
        left={
          <ArrowBackIosOutlined
            className={"action_logo"}
            onClick={() => {
              navigate(-1);
            }}
          />
        }
        center={<div className="header_title">My Points</div>}
      />

      <div
        style={{
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "24px",
          letterSpacing: "0.15px",
          width: "80%",
          alignSelf: "center",
          marginTop: "10px",
        }}
      >
        Level:
        <span
          style={{
            color: "#009afd",
            fontSize: "20px",
            fontWeight: "bolder",
            display: "contents",
          }}
        >
          Rookie
        </span>
      </div>
      {/* ******************Avaible and Spent****************** */}

      <div className="points__container">
        <div className="points__div">
          <div>
            Available{" "}
            <span style={{ fontSize: "12px", color: "#525167" }}>Points</span>
          </div>
          <div>
            <span className="points__count">
              <img
                style={{
                  display: "flex",
                  height: 25,
                }}
                src={require("../../asset/coin.png")}
              />
              {availablepoints ? availablepoints : 0}
            </span>
          </div>
        </div>
        <div className="points__div" style={{ borderRadius: "20px" }}>
          <div>
            Spent
            <span style={{ fontSize: "12px", color: "#525167" }}>Points</span>
          </div>
          <div>
            <span className="points__count">
              <img
                style={{
                  display: "flex",
                  height: 25,
                }}
                src={require("../../asset/coin.png")}
              />
              {availablepoints && totalPoints
                ? totalPoints - availablepoints
                : 0}
            </span>
          </div>
        </div>
      </div>
      <div className="points__history__main__div">
        <div className="points__history__title">
          <span
            style={{
              justifyContent: "center",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Points History
          </span>
        </div>

        <div>
          <Box style={{ overflowY: "scroll" }} sx={{ flexGrow: 1 }}>
            {pointsHistory && pointsHistory.length != 0 ? (
              pointsHistory.map((p) => {
                return (
                  <Grid
                    container
                    spacing={0}
                    style={{
                      padding: "20px",
                      borderBottom: "1px solid #232148",
                      background: "#141927",
                    }}
                  >
                    <Grid
                      item
                      xs={8}
                      style={{
                        color: "#FFFFFF80",
                        fontSize: "20px",
                        textAlign: "center",
                        color: "white",
                        fontWeight: "500",
                      }}
                    >
                      {p.couponId != null || p.answerId == null
                        ? "Redeemed"
                        : "Earned"}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        color: "#FFFFFF80",
                        fontSize: "13px",
                        fontWeight: "300",
                      }}
                      justifyContent={{ xs: "end" }}
                    ></Grid>
                    <Grid
                      item
                      xs={8}
                      style={{
                        color: "#FFFFFF80",
                        fontSize: "14px",
                        fontWeight: "300",
                        textAlign: "left",
                      }}
                    >
                      {months[new Date(p.createdAt).getMonth()]}{" "}
                      {new Date(p.createdAt).getFullYear()} <br /> {p.givenBy}
                    </Grid>
                    <Grid item xs={4} justifyContent={{ xs: "end" }}>
                      <div className={"points__count"}>
                        <img
                          style={{
                            display: "flex",
                            height: 25,
                          }}
                          src={require("../../asset/coin.png")}
                        />
                        {p.points}
                      </div>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid
                container
                spacing={0}
                style={{
                  padding: "40px",
                  background: "#141927",
                  borderBottom: "1px solid #232148",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    color: "#FFFFFF80",
                    fontSize: "13px",
                    fontWeight: "300",
                    textAlign: "center",
                  }}
                  justifyContent="center"
                >
                  Play and Win To Earn Points
                </Grid>
              </Grid>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
}
