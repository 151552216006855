import { createContext, useEffect, useReducer } from "react";
import AppReducer from "../reducer";
const storageKey = "localStorage";

export const initState = {
  Auth: {
    userId: 0,
    access_token: null,
    emailConfirmed: false,
  },
};

const AppContext = createContext();

export function AppWrapper({ children }) {
  var init = null;
  const [state, dispatch] = useReducer(
    AppReducer,
    initState,
    (defaultState) => {
      const persisted = JSON.parse(localStorage.getItem(storageKey));
      return persisted !== null ? persisted : defaultState;
    }
  );

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state));
  }, [storageKey, state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}
export default AppContext;
