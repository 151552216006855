import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import "./index.scss";

const keys = [
  "Ball Possession",
  "Total Shots",
  "Shots on Goal",
  "Shots off Goal",
  "Shots insidebox",
  "Yellow Cards",
  "Red Cards",
  "Passes accurate",
  "Passes %",
];

export default function Stats(props) {
  var matchDetails = props.match.matchDetails
    ? JSON.parse(props.match.matchDetails)
    : null;
  const [stats, setStats] = useState([]);

  const getValue = (array, key) => {
    for (var i in array) {
      if (array[i]["type"] == key) {
        return array[i]["value"];
      }
    }
  };

  useEffect(() => {
    if (matchDetails) {
      console.log(matchDetails);
      try {
        var _stats = [];
        for (var i in keys) {
          var team1value = getValue(matchDetails[props.match.team1Id], keys[i]);
          var team2value = getValue(matchDetails[props.match.team2Id], keys[i]);
          var team1percent =
            (parseInt(team1value) /
              (parseInt(team1value) + parseInt(team2value))) *
            100;
          var team2percent =
            (parseInt(team2value) /
              (parseInt(team1value) + parseInt(team2value))) *
            100;
          if (
            parseInt(team1value) + parseInt(team2value) == 0 ||
            (team1value == null && team2value == null)
          ) {
            team1value = 0;
            team2value = 0;
            team1percent = 0;
            team2percent = 0;
          }
          _stats.push({
            name: keys[i],
            team1: team1value,
            team2: team2value,
            team1percent: team1percent,
            team2percent: team2percent,
          });
          setStats(_stats);
        }
      } catch (ex) {
        console.log(ex);
      }
    } else {
      setStats(null);
    }
    console.log(stats);
  }, []);

  return (
    <div className="matcheventsparent">
      {stats
        ? stats.length > 0
          ? stats.map((x, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingTop: 8,
                    }}
                  >
                    {x.name}
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      fontWeight: "lighter",
                      fontSize: 11,
                    }}
                  >
                    <div>{x.team1}</div>
                    <div>{x.team2}</div>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      fontWeight: "lighter",
                      fontSize: 11,
                      width: "100%",
                    }}
                  >
                    <LinearProgress
                      className="loader loaderColor1 flip"
                      variant="determinate"
                      value={x.team1percent}
                    />
                    <LinearProgress
                      className="loader loaderColor2"
                      variant="determinate"
                      value={x.team2percent}
                    />
                  </div>
                </div>
              );
            })
          : console.log("to show data not avaiable")
        : console.log("to show data not avaiable")}
    </div>
  );
}

export const Stat = (props) => {
  return <div></div>;
};
