import { useContext } from "react";

import { useNavigate } from "react-router-dom";
import { OptionsList } from "../../";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import AppContext from "../../../../common/context";

import "./index.scss";

export default function Info(props) {
  const navigate = useNavigate();

  const menuItems1 = [
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>Match</span>
          <span>{props.match.league.name}</span>
        </div>
      ),
    },
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>{"Date & Time"}</span>
          <span>
            {props.match.date}, {props.match.time}
          </span>
        </div>
      ),
    },
    {
      name: (
        <div>
          <span style={{ color: "#52526b" }}>Venue</span>
          <span>{props.venue}</span>
        </div>
      ),
    },
  ];
  return (
    <div
      style={{
        width: "95%",
        overflowY: props.overflow ? props.overflow : "scroll",
        color: "white",
        margin: "auto",
      }}
    >
      <OptionsList OptionListItem={menuItems1} />
    </div>
  );
}

export const Formation = ({ Formation }) => {
  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{
        padding: "15px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        color: "white",
        flex: "1 1 0",
        background: "#141233",
        borderRadius: "20px",
        marginBottom: "10px",
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={4} style={{ justifyContent: "flex-start" }}>
          {Formation != null ? Formation : "-"}
        </Grid>
        <Grid item xs={4} style={{ justifyContent: "center" }}>
          Formation
        </Grid>
        <Grid item xs={4} style={{ justifyContent: "flex-end" }}>
          {Formation != null ? Formation : "-"}
        </Grid>
      </Grid>
    </Box>
  );
};
