import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Loader from "react-js-loader";
import Api from "../../../../common/api";
import AppContext from "../../../../common/context";
import { useContext } from "react";

export const SquadDetails = (props) => {
  const match = props.match;
  const { state } = useContext(AppContext);
  const [players1, setPlayers1] = useState(null);
  const [players2, setPlayers2] = useState(null);
  const [loading, setLoading] = useState(true);
  //Calling squad function
  const getPlayersList = async (matchid, teamId) => {
    try {
      var resp = await Api({
        route: "matchplayers/" + matchid + "/" + teamId,
        method: "Get",
        accessToken: state.Auth.access_token,
      });
      return resp;
    } catch (error) {}
  };

  const fetchPlayers = async () => {
    setPlayers1(await getPlayersList(match.matchId, match.team1Id));
    setPlayers2(await getPlayersList(match.matchId, match.team2Id));
    console.log("fetched Matched");
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchPlayers();
  }, []);

  const renderPlayers = () => {
    var players = [];
    for (var i = 0; i < players1.length || i < players1.length; i++) {
      players.push(
        <div
          key={i}
          style={{
            backgroundColor: "#141927",
            padding: "15px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            color: "white",
          }}
        >
          {players1.length > 1 ? (
            <div style={{ flex: 1 }}>
              {players1[i].teamPlayer.player.name}
              <span style={{ fontSize: "12px" }}>
                {JSON.parse(players1[i].role).battingStyle + " Handed Batter"}
              </span>
              <span style={{ fontSize: "12px" }}>
                {JSON.parse(players1[i].role).bowlingStyle + " Bowler"}
              </span>
            </div>
          ) : (
            <div style={{ flex: 1 }}>
              <span style={{ fontSize: "12px" }}></span>
              <span style={{ fontSize: "12px" }}></span>
            </div>
          )}
          {players2.length > i ? (
            <div style={{ flex: 1, textAlign: "right" }}>
              {players2[i].teamPlayer.player.name}
              <span style={{ fontSize: "12px" }}>
                {JSON.parse(players2[i].role).battingStyle + " Handed Batter"}
              </span>
              <span style={{ fontSize: "12px" }}>
                {JSON.parse(players2[i].role).bowlingStyle + " Bowler"}
              </span>
            </div>
          ) : (
            <div style={{ flex: 1, textAlign: "right" }}>
              <span style={{ fontSize: "12px" }}></span>
              <span style={{ fontSize: "12px" }}></span>
            </div>
          )}
        </div>
      );
    }
    return players;
  };

  return (
    <>
      {!loading ? (
        <div
          style={{
            margin: "20px",
            overflowY: "scroll",
            color: "white",
          }}
        >
          <div style={{ width: "100%", marginBottom: "3%" }}>
            <div
              style={{
                backgroundColor: "#141927",
                marginBottom: "1px",
                padding: "15px",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                color: "white",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {match.team1.name}
                  </Grid>
                  <Grid item xs={6} justifyContent="end">
                    {match.team2.name}
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div>{renderPlayers()}</div>
          </div>
        </div>
      ) : (
        <Loader
          type="bubble-top"
          bgColor={"#FFFFFF"}
          color={"#FFFFFF"}
          size={35}
        />
      )}
    </>
  );
};
