import { useEffect, useState, useContext } from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./index.scss";

import { Badge, ButtonDX, TeamAvatar, BasicModal } from "../../atoms";
import AppContext from "../../../common/context";
import Api from "../../../common/api";

export default function Circket(props) {
  var match = props.match;
  const navigate = useNavigate();
  var live = [7, 4, 6, 7, 5, 3, 11];
  var finished = [8, 15, 14, 18, 10, 9];
  var dateTime = moment(match.startDate).utc();
  match.finished = finished.includes(match.statusCode);
  match.live = live.includes(match.statusCode);
  match.futureMatch = moment().utc() < dateTime;
  match.loading = false;
  match.date = dateTime.format("MMM DD YYYY");
  match.time = moment(match.startDate).utc().format("hh:mm a");
  return (
    <div
      className="match__container"
      onClick={() =>
        navigate("/matchDetails/" + match.matchId, {
          replace: false,
          state: match,
        })
      }
    >
      <div
        style={{
          flexDirection: "row",
          paddingLeft: "3%",
          paddingTop: "3%",
          paddingBottom: "2%",
          borderBottom: "1px solid #212A42",
        }}
      >
        <div style={{ justifyContent: "center", width: "10%" }}>
          <Avatar
            style={{ height: 30, width: 30 }}
            src={match.league.logo}
            variant="circular"
          />
        </div>
        <div className="League__title">{match.league.name}</div>

        {
          <div
            style={{
              width: "45%",
              textAlign: "center",
              padding: "2%",
              marginRight: "2%",
              fontSize: "12px",
              alignItems: "end",
            }}
          >
            {match.live ? (
              <div className="live">
                <Badge className={"live"} text={<>Live</>} />
              </div>
            ) : (
              <>
                <span>{match.time},</span>
                <span>{match.date}</span>
              </>
            )}
          </div>
        }
      </div>
      <ScoreCard match={match} showResult={true} />
    </div>
  );
}
export const ScoreCard = (props) => {
  var match = props.match ? props.match : props;
  const [timeRemaining, setTimeRemaining] = useState("");
  const [selectedTeam1, setselectedTeam1] = useState(false);
  const [selectedTeam2, setselectedTeam2] = useState(false);
  useEffect(() => {
    //Check selected team
    if (match.questions[0]?.fanResponses[0]) {
      if (match.questions[0]?.fanResponses[0].answer == match.team1Id) {
        setselectedTeam1(true);
        setselectedTeam2(false);
      } else {
        setselectedTeam2(true);
        setselectedTeam1(false);
      }
    }
  }, []);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      var now = moment().utc();
      var duration = moment.duration(moment(match.startDate).local().diff(now));
      var hh = parseInt(duration.asHours());
      var mm = parseInt(duration.asMinutes()) % 60;
      var ss = parseInt(duration.asSeconds()) % 60;
      setTimeRemaining(hh + ":" + mm + ":" + ss);
      if (hh < 0) {
        // console.log('Negative time');
        match.finished = true;
      }
    }, 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [timeRemaining]);

  return (
    <div className={"match_score" + (props.nobg ? " nobg" : "")}>
      <Box sx={{ flexGrow: 1, marginTop: "2%" }}>
        <Grid container spacing={2}>
          <Grid item xs={2} justifyContent="center">
            <TeamAvatar
              teamLogo={`https://www.cricbuzz.com/a/img/v1/24x18/i1/c${match.team1.logo}/image.jpg`}
              team={match.team1.name}
              selectedTeam={selectedTeam1}
              variant="square"
            />
          </Grid>
          <Grid item xs={8}>
            {(match.live || match.finished) && !match.futureMatch ? (
              <Score match={match} />
            ) : (
              <Remaining match={match} timeRemaining={timeRemaining} />
            )}
          </Grid>
          <Grid item xs={2} justifyContent="center" alignItems="center">
            <TeamAvatar
              teamLogo={`https://www.cricbuzz.com/a/img/v1/24x18/i1/c${match.team2.logo}/image.jpg`}
              team={match.team2.name}
              selectedTeam={selectedTeam2}
              variant="square"
            />
          </Grid>
          <Grid item xs={12} style={{ justifyContent: "center" }}>
            {winningTeam(match)}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export const Score = (props) => {
  const [score1, setScore1] = useState();
  const [score2, setScore2] = useState();
  const [showStatus, setShowStatus] = useState(false);
  var match = props.match;

  useEffect(() => {
    setShowStatus(true);
    try {
      setScore1(JSON.parse(match.team1Score));
      setScore2(JSON.parse(match.team2Score));
    } catch (e) {
      console.log("error", e);
    }
  }, [match]);

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {score1 && score2 ? (
        <div className="team__score" style={{ fontSize: "20px" }}>
          <div style={{ marginLeft: "3%" }}>
            <span style={{ fontSize: "11px", color: "white" }}>
              {match.team1.code}
            </span>
            {Object.keys(score1).length !== 0 &&
            score1.inngs1.runs &&
            score1.inngs1.wickets
              ? score1.inngs1.runs + "/" + score1.inngs1.wickets
              : "0/0"}
            <span style={{ fontSize: "11px", color: "white" }}>
              {Object.keys(score1).length !== 0 && score1.inngs1.overs
                ? score1.inngs1.overs
                : "0"}{" "}
              Overs
            </span>
          </div>
          <div
            style={{
              padding: "10px",
              borderRadius: "50%",
              fontSize: "14px",
              color: "#009AFD",
              background: "#212A42",
            }}
          >
            VS
          </div>
          <div style={{ textAlign: "end", marginRight: "3%" }}>
            <span
              style={{ fontSize: "11px", color: "white", textAlign: "end" }}
            >
              {match.team2.code}
            </span>
            {Object.keys(score2).length !== 0 &&
            score2.inngs1.runs &&
            score2.inngs1.wickets
              ? score2.inngs1.runs + "/" + score2.inngs1.wickets
              : "0/0"}
            <span style={{ fontSize: "11px", color: "white" }}>
              {Object.keys(score2).length !== 0 && score2.inngs1.overs
                ? score2.inngs1.overs
                : "0"}{" "}
              Overs
            </span>
          </div>
        </div>
      ) : (
        <div
          style={{
            flex: 3,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          To Be Updated
        </div>
      )}
    </div>
  );
};

const Remaining = (props) => {
  var match = props.match;
  const { state } = useContext(AppContext);
  const [question, setQuestion] = useState();
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    getquestion(match.matchId);
  }, []);
  const getquestion = async (matchid) => {
    try {
      var resp = await Api({
        route: "matches/" + matchid,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      setQuestion(resp.questions);
    } catch (error) {
      //alert
    }
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {open && question ? (
        <BasicModal
          open={open}
          setOpen={setOpen}
          match={match}
          question={question}
          setHide={setHide}
          cricketLogo1={`https://www.cricbuzz.com/a/img/v1/24x18/i1/c${match.team1.logo}/image.jpg`}
          cricketLogo2={`https://www.cricbuzz.com/a/img/v1/24x18/i1/c${match.team2.logo}/image.jpg`}
        />
      ) : (
        ""
      )}
      {question &&
      (match?.questions?.length == 0 ||
        match?.questions[0]?.fanResponses?.length == 0 ||
        match?.questions[0]?.fanResponses == undefined) ? (
        <>
          <div className="time_remaining-text">Time Remaining</div>
          <div className="time_remaining">{props.timeRemaining}</div>
          <ButtonDX
            className={"play_win_btn"}
            type={"gradient"}
            onClick={handleOpen}
            loading={match.loading}
            loadersize={15}
            loaderheight={15}
            disabled={match.loading ? true : false}
            text={"PLAY TO WIN"}
          ></ButtonDX>
        </>
      ) : (
        <div style={{ justifyContent: "center", height: "90%" }}>
          <div className="time_remaining-text">Time Remaining</div>
          <div className="time_remaining" style={{ fontSize: "25px" }}>
            {props.timeRemaining}
          </div>
        </div>
      )}
    </div>
  );
};

// check winning team name and margin return too
export const winningTeam = (match) => {
  const result = JSON.parse(match.winnerDetails).result;
  var winningTeamName = "";
  if (!result.resultType) {
    if (match.winner) {
      winningTeamName =
        match.team1Id == match.winner ? match.team1.name : match.team2.name;
      winningTeamName += " won the Match";
    }
  } else {
    if (match.winner) {
      winningTeamName =
        match.team1Id == match.winner ? match.team1.name : match.team2.name;
      winningTeamName += " won by ";
    }

    winningTeamName += result.winningMargin;

    winningTeamName += result.winByRuns ? " runs" : " wickets";
  }

  const res = (
    <Grid item xs={12} style={{ justifyContent: "center" }}>
      {winningTeamName}
    </Grid>
  );
  return res;
};
