import { Avatar } from "@mui/material";
import { useEffect } from "react";
import "./index.scss";

export default function Summary(props) {
  const match = props.match;
  const matchEvents = match.matchEvents;
  useEffect(() => {
    matchEvents.sort(function (a, b) {
      return a.time - b.time;
    });
  }, [match]);

  return (
    <div className="matcheventsparent">
      {matchEvents.length != 0 ? (
        matchEvents.map((m, index) => {
          return (
            <div className="matchevents__div" key={index}>
              <div
                style={{
                  justifyContent: "center",
                  width: "fit-content",
                  fontWeight: "bolder",
                }}
              >
                {m.time} {"'"}
              </div>
              {m.type == "Card" ? (
                <RedCard detail={m.detail} />
              ) : m.type == "Goal" ? (
                <Goal />
              ) : (
                <Subsitution />
              )}

              <div style={{ justifyContent: "center", paddingLeft: "5px" }}>
                {/* {m.player} */}
                {/* {m.playerId} */}
                {m.assistPlayer || m.assistPlayer != null
                  ? m.assistPlayer
                  : "-"}
                {m.type == "Goal" ? (
                  <div style={{ fontSize: "11px", color: "#2E2D4D" }}>
                    Assist by {m.assistPlayer}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="matchevents__div" style={{ justifyContent: "center" }}>
          To be Updated
        </div>
      )}
    </div>
  );
}
export const RedCard = (props) => {
  const color = props.detail == "Red Card" ? "red" : "yellow";
  return (
    <div
      style={{
        justifyContent: "center",
        marginRight: "5px",
        marginLeft: "5px",
        padding: "10px",
        background: `${color}`,
        height: "10px",
      }}
    ></div>
  );
};

export const Goal = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <Avatar
        style={{ height: 20, width: 20, marginLeft: "5px", marginRight: "5px" }}
        src={
          "https://purepng.com/public/uploads/large/purepng.com-footballfootballballgamesgoals-1701528169327cr8bf.png"
        }
        variant="circular"
      />
    </div>
  );
};

export const Subsitution = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <Avatar
        style={{ height: 20, width: 20, marginLeft: "5px", marginRight: "5px" }}
        src={
          "https://icon-library.com/images/substitute-icon/substitute-icon-10.jpg"
        }
        variant="circular"
      />
    </div>
  );
};
