import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Typography } from '@mui/material';
import { ButtonDX } from '../../atoms';
import './index.scss';

const style = {
	position: 'relative',
	top: '40%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '300px',
	bgcolor: '#232148',
	boxShadow: 24,
	color: 'white',
	// p: 4,
	padding: '15px',
	height: '400px',
};

export const CongReedemModal = (props) => {
	return (
		<Modal
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			// TransitionComponent={Transition}
		>
			<Box sx={style}>
				<Grid container spacing={1}>
					<Grid item xs={12} justifyContent="end">
						<CloseIcon onClick={() => props.setOpenCong(false)} />
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<Avatar
							style={{ height: '90%', width: '50%', marginLeft: '50px' }}
							src="https://upload.wikimedia.org/wikipedia/en/2/26/Party_Popper_Emojipedia.png"
							variant="square"
						/>
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<Typography
							className="Reedemption__title"
							style={{ fontWeight: '700', fontSize: '24px', lineHeight: '24px' }}
						>
							CONGRATULATIONS
						</Typography>
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<Typography style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>
							Coupoun successfully redeemed.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{''}
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<div
							style={{
								paddingTop: '7px',
								background: '#090729',
								borderRadius: '38px',
								width: '250px',
								textAlign: 'center',
							}}
						>
							<Typography className="Reedem__Modal__title">Balance Points</Typography>
							<Typography className="Reedemption__title" style={{ fontSize: '32px' }}>
								2000
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12}>
						{''}
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<ButtonDX
							style={{
								fontSize: '14px',
								height: 'auto',
								padding: '5px',
								width: '217px',
								borderRadius: '4px',
								boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
								marginTop: 'auto',
								fontWeight: '500',
								letterSpacing: '1.25px',
							}}
							type={'gradient'}
							onClick={() => props.setOpenCong(false)}
							loadersize={15}
							loaderheight={15}
							text="OK"
						></ButtonDX>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};
