const errorHandler = (error) => {
  switch (error) {
    case "USER_ALREADY_EXISTS":
      throw new Error("Email adress is already registered.");
    case "INVALID_OTP":
      throw new Error("OTP is invalid or expired, Please Resend OTP email.");
    case "INVALID_EMAIL_PASSWORD":
      throw new Error("Please verify email Password.");
    case "USER_NOT_FOUND":
      throw new Error("Provided email is not register.");
    case "INVALID_LOGIN_ATTEMPT":
      throw new Error("please enter a valid phone number / password.");
    case "Invalid response from SMS gateway.":
      throw new Error("Please verify phone number");
    case "ERROR_SENDING_EMAIL":
      throw new Error("Unable to Send email");
    case "MEMBER_REMOVED_BY_ADMIN":
      throw new Error(
        "Your membership request has already been rejected by the admin."
      );
    default:
      throw error;
  }
};
export default errorHandler;
