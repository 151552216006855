import Header from "../header";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Info } from "../circketDetailsTabs";
import { useEffect, useState, useContext } from "react";
import AppContext from "../../../common/context";
import Api from "../../../common/api";
import Loader from "react-js-loader";
import "./index.scss";
import { ProcessCircketMatch } from "../../../common/postProcessApi";
import { SquadDetails } from "../circketDetailsTabs";
import { ScoreCard, winningTeam } from "../matchUpdate/circket";
import ScoreBoard from "../circketDetailsTabs/scoreBoard";

export default function Circket(props) {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [match, setMatch] = useState(null);
  const [players1Score, setPlayers1Score] = useState();
  const [players2Score, setPlayers2Score] = useState();
  const params = useParams();

  useEffect(() => {
    getMatch(params.matchId);
  }, []);

  const getMatch = async (matchid) => {
    try {
      var resp = await Api({
        route: "matches/" + matchid,
        method: "GET",
        accessToken: state.Auth.access_token,
      });
      const _match = ProcessCircketMatch(resp);
      setMatch(_match);
      setPlayers1Score(await getTeamScore(_match.matchId, _match.team1Id));
      setPlayers2Score(await getTeamScore(_match.matchId, _match.team2Id));
    } catch (error) {}
  };

  const getTeamScore = async (matchid, teamid) => {
    try {
      var resp = await Api({
        route: "matchplayers/" + matchid + "/" + teamid,
        method: "Get",
        accessToken: state.Auth.access_token,
      });

      return resp;
    } catch (error) {}
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "800px",
        height: "100vh",
        backgroundColor: "#02030a",
      }}
    >
      <Header
        left={
          <ArrowBackIosOutlined
            className={"action_logo"}
            onClick={() => navigate(-1)}
          />
        }
        center={<div className="header_title">Match Detail</div>}
      />
      {match == null ? (
        <div>
          <Loader
            type="spinner-circle"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={70}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              color: "white",
            }}
          >
            <div className="match_details_league">
              <div
                style={{
                  justifyContent: "center",
                  fontSize: "14px",
                  fontWeight: "bolder",
                  marginLeft: 10,
                }}
              >
                {match.league.name}
              </div>
            </div>
            <ScoreCard match={match} showResult={false} />
          </div>
          <div
            style={{
              color: "#E1811F",
              textAlign: "center",
              background: "#141927",
              padding: "3%",
              marginBottom: "5%",
              fontSize: "11px",
            }}
          >
            {winningTeam(match)}
          </div>
          <Tabs className={"match-details__react-tabs"}>
            <TabList className={"match-details__react-tabs__tab-list"}>
              {!match.futureMatch ? (
                <Tab className={"match-details__react-tabs__tab"}>
                  <div className="match-details__react-tabs__tab__text">
                    SCOREBOARD
                  </div>
                </Tab>
              ) : (
                ""
              )}
              <Tab className={"match-details__react-tabs__tab"}>
                <div className="match-details__react-tabs__tab__text">
                  SQUADS
                </div>
              </Tab>
              <Tab className={"match-details__react-tabs__tab"}>
                <div className="match-details__react-tabs__tab__text">INFO</div>
              </Tab>
            </TabList>

            <TabPanel>
              <ScoreBoard
                match={match}
                players1={players1Score}
                players2={players2Score}
              />
            </TabPanel>
            <TabPanel>
              <SquadDetails match={match} />
            </TabPanel>
            <TabPanel>
              <Info match={match} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </div>
  );
}
